import type { ImportMetaEnv } from '@/@types/shims-vue.d'

export const getGlobEnvConfig = (): ImportMetaEnv => {
  const env = import.meta.env
  return env as unknown as ImportMetaEnv
}

export const getUniAppEnv = () => {
  let obj: any = {}
  // let env = ''
  // const envType = uni.getStorageSync('setLoginEnv')
  // if (envType && envType !== 'prod') {
  //   env = envType
  // } else {
  //   env = 'test'
  //   // env = 'beta'
  //   // env = 'prod'
  // }
  // let env = 'test'
  // let env = 'beta'
  let env = 'prod'
  if (env === 'test' || env === 'beta') {
    obj = {
      VITE_APP_WX_APPID: 'wxb429aa22cefecb42', // H5登录相关
      VITE_APP_ENV: env === 'beta' ? 'beta' : 'test',
      VITE_APP_BASE_API: env === 'beta' ? 'https://wwzl-h5.bravedu.com/api' : 'https://wwzl-h5.bravedu.com/api',
      VITE_APP_WS_URL: 'wss://test-api.pengyin.vip/ws',
      VITE_APP_ROOT_URL: 'https://test-h5.pengyin.vip/h5-uniapp/',
      VITE_APP_BASE_HOST: 'https://test-h5.pengyin.vip/h5-uniapp',
      VITE_APP_OPEN_ADMIN: 'https://test-newtuige.pengyin.vip',
    }
  } else {
    obj = {
      VITE_APP_WX_APPID: 'wxb429aa22cefecb42', // H5登录相关
      VITE_APP_ENV: env === 'prod',
      VITE_APP_BASE_API: 'https://wwzl-h5.bravedu.com/api',
      VITE_APP_WS_URL: 'wss://api.pengyin.vip/ws',
      VITE_APP_ROOT_URL: 'https://h5.pengyin.vip/h5-uniapp/',
      VITE_APP_BASE_HOST: 'https://h5.pengyin.vip/h5-uniapp',
      VITE_APP_OPEN_ADMIN: 'https://newtuige.pengyin.vip',
    }
  }
  return {
    ...obj,
    VITE_APP_SCHEMA_PATH: 'pengyin://',
    VITE_DOUYIN_BAQ_ASSETSID: '1756804358448222', // 抖音js埋码回传id
    VITE_DOUYIN_BAQ_EVENTTYPE: 'active_register', // 抖音js埋码事件资产-事件类型（注册）
    VITE_DOUYIN_BAQ_EVENTTYPE_FORM: 'form' // 抖音js埋码事件资产-事件类型（表单提交｜ 音乐人签约）
  }
}
/**
 * @description: 获取环境变量
 * @param {type}
 * @returns:
 * @example:
 */
export const getEnv = (): string => import.meta.env.VITE_APP_NODE_ENV

/**
 * @description: 是否是开发模式
 * @returns:
 * @example:
 */
export const isDevMode = (): boolean => getEnv() === 'development'
/**
 * @description: 是否是开发模式
 * @returns:
 * @example:
 */
export const isStagingMode = (): boolean => getEnv() === 'staging'
/**
 * @description: 是否是开发模式
 * @returns:
 * @example:
 */
export const isBetaMode = (): boolean => getEnv() === 'beta'
/**
 * @description: 是否是生产模式模式
 * @returns:
 * @example:
 */
export const isProdMode = (): boolean => getEnv() === 'production'

/**
 * @description: 是否开启mock
 * @returns:
 * @example:
 */
export const isUseMock = (): boolean => import.meta.env.VITE_USE_MOCK === 'true'
