import { defineStore } from 'pinia'
import { store } from '@/store'

export const useSongsStore = defineStore('app-songs', {
  state: () => ({
    search_txt: '',
    superSearchTxt: ''
  }),
  actions: {
    setSearchTxt (val) {
      this.search_txt = val
    },
    // 首页超级搜索
    setSuperSearchTxt (val) {
      this.superSearchTxt = val
    },
    clearSearchTxt () {
      this.search_txt = ''
    },
    // 清空首页超级搜索
    clearSuperSearchTxt () {
      this.superSearchTxt = ''
    }
  }
})

// Need to be used outside the setup
export function useSongsStoreWithOut () {
  return useSongsStore(store)
}
