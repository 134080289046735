import { useCommonStoreWithOut } from './../store/modules/common';
import { pyToast } from '@/utils/util';
import routerConfig from "./routerConfig"

const commonStore = useCommonStoreWithOut()

function queryURLparamsRegEs6 (url) {
  let obj = {}
  let reg = /([^?=&]+)=([^?=&]+)/g
  url.replace(reg, (...arg) => {
    obj[arg[1]] = arg[2]
  })
  return obj

}

export default async function () {
  let list = ["navigateTo", "redirectTo", "reLaunch", "switchTab"];
  list.forEach(item => {  //用遍历的方式分别为,uni.navigateTo,uni.redirectTo,uni.reLaunch,uni.switchTab这4个路由方法添加拦截器
    uni.addInterceptor(item, {
      invoke (e) { // 调用前拦截
        const queryURLparams: any = queryURLparamsRegEs6(e.url)
        const token = uni.getStorageSync('aibooktoken')
        // //获取当前页面路径（即url去掉"?"和"?"后的参数）
        const url = e.url.split('?')[0]
        let notNeed = routerConfig.notNeedLoginList.includes(url)
        if (notNeed) {
          return e
        } else {
          //判断要打开的页面是否需要验证登录
          if (token === '') {
            commonStore.setBackUrl(e.url)
            // uni.navigateTo({
            //   url: "/pages/login/index"
            // })
            return false
          } else {
            return e
          }
        }
      },
      fail (err) { // 失败回调拦截 
        // uni.showModal({
        //   content: JSON.stringify(err),
        //   showCancel: false
        // });
      }
    })
  })
}

