<template>
  <view>
    <slot
      :time="time"
      :remain="timeData.remain"
      :day="timeData.day"
      :hour="timeData.hour"
      :minute="timeData.minute"
      :second="timeData.second"
      :formatsecond="timeData.formatsecond"
      :formatminute="timeData.formatminute"
      :formathour="timeData.formathour"
    />
  </view>
</template>
<script setup lang="ts">
import { onMounted, onBeforeUnmount, reactive, ref, watch } from 'vue'
const props = defineProps({
  // 倒计时时长（单位：毫秒）
  time: {
    type: Number,
    default: 0
  },
  // 是否自动
  autoStart: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits<{
  (e: 'finish'): void
}>()
let timer
let timeData = reactive({
  remain: 0,
  day: 0,
  hour: 0,
  minute: 0,
  second: 0,
  formathour: '',
  formatsecond: '',
  formatminute: ''
})
watch(
  () => props.time,
  () => {
    reset()
  }
)
// 设置timeData
const updateTimeData = () => {
  let t: any = timeData.remain
  timeData.day = Math.floor(t / 1000 / 60 / 60 / 24)
  timeData.hour = Math.floor((t / 1000 / 60 / 60) % 24)
  timeData.minute = Math.floor((t / 1000 / 60) % 60)
  timeData.second = Math.floor((t / 1000) % 60)
  // 补0
  timeData.formathour = String(timeData.hour).length < 2 ? `0${timeData.hour}` : `${timeData.hour}`
  timeData.formatminute = String(timeData.minute).length < 2 ? `0${timeData.minute}` : `${timeData.minute}`
  timeData.formatsecond = String(timeData.second).length < 2 ? `0${timeData.second}` : `${timeData.second}`
}
// 开启倒计时
const startTimer = () => {
  if (timer) {
    clearInterval(timer)
  }
  if (timeData.remain <= 0) {
    emit('finish')
    return
  }
  timer = setInterval(() => {
    timeData.remain -= 1000
    updateTimeData()
    if (timeData.remain <= 0) {
      pause()
      emit('finish')
    }
  }, 1000)
}
// 重置倒计时
const reset = () => {
  timeData.remain = props.time < 0 ? 0 : props.time
  updateTimeData()
  if (props.autoStart) {
    start()
  }
}
// 暂停倒计时
const pause = () => {
  if (timer) {
    clearInterval(timer)
    timer = null
  }
}
// 开始倒计时
const start = () => {
  if (timer) {
    return
  }
  startTimer()
}
onMounted(() => {
  reset()
})
onBeforeUnmount(() => {
  pause()
})
</script>
