import type { App } from 'vue'
import CommonHeader from '@/components/common-header/common-header.vue'
import PyLoading from '@/components/loading/loading.vue'
import CustomTabBar from '@/components/custom-tab-bar/CustomTabBar.vue'
import PyDialog from '@/components/py-dialog/PyDialog.vue'
import InputChunk from '@/components/input-chunk/index.vue'
import CountDown from '@/components/count-down/count-down.vue'
import CommonSlider from '@/components/common-slider/common-slider.vue'
import CommonSliderNvc from '@/components/common-slider/common-slider-nvc.vue'
import Collapse from '@/components/collapse/index.vue'
import CommonTabs from '@/components/common-tabs/common-tabs.vue'
import CommonPassword from '@/components/common-password/index.vue'
import CommonPlayer from '@/components/common-player/common-player.vue'
import CommonCircle from '@/components/common-circle/common-circle.vue'
import List from '@/components/list/index.vue'
import PullList from '@/components/list/pullList.vue'
import CommonFootPlayer from '@/components/commom-foot-player/index.vue'
import PySkeleton from '@/components/skeleton/skeleton.vue' // 骨架屏
import TextHidden from '@/components/text-hidden/index.vue' // 文字省略
import PySticky from '@/components/py-sticky/index.vue' // 吸顶容器
import CommonShare from '@/components/common-share/index.vue';
import PyButton from '@/components/py-button/index.vue';
import PyBarrage from '@/components/py-barrage/py-barrage.vue';
import PreviewImage from '@/components/preview-image/index.vue'; // 预览图片  因app预览是打开新页面 
import DownloadAppTips from '@/components/downloadApp-tips/index.vue'; // 顶部下载app
import OpenPyApp from '@/components/open-py-app/index.vue'; // 打开app遮罩
import LoginDialog from '@/components/login-dialog.vue'; // 打开app遮罩
//#ifdef H5 
import PyCroper from "@/components/py-croper/py-croper.vue" //h5裁剪图片组件
// #endif

/**
 * 注册全局组件
 * @since 2022.08.23
 */
export function setupGlobalComponents(app: App<Element>) {
  app.component('CommonHeader', CommonHeader)
  app.component('PyLoading', PyLoading)
  app.component('CustomTabBar', CustomTabBar)
  app.component('List', List)
  app.component('PullList', PullList)
  app.component('PyDialog', PyDialog)
  app.component('InputChunk', InputChunk)
  app.component('CountDown', CountDown)
  app.component('CommonSlider', CommonSlider)
  app.component('CommonSliderNvc', CommonSliderNvc)
  app.component('Collapse', Collapse)
  app.component('CommonTabs', CommonTabs)
  app.component('CommonPlayer', CommonPlayer)
  app.component('CommonPassword', CommonPassword)
  app.component('CommonCircle', CommonCircle)
  app.component('CommonFootPlayer', CommonFootPlayer)
  app.component('PySkeleton', PySkeleton)
  app.component('CommonShare', CommonShare)
  app.component('TextHidden', TextHidden)
  app.component('PyBarrage', PyBarrage)
  app.component('PySticky', PySticky)
  app.component('PyButton', PyButton)
  app.component('PreviewImage', PreviewImage)
  app.component('DownloadAppTips', DownloadAppTips)
  app.component('OpenPyApp', OpenPyApp)
  app.component('LoginDialog', LoginDialog)
  //#ifdef H5 
  app.component('PyCroper', PyCroper)
  // #endif
}
