<template>
  <view>
    <scroll-view
      class="list-scroll-view"
      :style="{ height: height }"
      scroll-y="true"
      :scroll-top="scrollTopHeight"
      :scroll-anchoring="true"
      lower-threshold="100rpx"
      :refresher-enabled="refresherEnabled && disabledScroll"
      :refresher-triggered="triggered"
      :refresher-threshold="100"
      :refresher-background="refresherBackground"
      :refresher-default-style="refresherDefaultStyle"
      @refresherpulling="onPulling"
      @refresherrefresh="onRefresh"
      @refresherrestore="onRestore"
      @refresherabort="onAbort"
      @scroll="onScroll"
      @scrolltolower="scrolltolower"
      @scrolltoupper="scrolltoupper"
      @touchstart="touchstart"
      @touchmove="touchmove"
      @touchend="touchend"
    >
      <!-- <view v-if="pullend" class="bottom-text">别拉了，没有更多了~</view> -->
      <slot name="empty" v-if="state.data.length === 0 && isSend && !emptyShow"></slot>
      <slot name="content" v-else-if="(state.data.length !== 0 && isSend) || emptyShow" :content="state.data"> </slot>
      <slot name="other"></slot>
      <view v-if="state.data.length !== 0" class="bottom-text">
        <view>
          {{ finished ? loadingMoreNoMoreText : '加载中...' }}
        </view>
      </view>
    </scroll-view>
  </view>
</template>

<script lang="ts" setup>
import { onShow, onLoad, onHide } from '@dcloudio/uni-app'
import { onMounted, reactive, ref, watch, nextTick } from 'vue'
import * as _ from 'lodash-es'

const emit = defineEmits<{
  (e: 'listChange', data: any): void
  (e: 'onRefresh'): void
  (e: 'error'): void
}>()

const props: any = defineProps({
  emptyShow: {
    type: Boolean,
    default: false
  },
  // apiAction: {
  //   type: Promise,
  //   default: (params?: any) => Promise as any
  // },
  apiAction: {
    type: Function,
    default: () => {}
  },
  apiParams: {
    type: Object,
    default: {} as any
  },
  // 页码
  offset: {
    type: Number,
    default: 1
  },
  // isPullLoadList: {
  //   type: Boolean,
  //   default: false
  // },
  // 每页多少条
  page_size: {
    type: Number,
    default: 10
  },
  resDataKey: {
    type: String,
    default: 'data'
  },
  height: {
    type: String,
    default: '100vh'
  },
  // 首次是否load
  immediateCheck: {
    type: Boolean,
    default: true
  },
  // 是否开启自定义下拉刷新
  refresherEnabled: {
    type: Boolean,
    default: true
  },
  // 是否需要加载更多
  needLoadMore: {
    type: Boolean,
    default: true
  },
  showLoading: {
    type: Boolean,
    default: false
  },
  loadingMoreNoMoreText: {
    type: String,
    default: '到底了~'
  },
  refresherBackground: {
    type: String,
    default: 'black'
  },
  refresherDefaultStyle: {
    type: String,
    default: 'black'
  },
  isMountedLoad: {
    type: Boolean,
    default: false
  },
  propScrollTop: {
    type: Number,
    default: 0
  },
  isApiAction: {
    type: Boolean,
    default: true
  }
})

const scrollTopHeight = ref(0)
const scrollTop = ref(0)

watch(
  () => props.apiAction,
  (val) => {
    if (val && props.isApiAction) {
      getData(true)
    }
  }
)
watch(
  () => props.apiParams,
  (val: any) => {
    // offset 设置为0 则不会请求数据
    if (val && val.offset) {
      nextTick(() => {
        offsetMin.value = val.offset
        offset.value = val.offset
        offsetMax.value = val.offset
        getData()
      })
    }
  },
  { deep: true, immediate: true }
)

const offset = ref(0) // 当前分页
const offsetMin = ref(0) // 当前滚动加载最小分页
const offsetMax = ref(0) // 当前滚动加载最大分页
// const offsetMax = ref(props.offset) // 当前滚动加载最大分页

// 设置当前下拉刷新状态，true 表示下拉刷新已经被触发，false 表示下拉刷新未被触发
// const triggered = ref(false)
const triggered: any = ref(null)
const state = reactive({
  data: [] as any
})
const isSend = ref(false)
const disabledScroll = ref(true)
// 自定义下拉刷新被触发
const _freshing = ref(false)

let touchPositon = reactive({
  X: 0,
  Y: 0
})
const touchstart = (e) => {
  touchPositon.X = e.touches[0].clientX
  touchPositon.Y = e.touches[0].clientY
}
const touchmove = (e) => {
  if (disabledScroll.value === false) {
    return
  }
  let moveX = Math.abs(touchPositon.X - e.touches[0].clientX)
  let moveY = Math.abs(touchPositon.Y - e.touches[0].clientY)
  if (moveX / moveY <= 1) {
    disabledScroll.value = true
  } else {
    disabledScroll.value = false
  }
}
const touchend = (e) => {
  touchPositon.X = 0
  touchPositon.Y = 0
  disabledScroll.value = true
}
// 加载状态
const finished = ref(false)
//  下拉加载状态
const pullend = ref(false)
watch(
  () => props.propScrollTop,
  (val) => {
    nextTick(() => {
      if (val >= 0) {
        scrollTopHeight.value = val
      }
    })
  }
)
/**
 * 获取数据
 * @param init 是否初始化，默认false
 * @param ps 传入页大小 刷新时调用
 */
const getData = (init = false, page_size?: number) => {
  if (props.showLoading) {
    uni.showLoading({
      title: '加载中',
      mask: true
    })
  }
  if (init) {
    offset.value = 1
    offsetMin.value = 1
    offsetMax.value = 2
    finished.value = false
    isSend.value = false
    state.data = []
  } else {
    if (isPull.value) {
      finished.value = false
      if (offset.value > 1) {
        offset.value--
      } else {
        offset.value = 1
        pullend.value = true
      }
      if (offset.value < offsetMin.value) {
        offsetMin.value = offset.value
      }
    } else {
      offset.value = offsetMax.value
    }
  }

  if (finished.value) return
  if (props.apiAction) {
    try {
      return props
        .apiAction({
          ...props.apiParams,
          offset: offset.value,
          page_size: page_size || props.page_size
        })
        .then((res: any) => {
          uni.hideLoading()
          isSend.value = true
          const resData = res.data[props.resDataKey] || []
          const ps = props.page_size || page_size
          if (init) {
            state.data = resData
          } else {
            if (isPull.value) {
              // 下拉加载
              // state.data.unshift(...resData)
              state.data = [...resData, ...state.data]
              isPull.value = false
            } else {
              state.data = state.data.concat([...resData])
              offset.value++
              if (offset.value > offsetMax.value) {
                offsetMax.value = offset.value
              }
            }
          }
          // 如果有传入ps参数，根据ps参数计算下一次的pn
          if (page_size) {
            offset.value = Math.ceil(page_size / props.page_size) + 1
          }
          // 改为判断返回的list长度是否为0，为0则没有数据了
          if (
            resData.length === 0 ||
            res.data?.total === 0 ||
            resData.length < ps ||
            !props.needLoadMore ||
            state.data?.length === res.data?.total
          ) {
            finished.value = true
            pullend.value = true
          }
          nextTick(() => {
            if (props.propScrollTop >= 0) {
              scrollTopHeight.value = props.propScrollTop
            }
          })
          emit('listChange', {
            ...res,
            offset: offset.value,
            resList: state.data,
            isInit: init
          })
        })
        .catch(() => {
          uni.hideLoading()
          // this.error = true
          state.data = []
          finished.value = true
          isSend.value = true
          pullRstore()
          emit('error')
        })
        .finally(() => {
          uni.hideLoading()
          // this.loading = false
          // if (this.refreshing) {
          //   this.refreshing = false
          // }
        })
    } catch (error) {
      emit('error')
      uni.hideLoading()
      state.data = []
      finished.value = true
      isSend.value = true
      pullRstore()
    }
  }
}

const setData = (data) => {
  state.data = data
}

onLoad(() => {})

// 自定义下拉刷新控件被下拉
const pullHeight = ref(0)
const onPulling = _.debounce((e) => {
  // if (e.detail.deltaY < 0) return // 防止上滑页面也触发下拉
  // triggered.value = true
}, 1000)

const pullRstore = () => {
  triggered.value = 'restore' // 需要重置
  setTimeout(async () => {
    triggered.value = false
    _freshing.value = false
  }, 500)
}
const isPull = ref(false)
const onRefresh = () => {
  isPull.value = true
  offset.value = offsetMin.value
  triggered.value = 'restore' // 需要重置
  setTimeout(async () => {
    if (offset.value === 1 || pullend.value) {
      pullend.value = true
      await getData(true)
    } else {
      await getData()
    }
    triggered.value = false
    _freshing.value = false
  }, 500)
  emit('onRefresh')
}
// 自定义下拉刷新被复位
const onRestore = () => {
  triggered.value = 'restore' // 需要重置
}
// 自定义下拉刷新被中止
const onAbort = () => {}
const onScroll = (e) => {
  scrollTop.value = e.detail.scrollTop
}
const scrolltolower = () => {
  isPull.value = false
  offset.value = offsetMax.value
  getData()
}
const scrolltoupper = () => {
  if (offset.value > offsetMax.value) {
    offsetMax.value = offset.value
  }
  isPull.value = true
  if (offset.value === 1 || offsetMin.value === 1) {
    pullend.value = true
    return
  } else {
    offset.value = offsetMin.value
    getData()
  }
}
const clearData = () => {
  isSend.value = false
  state.data = []
}
onShow(async () => {
  uni.hideLoading()
  scrollTopHeight.value = scrollTop.value
})
onHide(() => {})
onMounted(() => {})
defineExpose({
  getData,
  clearData,
  setData,
  scrollTop
})
</script>
<style lang="scss" scoped>
.bottom-text {
  font-size: 20rpx;
  color: #666;
  height: 80rpx;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
