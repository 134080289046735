import type { App } from 'vue'
import { buried } from '@/directives/buried'
/**
 * 注册全局指令
 * @since 2022.10.21
 */
export function setupGlobalDirectives (app: App<Element>) {
  // 注册（对象形式的指令）
  // app.directive('buried', buried)
  app.directive('clickOnce', {
    mounted (el, binding, vnode) {
      // uni.report()
      // console.log('clickOnce1', el, JSON.stringify(el))
      console.log('clickOnce2', binding)
      console.log('clickOnce3', vnode, JSON.stringify(vnode))
      // el.addEventListener('click', () => {
      //   console.log('clickOnce2', el)
      //   if (!el.disabled) {
      //     el.disabled = true;
      //     setTimeout(() => {
      //       el.disabled = false;
      //     }, binding.value || 1000);
      //   }
      // });
    }
  });
}