<template>
  <view class="text-view" :style="{ fontSize: fontSize + 'rpx' }" v-if="newContent" @click.stop="showClick()">
    <!-- 'line-height': platform === 'ios' ? '40rpx' : '38rpx'  -->
    <view class="text" :style="{ maxHeight: textStyle.maxHeightStyle, color: color, }">
      <view class="seat" :style="{ height: textStyle.seatHeight }"></view>
      <!-- @click.stop="showClick(showall)" -->
      <view class="is-show" v-show="isShow && !showall">
        <!-- ...展开>> -->
        <slot name="showText"></slot>
      </view>
      <view v-html="newContent"></view>
      <!-- @click.stop="showClick(showall)" -->
      <view class="is-hidden" v-show="isShow && showall">
        <!-- 收起>> -->
        <slot name="hiddenText"></slot>
      </view>
    </view>
    <view class="hidden-info" v-html="newContent"></view>
  </view>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, getCurrentInstance, watch, nextTick } from 'vue'
const showall = ref(false) //用来判断是否超出规定行数
const isShow = ref(false)
const instance = getCurrentInstance()
type Props = {
  isHidden: boolean //控制展开和收起
  content: string //内容
  lineClamp: number //多少行显示展开按钮
  fontSize?: number //字号大小
  color?: string //颜色
  disableClick?: boolean // 禁用点击
}
const prop = withDefaults(defineProps<Props>(), {
  isHidden: true,
  content: '',
  lineClamp: 2,
  fontSize: 24, //单位：rpx
  color: '#ffffff',
  disableClick: false
})

const emit = defineEmits<{
  (e: 'showClick', isShowAll: boolean): void
}>()

const newContent = ref('')
watch(
  () => prop.content,
  (val) => {
    if (val) {
      newContent.value = val
    }
  },
  { immediate: true, deep: true }
)

const platform = uni.getSystemInfoSync().platform

const rpxToPx = (rpx: number) => {
  let deviceWidth = uni.getSystemInfoSync().windowWidth //获取设备屏幕宽度
  let px = (deviceWidth / 750) * Number(rpx)
  return Math.floor(px)
}

const textStyle = computed<any>(() => {
  let height = 1.43 * prop.lineClamp
  let style = `${showall.value || !prop.isHidden ? 'none' : height + 'em'}`
  let seatHeight = `${showall.value || !prop.isHidden ? 'none' : height - 1.29 + 'em'}`
  let styleObj = {
    maxHeightStyle: style,
    seatHeight
  }
  return styleObj
})

const showClick = (isShowall?: boolean) => {
  if (prop.disableClick) {
    emit('showClick', true)
  } else {
    showall.value = !isShowall
    emit('showClick', showall.value)
  }
}

let textHeight = ref(0)
const getMaxHeight = () => {
  nextTick(() => {
    // 获取所有文本在html中的高度
    let query = uni.createSelectorQuery().in(instance)
    query
      .select('.hidden-info')
      .boundingClientRect((data: any) => {
        if (!data || data.length === 0) {
          setTimeout(() => {
            getMaxHeight()
          }, 10)
          return
        } else {
          textHeight.value = Number(data.height)
          //行数 = 文本高度 / 行高；
          let lineNum = textHeight.value / rpxToPx(prop.fontSize)
          isShow.value = lineNum > prop.lineClamp + 1.2 ? true : false
        }
      })
      .exec()
  })

}
onMounted(() => {
  if (!prop.isHidden) {
    return
  }
  nextTick(() => {
    getMaxHeight()
  })
})
</script>

<style lang="scss" scoped>
.text-view {
  display: flex;
  overflow: hidden;
  width: 100%;
  position: relative;
  white-space: unset !important;

  .text {
    position: relative;
    overflow: hidden;
    text-align: justify;
    text-overflow: ellipsis;
    word-break: break-all;
    line-height: 1.5;
   width: 100%;
    // line-height: 48rpx;
  }

  .hidden-info {
    width: 100%;
    position: absolute;
    opacity: 0;
  }

  .is-hidden {
    position: relative;
    float: right;
    color: #34abec;
    z-index: 99;
  }

  .is-show {
    float: right;
    background: transparent;
    color: #fff;
    position: relative;
    z-index: 99;
    clear: both;
  }

  .seat {
    float: right;
  }
}
</style>
