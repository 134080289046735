<template>
  <view v-if="item.music_id">
    <view class="f-row-b-c item-music-item">
      <!-- :class="{ 'item-playing': storeSongID === item.music_id && isPlay && props.currentTab === isUserLike }" -->
      <view
        class="f-row-s-c item-music-item-l"
        :class="{ 'item-playing': storeSongID === item.music_id }"
        @click.stop="playHandle"
      >
        <!-- v-if="storeSongID === item.music_id && isPlay && props.currentTab === isUserLike" -->
        <image
          v-if="storeSongID === item.music_id && isPlay"
          class="foot-playing"
          src="@/static/img/common/player/sound-Y.png"
        ></image>
        <image
          v-else-if="storeSongID === item.music_id && !isPlay"
          class="foot-playing"
          src="@/static/img/common/player/music-pause.png"
        ></image>
        <!-- f-row-c-c  -->
        <view class="t-overflow music-info">
          <view class="item-music-name">{{ item.music_name }}</view>
          <view class="item-music-singer ml12" v-if="item.singer">- {{ item.singer }}</view>
        </view>
      </view>
      <!-- <view class="item-music-item-r" @click="likeClick(item)">
        <image class="like-icon" v-if="item.is_favor" src="@/static/img/audio/like-r.png"></image>
        <image class="like-icon" v-else src="@/static/img/audio/like-w.png"></image>
      </view> -->
    </view>
  </view>
</template>
<script lang="ts" setup>
import {  SongsApi } from '@/api'
import { APIResponse } from '@/interface'
import { useGlobalPlayerStore } from '@/store/modules'
import { pyToast } from '@/utils/util'
import { computed } from 'vue'
import { MusicTabListTypeEnum, GlobalMusicPlayModeEnum } from '@/enum'

export interface Props {
  item: any
  currentTab: number
}

const props = withDefaults(defineProps<Props>(), {
  item: {} as any,
  currentTab: MusicTabListTypeEnum.ALLMUSICLIST
})

const emit = defineEmits<{
  (e: 'itemClick'): void
  (e: 'deleteLike'): void
  (e: 'joinLike'): void
}>()

const globalPlayerStore = useGlobalPlayerStore()

let isUserLike = computed(() => {
  return +globalPlayerStore.footPlayer.isUserLike
})

let currentIndex = computed(() => {
  return +globalPlayerStore.footPlayer.currentIndex
})
let storeSongID = computed(() => {
  return +globalPlayerStore.songId
})
let isPlay = computed(() => {
  return globalPlayerStore.isPlay
})

const setFootPlayer = () => {
  globalPlayerStore.setFootPlayer({
    ...globalPlayerStore.footPlayer,
    // 如果是我喜欢的音乐页面，则设置3, 用来区分全局播放器全部音乐和我的喜欢不同时播放同一首歌
    isUserLike: props.currentTab
  })
}

const playHandle = () => {
  setFootPlayer()
  if (props.currentTab === MusicTabListTypeEnum.MYLIKESLIST) {
    globalPlayerStore.setFavorMusic(props.item)
  }
  const { source_url: url, music_id: id, cover: img, title } = props.item
  if (+storeSongID.value === +id) {
    globalPlayerStore.setPlayToggle()
  } else {
    globalPlayerStore.setPlayerSrc('', id)
    globalPlayerStore.setPlay()
    emit('itemClick')
  }
}

// const likeClick = async (item) => {
//   if (+item.is_favor === 1) {
//     try {
//       LikesApi.apiDeleteLike({
//         worksIds: props.item.music_id
//       }).then((res: APIResponse) => {
//         if (res && res.code === 0) {
//           props.item.is_favor = 0
//           emit('deleteLike')
//         }
//       })
//     } catch (error) {}
//   } else if (+item.is_favor === 0) {
//     try {
//       const res = (await SongsApi.apiJoinLike({ id: props.item.music_id })) as APIResponse
//       if (res && !res.code) {
//         props.item.is_favor = 1
//         emit('joinLike')
//         // pyToast('操作成功')
//       }
//     } catch (error) {}
//   }
// }
</script>
<style lang="scss" scoped>
.item-music-item {
  box-sizing: border-box;
  margin: 0 48rpx;
  border-bottom: 2rpx solid #49474b;
  height: 94rpx;
  .item-music-item-l {
    height: 92rpx;
    line-height: 92rpx;
    // width: 600rpx;
    width: 580rpx;
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .item-music-item-r {
    width: 70rpx;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 92rpx;
    line-height: 92rpx;
    flex: none;
  }
}
.foot-playing {
  height: 34rpx;
  width: 34rpx;
  margin-right: 16rpx;
  flex: none;
}
.music-info {
  width: 560rpx;
}
.item-music-name {
  font-size: 28rpx;
  font-weight: 600;
  color: #ffffff;
  display: inline-block;
}
.item-music-singer {
  font-size: 28rpx;
  font-weight: 400;
  color: $color-primary;
  display: inline-block;
}

.like-icon {
  height: 36rpx;
  width: 36rpx;
}
.item-playing {
  .item-music-name,
  .item-music-singer {
    color: #f7ca18;
  }
}
</style>
