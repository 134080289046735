import { CommonApi } from '@/api/common';
import { APIResponse } from './../interface/index';
import { isAuditStatus, getQueryString } from './util'
import { reactive, ref, computed } from 'vue'

/**
 *
 * @param config
 * emergency_close 财产集市紧急关闭 1:关闭 0:未关闭
 * platform_upgrade: 1 平台升级中。0 正常
 * @returns
 */
export const handleConfig = (config: any) => {
  if (+config.platform_upgrade === 1) {
    uni.reLaunch({
      url: '/pages/common/maintain'
    })
    return
  }
}
/**
 *
 * @param url
 * @returns
 */
export const checkLogin = (url: string) => {
  const getUserInfo = uni.getStorageSync('userInfo')
  let token = getUserInfo.token
  if (!token) {
    // return uni.navigateTo({
    //   url: '/pages/login/index'
    // })
  }
  uni.navigateTo({ url })
}

// 设置tabbar
export const setTabbar = async () => {
  let temp = await isAuditStatus()
  if (temp) {
    // #ifdef APP-PLUS
    uni.setTabBarItem({
      index: 1,
      visible: false
    })
    uni.setTabBarItem({
      index: 2,
      visible: false
    })
    // #endif
    // #ifdef H5
    uni.setTabBarItem({
      index: 2,
      visible: true
    })
    // #endif
  } else {
    // #ifdef APP-PLUS
    uni.setTabBarItem({
      index: 1,
      visible: true
    })
    uni.setTabBarItem({
      index: 2,
      visible: true
    })
    // #endif
    // #ifdef H5
    uni.setTabBarItem({
      index: 2,
      visible: true
    })
    // #endif
  }
}
