import { inBrowser, type Numeric } from './basic';

const platform = uni.getSystemInfoSync().platform
// console.log("platform", uni.getSystemInfoSync())
export const isDef = <T> (val: T): val is NonNullable<T> =>
  val !== undefined && val !== null;

// eslint-disable-next-line @typescript-eslint/ban-types
export const isFunction = (val: unknown): val is Function =>
  typeof val === 'function';

export const isObject = (val: unknown): val is Record<any, any> =>
  val !== null && typeof val === 'object';

export const isPromise = <T = any> (val: unknown): val is Promise<T> =>
  isObject(val) && isFunction(val.then) && isFunction(val.catch);

export const isDate = (val: unknown): val is Date =>
  Object.prototype.toString.call(val) === '[object Date]' &&
  !Number.isNaN((val as Date).getTime());

export function isMobile (value: string): boolean {
  value = value.replace(/[^-|\d]/g, '');
  return (
    /^((\+86)|(86))?(1)\d{10}$/.test(value) || /^0[0-9-]{10,13}$/.test(value)
  );
}

export const isNumeric = (val: Numeric): val is string =>
  typeof val === 'number' || /^\d+(\.\d+)?$/.test(val);


/**
 * 是否iphone
 */
export const isIphone = () => {
  return platform === 'ios'
}

/**
 * 是否安卓
 */
export const isAndroid = () => {
  return platform === 'android'
}
/**
 * 是否mac电脑
 */
export const isMac = () => {
  return platform === 'mac'
}
/**
 * 是否微信
 */
export const isWeiXin = () => {
  // #ifdef H5
  if (/MicroMessenger/i.test(window.navigator.userAgent)) {
    return true
  } else {
    return false
  }
  // #endif
}
/**
 * 验证手机号格式
 * @param {String} mobile
 */
export const checkMobile = (mobile: string) => {
  return /^1\d{10}$/.test(mobile)
}


