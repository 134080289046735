import { defineStore } from 'pinia'
import { store } from '@/store'

export const useMarketStore = defineStore('app-market', {
  state: () => ({
    marketTab: 0,
    search_txt: ''
  }),
  actions: {
    setSearchTxt(val) {
      this.search_txt = val
    },
    clearSearchTxt() {
      this.search_txt = ''
    },
    setMarketTab(tab) {
      this.marketTab = tab
    }
  }
})

// Need to be used outside the setup
export function useMarketStoreWithOut() {
  return useMarketStore(store)
}
