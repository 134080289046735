<template>
  <view class="numberJpan" :style="wc" v-show="flag">
    <view class="myshuru" :style="obj" @tap.stop="flag = true">
      <view class="gb" @tap="close()" :style="gsbstyle">×</view>
      <view class="shuruTitle"> 请输入支付密码 </view>
      <view class="center-x">
        <view class="srk" v-for="i in length || 6" :id="i - 1 == xz ? 'numberJpanActive' : ''" :key="i">
          <!-- #ifdef MP-WEIXIN -->
          {{ showNum ? (arr[i] == null ? '' : arr[i]) : arr[i] != null ? '●' : '' }}
          <!-- #endif -->
          <!-- #ifndef MP-WEIXIN -->
          {{ showNum ? (arr[i - 1] == null ? '' : arr[i - 1]) : arr[i - 1] != null ? '●' : '' }}
          <!-- #endif -->
        </view>
      </view>
    </view>
    <view class="jpan" :style="tsfY">
      <view class="main">
        <!-- #ifdef MP-WEIXIN -->
        <!-- <view v-for="i in 9" @tap="numshuzi(i + 1)">{{ i + 1 }}</view> -->
        <!-- #endif -->
        <!-- #ifndef MP-WEIXIN -->
        <!-- <view v-for="i in 9" @tap="numshuzi(i)">{{ i }}</view> -->
        <!-- #endif -->
        <view class="number" v-for="item in randomArr" :key="item" @tap="numshuzi(item)">
          <template v-if="item === '收起'"><image src="@/static/img/common/arrow.png"></image></template>
          <template v-else-if="item === '删除'"><image src="@/static/img/common/delete.png"></image></template>
          <template v-else>{{ item }}</template>
        </view>
        <!-- <view @tap="close()">收起</view>
        <view @tap="del()">删除</view> -->
      </view>
    </view>
  </view>
</template>

<script>
export default {
  name: 'number-jpan',
  data() {
    return {
      wc: {
        'background-color': 'rgba(0, 0, 0, .0)'
      },
      obj: {
        opacity: 0,
        top: '50%'
      },
      flag: false,
      clear1: '',
      clear2: '',
      arr: [],
      xz: 0,
      gsbstyle: {
        opacity: 0
      },
      tsfY: {
        transform: 'translateY(100%)'
      },
      keyArr: [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]
    }
  },
  props: ['length', 'showNum'],
  computed: {
    randomArr() {
      let arr = this.keyArr
      let lastNum = arr[arr.length - 1]
      arr.pop()
      arr.push('收起')
      arr.push(lastNum)
      arr.push('删除')
      return arr
      // let arr = this.keyArr.sort((a, b) => {
      //   return Math.random() > 0.5 ? -1 : 1
      // })
      // let lastNum = arr[arr.length - 1]
      // arr.pop()
      // arr.push('收起')
      // arr.push(lastNum)
      // arr.push('删除')
      // return arr
    }
  },
  methods: {
    del() {
      if (this.xz > 0) {
        //let arr1=this.arr
        //arr1[this.xz]=""
        //this.arr=arr1
        this.arr.pop()
        this.xz--
      }
      // uni.vibrateShort()
    },
    numshuzi(num) {
      if (num === '收起') {
        this.close()
        return
      }
      if (num === '删除') {
        this.del()
        return
      }
      let arr1 = this.arr
      arr1[this.xz] = num
      this.arr = arr1
      // uni.vibrateShort()
      this.xz++
      if (this.xz == this.length) {
        let str = ''
        for (let item of this.arr) {
          str += item
        }
        this.$emit('closeChange', str)
        this.close()
      }
    },
    open() {
      this.flag = true
      this.arr = []
      this.xz = 0
      this.clear1 = setTimeout(() => {
        this.wc = 'background-color:rgba(0, 0, 0, .5)'

        this.obj = 'opacity:1;top:40%'

        this.gsbstyle = 'opacity:1'

        this.tsfY = 'transform:translateY(0%)'
      }, 100)
    },
    close() {
      this.wc = {
        'background-color': 'rgba(0, 0, 0, .0)'
      }
      this.obj = {
        opacity: 0,
        top: '50%'
      }
      this.gsbstyle = {
        opacity: 0
      }
      this.clear2 = setTimeout(() => {
        this.flag = false
      }, 1000)
      this.tsfY = {
        transform: 'translateY(100%)'
      }
    },
    xuanze(i) {
      this.xz = i
      // uni.vibrateShort()
    }
  }
}
</script>

<style lang="scss">
#numberJpanActive {
  color: #ffffff;
}
.jpan {
  width: 100vw;
  background-color: #302e33;
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
  transition: all 0.5s;
  .nav {
    text-align: center;
    line-height: 50upx;
    box-sizing: border-box;
    border-bottom: 1px solid #1e1e1e;
  }
  .main {
    width: 100%;
    height: calc(30vh - 50upx);
    background: #302e33;
    padding: 5rpx 10rpx;
    padding-bottom: 20rpx;
    image {
      width: 36rpx;
      height: 36rpx;
    }
    view {
      box-sizing: border-box;
      float: left;
      width: 33.33%;
      height: 25%;
      font-size: 40upx;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 10rpx solid #302e33;
      border-bottom: 0;
      color: #fff;
      background: #000;
      overflow: hidden;
      border-radius: 24rpx;
    }
  }
}
.numberJpan {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #1e1e1e;
  transition: all 0.5s;
  z-index: 999;
  .myshuru {
    transition: all 0.5s;
    position: absolute;
    width: 606rpx;
    height: 360rpx;
    top: 50%;
    opacity: 0;
    transform: translate(-50%, -50%);
    background-color: #302e33;
    left: 50%;
    color: #fff;
    border-radius: 20upx;
    overflow: hidden;
    .shuruTitle {
      margin: 100rpx auto 60rpx;
      font-weight: 900;
      text-align: center;
      font-size: 30upx;
    }
    .center-x {
      width: 100%;
      // position: absolute;
      overflow: hidden;
      // left: 50%;
      // transform: translateX(-50%);
      // bottom: 50upx;
      display: flex;
      justify-content: space-between;
      padding: 0 72rpx;
      .srk {
        width: 64rpx;
        height: 84rpx;
        line-height: 80upx;
        display: flex;
        justify-content: center;
        align-items: center;
        float: left;
        box-sizing: border-box;
        transition: all 0.4s;
        background: #000;
        border-radius: 12rpx;
      }
    }
  }
}
.gb {
  position: absolute;
  font-size: 50upx;
  top: 10rpx;
  color: #aaaaaa;
  right: 30rpx;
  transition: all 0.5s;
}
</style>
