<template>
  <!-- v-model="visible" -->
  <PyDialog
    v-model="visible"
    @confirm="handleConfirmDialog"
    @cancel="handleCloseDialog"
    :height="dialogHeight"
    :width="dialogWidth"
    @close="handleCloseDialog"
    :cancelText="cancelText"
    :confirmText="confirmText"
    :maskClick="true"
  >
    <template #content>
      <view class="dialog-content f-row-c-c mt50" v-if="dialogTitle === '授权'">
        <view class="dialog-title">欢迎使用AI book</view>
      </view>
      <view class="dialog-content" v-if="dialogTitle === '注册'">
        <view class="dialog-title">请绑定手机号后使用</view>
        <view class="left">
          <view class="f-row-c-c box-title">手机号<image src="@/static/img/user/xinghao.png"></image> </view>
          <input class="mobile" v-model="phone" placeholder="请输入手机号" type="number" :maxlength="11" />
          <view class="f-row-c-c box-title">图形验证码<image src="@/static/img/user/xinghao.png"></image> </view>
          <view class="code">
            <input v-model="validate_val" placeholder="请输入图形验证码" type="number" :maxlength="6" />
            <view class="getcode" @click="generateCaptcha">
              <img class="captcha" :src="captcha_b64s" alt="" />
            </view>
          </view>

          <view class="f-row-c-c box-title">手机验证码<image src="@/static/img/user/xinghao.png"></image> </view>
          <view class="code">
            <input v-model="code" placeholder="请输入手机验证码" type="number" :maxlength="6" />
            <view class="getcode" v-if="!isCountDown" @click="getCode">获取验证码</view>
            <view class="getcode" v-else>
              <CountDown :time="59000" :autoStart="true" @finish="finish">
                <template v-slot="timeData">
                  <text style="color: #fff"> {{ timeData.second }} s </text>
                </template>
              </CountDown>
            </view>
          </view>
        </view>
        <view class="login_agreement mt20">
          <image class="icon" v-if="checked" @click="checked = false" src="@/static/img/login/select.png"></image>
          <image class="icon" v-else @click="checked = true" src="@/static/img/login/unselect.png"></image>
          <view class="login_agreement_right">
            未注册的手机号验证后会自动生成账号，且代表您已阅读并同意
            <view class="inline-block" @click.stop="handleCheckAgreement('protocol')">《用户协议》</view>
            <view class="inline-block">及</view>
            <view class="inline-block" @click.stop="handleCheckAgreement('privacy')">《隐私协议》</view>
          </view>
        </view>
      </view>
    </template>
  </PyDialog>
</template>
<script lang="ts" setup>
import { onMounted, reactive, ref, computed, inject } from 'vue'
import { useRouter } from '@/hooks/routerLink'
import { pyToast, checkMobile, getUniAppEnv, getQueryString } from '@/utils'
import { CommonApi, UserApi } from '@/api/index'
import { APIResponse } from '@/interface'
import { useUserStoreWithOut } from '@/store/modules'
import { onShow } from '@dcloudio/uni-app'

const emit = defineEmits<{
  (e: 'loginSuccess', data?: any): void
  (e: 'handleCloseDialog'): void
}>()

const userStore = useUserStoreWithOut()
const router = useRouter()
let phone = ref('')
let code = ref('')
let validate_val = ref('')
let checked = ref(false)
const state = reactive({
  userInfo: null as any
})
const isCountDown = ref(false)
// 手机号校验是否通过
const isPhoneSuccess = computed<boolean>(() => {
  return checkMobile(phone.value)
})
// 获取验证码接口
const getCode = async () => {
  // 手机号判断
  if (!isPhoneSuccess.value) {
    return pyToast('请输入正确的手机号')
  }
  if (!validate_val.value) {
    return pyToast('请输入图形验证码')
  }
  try {
    const res = (await CommonApi.send_phone_msg_code({
      phone: phone.value,
      captcha_id: captcha_id.value,
      validate_val: validate_val.value
    })) as APIResponse
    if (res && !res.code) {
      isCountDown.value = true
      return pyToast('验证码已发送到您的手机')
    } else {
    }
  } catch (error) {}
}

let captcha_b64s = ref('')
let captcha_id = ref('')
const generateCaptcha = async () => {
  try {
    const res: any = await CommonApi.generateCaptcha({})
    if (res && !res.code) {
      captcha_b64s.value = res.data.captcha_b64s
      captcha_id.value = res.data.captcha_id
    }
  } catch (error: any) {
    return pyToast(error.data.error)
  }
}

const finish = () => {
  isCountDown.value = false
}
// ------ dialog start -------- //
let visible = ref(true)
let dialogHeight = ref('')
let dialogWidth = ref('606rpx')
let dialogTitle = ref('授权')
let cancelText = ref('')
let confirmText = ref('')
const handleDialogOption = (title: string) => {
  dialogTitle.value = title
  if (dialogTitle.value === '注册') {
    dialogHeight.value = '864rpx'
    confirmText.value = '提交'
  } else if (dialogTitle.value === '授权') {
    dialogHeight.value = '320rpx'
    confirmText.value = '获取授权,立即使用'
  }
}

const handleConfirmDialog = async () => {
  if (dialogTitle.value === '授权') {
    // #ifdef H5
    let authUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${
      getUniAppEnv().VITE_APP_WX_APPID
    }&redirect_uri=${encodeURIComponent(
      `${location.origin}/pages/login/authBack`
    )}&response_type=code&scope=snsapi_userinfo&state=111#wechat_redirect`
    window.location.replace(authUrl)
    //  #endif
  } else if (dialogTitle.value === '注册') {
    if (!phone.value || phone.value.length !== 11) {
      pyToast('请正确输入手机号')
      return
    }
    if (!validate_val.value) {
      pyToast('请输入图形验证码')
      return
    }
    if (!code.value || code.value.length !== 6) {
      pyToast('验证码错误')
      return
    }
    if (!checked.value) {
      return pyToast('请先同意《用户协议》及《隐私政策》')
    }
    try {
      let params: any = {}
      params = {
        phone: phone.value,
        msg_code: code.value
      }
      const res = (await CommonApi.phoneLogin(params)) as APIResponse
      if (res && !res.code) {
        const { token, phone, is_new_user } = res.data
        await userStore.setToken(token)
        await userStore.setUserInfo(res.data)
        uni.setStorageSync('aibooktoken', token)
        uni.setStorageSync('aibookuserInfo', res.data)
      } else {
      }
    } catch (error) {}
  }
}
const handleCancelDialog = () => {}
const handleCloseDialog = () => {
  visible.value = false
  emit('handleCloseDialog')
}

// ------ dialog end -------- //

// 隐私协议跳转
const handleCheckAgreement = (url: string) => {
  uni.navigateTo({
    url: `/pages/common/webview?&url=${encodeURIComponent(
      `${getUniAppEnv().VITE_APP_ROOT_URL}pages/common/${url}`
    )}&t=${new Date().getTime()}`
  })
}
const getUserInfo = async () => {
  try {
    CommonApi.getUserInfo().then((res: APIResponse) => {
      if (res && !res.code) {
        state.userInfo = res.data
        if (state.userInfo.phone) {
        } else {
          handleDialogOption('注册')
        }
      }
    })
  } catch (error) {}
}

onMounted(() => {})

onShow(() => {
  if (uni.getStorageSync('aibooktoken')) {
    getUserInfo()
    // handleDialogOption('注册')
  } else {
    handleDialogOption('授权')
    // handleDialogOption('注册')
  }
})
</script>
<style lang="scss" scoped>
.dialog-content {
  padding: 48rpx;
  display: inline-block;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 28rpx;
  font-weight: 400;
  color: #333;
  vertical-align: middle;
  .dialog-title {
    width: 100%;
    font-size: 32rpx;
    font-weight: 600;
    color: #333;
    line-height: 48rpx;
    text-align: center;
  }
  .left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 510rpx;
    .mobile {
      margin-top: 16rpx;
      width: 510rpx;
    }
    .code {
      margin-top: 16rpx;
      position: relative;
      width: 510rpx;
    }
    .getcode {
      position: absolute;
      right: 0;
      top: calc(50% - 16rpx);
      font-size: 28rpx;
      font-weight: 400;
      color: rgb(236, 108, 57);
      line-height: 32rpx;
      height: 32rpx;
      width: 200rpx;
      display: flex;
      justify-content: center;
      align-items: center;
      border-left: 2rpx solid rgba(172, 174, 191, 0.5);
    }
    input {
      width: 510rpx;
      height: 84rpx;
      background: #eff1f4;
      color: #333;
      border-radius: 12rpx;
      font-size: 28rpx;
      font-weight: 400;
      line-height: 32rpx;
      padding: 0 40rpx;
    }
  }
  .slider-box {
    width: 510rpx;
    overflow: hidden;
    margin-top: 48rpx;
    margin-bottom: 48rpx;
    :deep(.nc_scale) {
      overflow: hidden;
      height: 84rpx;
    }
    :deep(.nc_iconfont) {
      overflow: hidden;
      width: 120rpx;
      height: 84rpx;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  view {
    display: inline-block;
  }
  .yellow {
    color: #f7ca18;
  }
}
.box-title {
  color: $color-primary;
  font-size: 24rpx;
  margin-top: 40rpx;
  image {
    width: 12rpx;
    height: 12rpx;
    margin-left: 6rpx;
  }
}
.login_agreement {
  display: flex !important;
  align-items: center;
  // padding: 0 48rpx;
  color: $color-primary;
  // margin-top: 64rpx;
  // margin-bottom: 16rpx;
  line-height: 1.8;
  font-size: 24rpx;

  .icon {
    height: 36rpx;
    width: 36rpx;
    flex: none;
  }

  .login_agreement_right {
    margin-left: 16rpx;
    font-size: 20rpx;
  }
}
:deep(.nc-lang-cnt) {
  margin-left: 42rpx;
}
</style>
