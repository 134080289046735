import { createSSRApp } from 'vue'
import App from './App.vue'
import { setupGlobalProperties } from '@/utils/vue-ext'
import { setupGlobalComponents } from '@/utils/global-components'
import { setupGlobalDirectives } from '@/directives/index'
import { setupStore } from '@/store'
// import { setupGlobalStat } from '@/utils/stat'
import '@/router/index'

export function createApp() {
  const app = createSSRApp(App)
  setupStore(app)
  setupGlobalComponents(app)
  setupGlobalProperties(app)
  setupGlobalDirectives(app)
  // setupGlobalStat(app)
  return {
    app
  }
}
