<template>
  <view class="foot-player-container">
    <page-meta :page-style="'overflow:' + (popupVisible ? 'hidden' : 'visible')"></page-meta>
    <view
      v-if="footPlayerVisible"
      class="foot-player"
      :style="{
        bottom: tabbarBottom + 'px',
        'border-top': globalPlayerStore.lyricInfo.maxTime ? '0rpx solid #ec6c39' : '2rpx solid #ec6c39'
      }"
    >
      <slider
        v-if="globalPlayerStore.lyricInfo.maxTime"
        :min="0"
        :max="globalPlayerStore.lyricInfo.maxTime"
        :value="sliderVal"
        activeColor="#ec6c39"
        backgroundColor="#ec6c39"
        block-color="#ec6c39"
        block-size="2"
        @change="sliderChange"
        @changing="sliderChanging"
      />
      <view class="foot-player-container f-row-b-c">
        <view class="player-left f-row-s-c" @click="openPlayPopup">
          <view class="music-img mr20">
            <!-- <image class="music-img-default mr20" src="@/static/img/common/player/music_default.png"> </image> -->
            <!-- <image class="music-cover loading" v-if="isLoading" src="@/static/img/common/player/icon-loading.svg">
            </image> -->
            <image
              class="music-cover mr20"
              :class="{ playing: isPlay && !isLoading, paused: !isPlay }"
              v-if="storeMusicInfo.cover"
              :src="storeMusicInfo.cover"
            ></image>
          </view>

          <view class="music-info">
            <view class="music-name" :style="{ flex: storeMusicInfo.title?.length > 14 ? '1' : '' }">{{
              storeMusicInfo.title || '书籍名称'
            }}</view>
            <!-- <view class="music-singer ml12" :style="{ flex: storeMusicInfo.title?.length > 14 ? 'none' : '' }"
              >- {{ storeMusicInfo.singer || '歌手名' }}</view
            > -->
          </view>
        </view>
        <view class="f-row-c-c player-right">
          <view @click="playHandle" class="player-right-children">
            <view class="attach-img">
              <image class="attach-img-play" v-if="globalPlayerStore.isPlay" src="@/static/img/audio/play.png" />
              <image class="attach-img-stop" v-else src="@/static/img/audio/play_icon.png" />
            </view>
          </view>
          <!-- <view class="player-right-children">
            <image class="music-list ml20" @click="playListClick" src="@/static/img/common/player/foot-list.png">
            </image>
          </view> -->
        </view>
      </view>
    </view>
  </view>
  <uni-popup
    ref="playerListRef"
    type="bottom"
    class="play-list-ref"
    @change="popupChange"
    mask-background-color="rgba(0,0,0,0.7)"
  >
    <view class="play-list">
      <swiper
        class="swiper"
        :current="currentIndex"
        :indicator-dots="true"
        :autoplay="false"
        indicator-color="#636164"
        indicator-active-color="#ffffff"
        @change="swiperChange"
      >
        <swiper-item v-for="(i, idx) in 4" :key="idx">
          <view class="top f-row-b-c">
            <view class="f-row-c-c">
              <view class="colorff f32 fw600">{{ musicTypeList[+idx] }}</view>
              <view class="top-number f24 fw400">({{ total[idx] }}首)</view>
            </view>
            <template v-for="i in loopList" :key="i.key">
              <view class="f-row-c-c" @click="loopPlay(i.key)" v-if="+loopValue === i.key">
                <image class="music-type" :src="i.imgSrc"></image>
                <view class="colorff f24 fw600 ml8">{{ i.title }}</view>
                <template
                  v-if="
                    +currentIndex === MusicTabListTypeEnum.MUSICDAILYLIST &&
                    +loopValue === GlobalMusicPlayModeEnum.RANDOM
                  "
                >
                  <image class="music-type" src="@/static/img/common/player/player-random@2x.png"></image>
                  <view class="colorff f24 fw600 ml8">随机播放</view>
                </template>
              </view>
            </template>
          </view>
          <view
            v-if="
              (+currentIndex === MusicTabListTypeEnum.MYLIKESLIST ||
                +currentIndex === MusicTabListTypeEnum.MYCOLLECTMUSICLIST) &&
              !getToken
            "
          >
            <view class="empty">
              <image src="@/static/img/audio/icon_empty.svg" alt="" />
              <view class="favor-login-btn" @click="goLogin">{{ empytData }}</view>
            </view>
          </view>
          <template v-else>
            <PullList
              v-if="+currentIndex === +idx"
              ref="ListRef"
              :isPullLoadList="true"
              :apiAction="apiAction[+currentIndex]"
              :apiParams="apiParams[+currentIndex]"
              :propScrollTop="allMusicScrollTop[+currentIndex]"
              :refresherEnabled="true"
              :resDataKey="resDataKey[+currentIndex]"
              :immediateCheck="false"
              :isMountedLoad="true"
              :isApiAction="false"
              loadingMoreNoMoreText="到底了 ～"
              refresherBackground="#302e33"
              @listChange="listChange"
            >
              <template v-slot:content="{ content }">
                <template v-for="item in state.resList" :key="item.music_id">
                  <SongsItem
                    :currentTab="currentIndex"
                    :item="item"
                    v-if="item.music_id"
                    @deleteLike="deleteLike"
                    @itemClick="itemClick"
                    @joinLike="joinLike"
                  ></SongsItem>
                </template>
              </template>
              <template #empty>
                <view class="empty">
                  <image src="@/static/img/audio/icon_empty.svg" alt="" />
                  <text>{{ empytData }}</text>
                </view>
              </template>
            </PullList>
          </template>
        </swiper-item>
      </swiper>
      <view class="close" @click="closePopup"> 关闭 </view>
    </view>
  </uni-popup>
</template>
<script lang="ts" setup>
//  footPlayer: {
//     currentIndex: 0, // 0:全部 1:每日推荐 2:我的喜欢
//     loopValue: 1, // 1：列表循环 2:单曲播放 3:随机播放
//     isUserLike: 0 // 是否是从我的 -> 我喜欢的音乐点击
//   },
/**
 * 全局底部播放器逻辑梳理
 * 1、切换tab不算选择播放模式，只有点击其下面某首歌才算切换
 * 2、左右滑动歌单时，若点击其他歌单的播放模式，不能算作切换播放歌单
 * 3、进入每日推荐或者我的喜欢，全局底部播放器则切换为相应的tab，其他页面则默认展示全部音乐
 * 4、
 */

import { CommonApi,SongsApi, UserApi } from '@/api'
import { useGlobalPlayerStore, useUserStoreWithOut, useCommonStoreWithOut } from '@/store/modules'
import { pyToast } from '@/utils/util'
import { onShow } from '@dcloudio/uni-app'
import { computed, ref, reactive, onMounted, watch, nextTick } from 'vue'
import SongsItem from './components/songs-item.vue'
import { useRouter } from '@/hooks/routerLink'
import routerConfig from '@/router/routerConfig'
import { get_page_vm } from '@/utils/stat/pageInfo'
import { APIResponse } from '@/interface'
import { MusicTabListTypeEnum, GlobalMusicPlayModeEnum } from '@/enum'

const emit = defineEmits<{
  (e: 'joinLike'): void
  (e: 'deleteLike'): void
}>()
const globalPlayerStore = useGlobalPlayerStore()
const userStore = useUserStoreWithOut()
const commonStore = useCommonStoreWithOut()
const router = useRouter()

const sys = uni.getSystemInfoSync()
const windowBottom = ref(sys.windowBottom || 0)
const footPlayerVisible = ref(true)
const playerListRef = ref<any>(null)
const songsDetailRef = ref<any>(null)
const showPopup = ref(false)
const popupVisible = ref(false)
const ListRef = ref<any>(null)
// 全部音乐
const musicTypeList = {
  [MusicTabListTypeEnum.ALLMUSICLIST]: '全部音乐',
  [MusicTabListTypeEnum.MUSICDAILYLIST]: '每日推荐',
  [MusicTabListTypeEnum.MYCOLLECTMUSICLIST]: '我的藏品',
  [MusicTabListTypeEnum.MYLIKESLIST]: '我的喜欢'
}
let loopList = ref([
  {
    title: '列表循环',
    imgSrc: '../../static/img/common/player/loop@2x.png',
    key: GlobalMusicPlayModeEnum.ORDER
  },
  {
    title: '单曲播放',
    imgSrc: '../../static/img/common/player/single@2x.png',
    key: GlobalMusicPlayModeEnum.SINGLE
  },
  {
    // title: '随机播放',
    // imgSrc: '../../static/img/common/player/player-random@2x.png',
    key: GlobalMusicPlayModeEnum.RANDOM
  }
])
const empytData = ref('暂无数据')
let apiAction: any = reactive({
  0: null,
  1: null,
  2: null,
  3: null
})
let apiParams = reactive({
  0: { offset: 0, fe_change: '' },
  1: { offset: 0, fe_change: '' },
  2: { offset: 0, fe_change: '' },
  3: { offset: 0, fe_change: '' }
})
let resDataKey: any = reactive({
  0: 'list',
  1: 'list',
  2: 'list',
  3: 'data'
})
const total = reactive({
  0: 0,
  1: 0,
  2: 0,
  3: 0
})
const allMusicScrollTop = reactive({
  0: -1,
  1: 0,
  2: 0,
  3: -1
})

const currentIndex = ref(+uni.getStorageSync('footPlayer').currentIndex || MusicTabListTypeEnum.ALLMUSICLIST)
const loopValue = ref(+uni.getStorageSync('footPlayer').loopValue || GlobalMusicPlayModeEnum.ORDER)
const isUserLike = ref(+uni.getStorageSync('footPlayer').isUserLike || 0)
let currentVal = ref(+globalPlayerStore.lyricInfo.nowtimes)

const state = reactive({
  resList: [] as any
})

let storeSongID = computed(() => {
  return globalPlayerStore.songId
})
let storeMusicInfo = computed<any>(() => {
  return globalPlayerStore.musicInfo
})
let isPlay = computed(() => {
  return globalPlayerStore.isPlay
})
const isLoading = computed(() => {
  return globalPlayerStore.loading
})
const getToken = computed(() => {
  return userStore.getToken || uni.getStorageSync('aibooktoken')
})
/**
 * 在tabbar页面popup展示时隐藏tabbar，反之
 */
const popupChange = (e) => {
  popupVisible.value = e.show
  globalPlayerStore.setPlayerListVisible(e.show)

  if (e.show) {
    footPlayerVisible.value = false
    uni.hideTabBar()
  } else {
    setTimeout(() => {
      footPlayerVisible.value = true
      uni.showTabBar()
      // 设置为0不会调接口
      apiParams[currentIndex.value].offset = 0
    }, 200)
  }
}
const setMusicScrollTop = () => {
  let musicDailyLists = [] as any
  let curIndex = +currentIndex.value || +uni.getStorageSync('footPlayer').currentIndex
  if (curIndex === MusicTabListTypeEnum.MUSICDAILYLIST) {
    musicDailyLists = commonStore.getMusicDailyLists
  } else if (curIndex === MusicTabListTypeEnum.MYCOLLECTMUSICLIST) {
    musicDailyLists = uni.getStorageSync('allMyCollectLists')
  }
  let dailyIndex = musicDailyLists.findIndex((dailyItem) => dailyItem.music_id === +storeSongID.value)
  if (dailyIndex > 5) {
    allMusicScrollTop[curIndex] = 47 * (dailyIndex - 4)
  } else {
    allMusicScrollTop[curIndex] = 0
  }
}
watch(
  () => globalPlayerStore.songId,
  (val) => {
    if (!globalPlayerStore.isEnd) return // 手动点击不设置滚动距离
    if (popupVisible.value) {

      setMusicScrollTop()
    }
  }
)
let userInfo: any = reactive({})
const getUserInfo = async () => {
  const res = (await UserApi.getUserInfo()) as APIResponse
  if (res && !res.code) {
    userInfo = res.data
  }
}

const playListClick = async () => {
  playerListRef.value.open()
  try {
    currentIndex.value = +uni.getStorageSync('footPlayer').currentIndex || MusicTabListTypeEnum.ALLMUSICLIST
    if (+storeSongID.value > 0) {
      let res: any = null
      if (+currentIndex.value === MusicTabListTypeEnum.ALLMUSICLIST) {
        res = await SongsApi.getMusicLocation({
          music_id: +storeSongID.value
        })
      } else if (+currentIndex.value === MusicTabListTypeEnum.MYLIKESLIST) {
        await getUserInfo()
        res = await SongsApi.getFavorMusicLocation({
          id: +storeSongID.value,
          user_id: userInfo.id || 0
        })
      }
      if (
        +currentIndex.value === MusicTabListTypeEnum.ALLMUSICLIST ||
        +currentIndex.value === MusicTabListTypeEnum.MYLIKESLIST
      ) {
        if (res.data && res.data.music_number) {
          apiParams[currentIndex.value].offset = Math.ceil(res.data.music_number / 10)
        } else {
          apiParams[currentIndex.value].offset = 1
        }
      } else if (
        +currentIndex.value === MusicTabListTypeEnum.MUSICDAILYLIST ||
        +currentIndex.value === MusicTabListTypeEnum.MYCOLLECTMUSICLIST
      ) {
        apiParams[currentIndex.value].offset = 1
        setMusicScrollTop()
      }
      apiParams[currentIndex.value].fe_change = currentIndex.value
    }
    getListInit()
  } catch (error) {
    console.log(error)
  }
}
const closePopup = () => {
  playerListRef.value.close()
}
const openPlayPopup = () => {
  if (+storeSongID.value > 0) {
    globalPlayerStore.setPlay()
    uni.navigateTo({
      url: `/pages/songs/play?id=${storeSongID.value}&isComponent=${true}`,
      animationType: 'slide-in-bottom',
      animationDuration: 200
    })
  } else {
    return pyToast('请先选择歌曲播放')
  }
}
const closePlayPopup = () => {
  songsDetailRef.value.close()
  showPopup.value = false
}
// swiper切换 返回当前index
const swiperChange = (e) => {
  // 切换不算选择全部或者喜欢，只有点击其下面某首歌才算切换
  currentIndex.value = e.detail.current
  total[+currentIndex.value] = 0
  apiParams[currentIndex.value].offset = 1
  apiParams[currentIndex.value].fe_change = currentIndex.value
  state.resList = []
  if (
    currentIndex.value !== MusicTabListTypeEnum.MUSICDAILYLIST &&
    +uni.getStorageSync('footPlayer').loopValue === GlobalMusicPlayModeEnum.RANDOM
  ) {
    //  非每日推荐tab，不展示随机播放
    loopValue.value = GlobalMusicPlayModeEnum.ORDER
  } else {
    loopValue.value = +uni.getStorageSync('footPlayer').loopValue || GlobalMusicPlayModeEnum.ORDER
  }
  getListInit()
}
// const total = ref(0)
// 列表返回
const listChange = (res: any) => {
  if (+currentIndex.value === MusicTabListTypeEnum.MUSICDAILYLIST) {
    total[currentIndex.value] = res.data.list?.length || 0
  } else {
    total[currentIndex.value] = res.data.total || 0
  }
  // const curMusicIndex = res.resList.findIndex((item) => item.music_id === +storeSongID.value)
  // // if (!isHandleDefault.value) {
  // // 当前列表中有值
  // if (curMusicIndex > -1) {
  //   const curItem = res.resList[curMusicIndex]
  //   if (curMusicIndex !== 4) {
  //     res.resList.splice(curMusicIndex, 1)
  //     res.resList.splice(4, 0, curItem)
  //   }
  // }
  // }
  // 根据music_id重复去重
  // let obj = {}
  // res.resList = res.resList.reduce((item, next) => {
  //   obj[next.music_id] ? '' : (obj[next.music_id] = true && item.push(next))
  //   return item
  // }, [])
  if (+currentIndex.value === MusicTabListTypeEnum.MYCOLLECTMUSICLIST) {
    commonStore.setAllCollectLists(res.resList)
  }
  state.resList = res.resList
}

let sliding = ref(false)
let sliderVal = computed(() => {
  if (sliding.value) {
    return +currentVal
  } else {
    return +globalPlayerStore.lyricInfo.nowtimes
  }
})
const setFootPlayer = (options?: any) => {
  if (options) {
    globalPlayerStore.setFootPlayer(options)
  } else {
    globalPlayerStore.setFootPlayer({
      ...globalPlayerStore.footPlayer,
      currentIndex: currentIndex.value,
      loopValue: loopValue.value
    })
  }
}
// 选择歌曲循环模式
const loopPlay = (type: number) => {
  loopValue.value = type
  if (currentIndex.value === MusicTabListTypeEnum.MUSICDAILYLIST) {
    // 每日推荐
    if (type === 1) {
      loopValue.value = GlobalMusicPlayModeEnum.SINGLE
    } else if (type === 2) {
      loopValue.value = GlobalMusicPlayModeEnum.RANDOM
    } else if (type === 3) {
      loopValue.value = GlobalMusicPlayModeEnum.ORDER
    }
  } else {
    loopValue.value =
      loopValue.value === GlobalMusicPlayModeEnum.ORDER ? GlobalMusicPlayModeEnum.SINGLE : GlobalMusicPlayModeEnum.ORDER
  }
  /**
   * bug（418）：左右滑动歌单时，若点击其他歌单的播放模式，不能算作切换播放歌单
   * 如果这么做会导致问题：点击了其他列表的播放，再展开播放器，没有展示对应歌单
   */
  setFootPlayer({
    ...globalPlayerStore.footPlayer,
    loopValue: loopValue.value
  })
}
const itemClick = () => {
  setFootPlayer()
  // 列表循环
  if (loopValue.value === GlobalMusicPlayModeEnum.ORDER) {
    // 全部音乐
    // 已登录：
    // {
    //   "music_id": 1103,
    //   "music_name": "922-我不记得",
    //   "singer": "空白君MAX",
    //   "is_favor": 0
    // }
    // 未登录（捧投列表）
    // {
    //     "id": 1319,
    //     "spread_no": "pt20221102162255",
    //     "spread_name": "我不记得拉",
    //     "status": 2,
    //     "progress_status": 1,
    //     "complete_num": 0,
    //     "goal_num": 10,
    //     "price": 1100,
    //     "price_format": 11,
    //     "music_id": 1103,
    //     "music_name": "922-我不记得",
    //     "music_img": "https://oss.pengyin.vip/d525716225a8da1ad2cda834f0eebf3cf7a0a6536950e33225f5f38e98f74546.jpg?x-oss-process=image/auto-orient,1/resize,m_lfit,w_200/quality,q_90",
    //     "singer": "空白君MAX",
    //     "source_url": "https://oss.pengyin.vip/cba3a4ce8ce79fbd54f525cda3fffcec8f520c60aa621f1331771591988a4588.mp3",
    //     "start_date": 1667232000,
    //     "end_date": 1667577599,
    //     "commission_discount": 100,
    //     "commission_price": 11,
    //     "created_at": "2022-11-02T16:22:55+08:00",
    //     "created_at_format": 1667377375,
    //     "my_accept": 0
    // }
    // 我的喜欢
    // 已登录
    // {
    //     "works_id": 1077,
    //     "music_id": 1077,
    //     "music_name": "测试普通藏品",
    //     "singer": "吕博文",
    //     "is_favor": 1
    // }
    const list = state.resList.map((item) => {
      return {
        ...item,
        ID: item.music_id
      }
    })
    globalPlayerStore.setPlayList(list)
    // globalPlayerStore.setPlayMode('order')
  } else if (loopValue.value === GlobalMusicPlayModeEnum.SINGLE) {
    //  单曲播放
    globalPlayerStore.setPlayList([])
    // globalPlayerStore.setPlayMode('')
  }
}
// 滑动
const sliderChange = (e) => {
  let s = e.detail.value
  if (s > globalPlayerStore.lyricInfo.quick_end || s < globalPlayerStore.lyricInfo.quick_start) {
    // 改为完整模式
    // songStatus.value = 2
  }
  sliding.value = false
  globalPlayerStore.seekMusic(s)
}
const sliderChanging = (e) => {
  if (!sliding.value) {
    currentVal.value = +globalPlayerStore.lyricInfo.nowtimes
    sliding.value = true
  } else {
    let s = e.detail.value
    currentVal.value = s
  }
}
const playHandle = () => {
  if (+storeSongID.value > 0) {
    globalPlayerStore.setPlayToggle()
  } else {
    return pyToast('请先选择歌曲播放')
  }
}
const getListInit = () => {
  // if (+currentIndex.value === MusicTabListTypeEnum.ALLMUSICLIST) {
  //   // 全部音乐
  //   if (userStore.getToken) {
  //     apiAction[+currentIndex.value] = CommonApi.getAllMusicList
  //   } else {
  //     apiAction[+currentIndex.value] = CommonApi.getAllMusicListV1
  //   }
  // } else if (+currentIndex.value === MusicTabListTypeEnum.MUSICDAILYLIST) {
  //   // 每日推荐
  //   apiAction[+currentIndex.value] = CommonApi.getMusicDailyLists
  // } else if (+currentIndex.value === MusicTabListTypeEnum.MYCOLLECTMUSICLIST) {
  //   // 我的藏品
  //   apiAction[+currentIndex.value] = CommonApi.getAllCollectLists
  // } else if (+currentIndex.value === MusicTabListTypeEnum.MYLIKESLIST) {
  //   // 我的喜欢
  //   if (userStore.getToken) {
  //     // getUserInfo()
  //     // apiAction[+currentIndex.value] = LikesApi.getLikeMusicList
  //     apiAction[+currentIndex.value] = SongsApi.apiGetLikeList
  //     empytData.value = '暂无数据'
  //   } else {
  //     apiAction[+currentIndex.value] = null
  //     empytData.value = '登录后查看'
  //     return
  //   }
  // }
}
const deleteLike = () => {
  emit('deleteLike')
  getListInit()
}
const joinLike = () => {
  emit('joinLike')
}
const goLogin = () => {
  if (!userStore.getToken) {
    closePopup()
    // router.navigateTo('/pages/login/index')
  }
}

const initSongId = async () => {
  let lastSongId = uni.getStorageSync('lastSongId') || 0
  if (!globalPlayerStore.appLaunch) return
  // try {
  //   const musicRes = (await CommonApi.getMusicDetailNoLogin({
  //     id: lastSongId,
  //     flag: 'isSimple' // 会出现删除歌曲的情况，不提示错误信息
  //   })) as APIResponse
  // } catch (error: any) {
  //   const errorData = error.data
  //   if (errorData.code === 10004 && errorData.error === '暂无此藏品信息') {
  //     lastSongId = 0
  //   }
  // }
  // if (lastSongId) {
  //   globalPlayerStore.setPlayerSrc('', lastSongId)
  // } else {
  //   let res: any = null
  //   if (userStore.getToken) {
  //     res = await CommonApi.getAllMusicList({ offset: 1 })
  //   } else {
  //     res = await CommonApi.getAllMusicListV1({ offset: 1 })
  //   }
  //   globalPlayerStore.setPlayerSrc('', res.data.list[0].music_id)
  // }
  // setFootPlayer()
}
onMounted(async () => {
  initSongId()
})
const tabbarBottom = ref(0)
onShow(() => {
  const isTabbar = routerConfig.tabbarList.includes(`/${get_page_vm().route}`)
  if (isTabbar) {
    tabbarBottom.value = windowBottom.value
  } else {
    tabbarBottom.value = 0
  }
  if (
    +currentIndex.value !== MusicTabListTypeEnum.MUSICDAILYLIST &&
    loopValue.value === GlobalMusicPlayModeEnum.RANDOM
  ) {
    // 非随机播放
    setFootPlayer({
      currentIndex: currentIndex.value,
      loopValue: GlobalMusicPlayModeEnum.ORDER,
      isUserLike: isUserLike.value
    })
  }
  getListInit()
})
</script>
<style lang="scss" scoped>
.foot-player {
  box-sizing: border-box;
  padding: 12rpx 24rpx;
  width: 100vw;
  height: 92rpx;
  // background-color: rgba($color: #ec6c39, $alpha: 0.5);
  background-color: rgba($color: #ec6c39, $alpha: 0.2);
  backdrop-filter: blur(10px);
  position: fixed;
  z-index: 4000;
  left: 0;

  .foot-player-container {
    height: 100%;
  }

  .player-left {
    width: 590rpx;

    .music-img {
      position: relative;
      height: 68rpx;
      width: 68rpx;

      .music-img-default {
        height: 68rpx;
        width: 68rpx;
      }

      .music-cover {
        height: 68rpx;
        width: 68rpx;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        border-radius: 100%;
      }
    }

    .music-info {
      width: 470rpx;
      display: flex;
      align-items: center;

      .music-name {
        font-size: 24rpx;
        font-weight: 600;
        color: #fff;
      }

      .music-singer {
        // flex: none;
        font-size: 24rpx;
        font-weight: 400;
        // color: $color-primary;
        color: #acaebf;
        // display: inline-block;
        // max-width: 250rpx;
      }
    }
  }

  .player-right {
    height: 92rpx;

    // width: 132rpx;
    .player-right-children {
      height: 92rpx;
      display: flex;
      align-items: center;
    }

    .music-list {
      height: 56rpx;
      width: 56rpx;
    }
  }

  :deep(uni-slider) {
    margin: 0;

    .uni-slider-wrapper {
      position: absolute;
      left: 0;
      top: -16rpx;
      width: 100%;

      .uni-slider-thumb {
        height: 12rpx !important;
        width: 12rpx !important;
        margin-top: -5rpx !important;
        border-radius: 100%;
      }

      .uni-slider-handle-wrapper {
        height: 1rpx;
      }

      .uni-slider-track {
        height: 4rpx !important;
      }
    }
  }
}

.loading {
  width: 58rpx;
  height: 58rpx;
  animation: rotate 3s linear infinite;
  animation-play-state: running;
}

.playing {
  animation: rotate 5s linear infinite;
  animation-play-state: running;
}

.paused {
  animation: rotate 5s linear infinite;
  animation-play-state: paused;
}

.attach-img-parent {
}

.attach-img {
  width: 56rpx;
  height: 56rpx;
  z-index: 11;
  border-radius: 100%;
  // background: rgba($color: #000000, $alpha: 0.5);
  background: rgba($color: #ec6c39, $alpha: 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  &-play {
    width: 28rpx;
    height: 28rpx;
  }

  &-stop {
    margin-left: 4rpx;
    width: 16rpx;
    height: 20rpx;
  }
}

.play-list-ref {
  z-index: 4001;
}

.play-list {
  position: fixed;
  bottom: 0;
  left: 0;
  // height: 1040rpx;
  width: 100vw;

  .top {
    height: 104rpx;
    line-height: 104rpx;
    padding: 32rpx 48rpx;
    border-bottom: 2rpx solid #49474b;

    .music-type {
      height: 32rpx;
      width: 40rpx;
    }

    .top-number {
      color: $color-primary;
      margin-left: 8rpx;
    }
  }
}

.close {
  width: 100vw;
  height: 84rpx;
  line-height: 84rpx;
  text-align: center;
  background: #000000;
  font-size: 24rpx;
  font-weight: 400;
  color: $color-primary;
  z-index: 4001;
}

:deep(.uni-popup) {
  z-index: 4001 !important;
}

:deep(.swiper) {
  // 16+ 32 + 1040 + 84
  // 16+ 32 + 1040
  height: 1072rpx;
  box-sizing: border-box;

  .uni-swiper-slides {
    box-sizing: border-box;
    height: 1040rpx;
    top: 32rpx;
    background: #302e33;
    border-radius: 32px 32px 0px 0px;
  }

  .uni-swiper-dots {
    top: 0;
    bottom: auto;
  }
}

// 勿改，支付后再返回会影响当前样式
:deep(.list-scroll-view) {
  height: 936rpx !important;
  background: #fff !important;

  .bottom-text {
    background: #fff !important;
  }
}

:deep(.uni-slider-wrapper) {
  top: -15rpx !important;
}
.empty {
  width: 100%;
  padding-top: 24vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .favor-login-btn {
    width: 320rpx;
    height: 84rpx;
    line-height: 84rpx;
    text-align: center;
    border-radius: 50rpx;
    border: 2rpx solid #f7ca18;
    font-size: 28rpx;
    font-weight: 600;
    color: #f7ca18;
  }

  image {
    width: 146rpx;
    height: 144rpx;
    margin-bottom: 32rpx;
  }

  text {
    font-size: 28rpx;
    font-weight: 600;
    color: $color-primary;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
