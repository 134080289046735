import { inject } from 'vue';
import { checkLogin } from './../utils/global-config'
import { IRequestParams } from './../interface/index'
import { pyToast, getVersion } from './../utils/util'
import requestClass from './requestClass'
import { getPlatform } from '@/utils/util'
import { useCommonStoreWithOut, useUserStoreWithOut } from '@/store/modules'
import { getUniAppEnv } from '@/utils/env'
import Report from '@/utils/stat/report'
import routerConfig from "@/router/routerConfig"

const report = new Report()

const sys = getPlatform()
const p: IRequestParams = {
  ak: sys.appId, // __UNI__7AD0253
  ut: sys.uniPlatform,      // app        app      web
  p: sys.platform,          // android    ios     ios
  v: sys.appVersion,        // 4.0.1
  vc: sys.appVersionCode,   // 4005       4007    4007
  did: sys.deviceId || '',  // huawei
  brand: sys.deviceBrand || '', // huawei
  md: sys.deviceModel, // HMA-AL00
  t: new Date().getTime(),
  pr: sys.pixelRatio,
  ww: sys.windowWidth,
  wh: sys.windowHeight,
  sw: sys.screenWidth,
  sh: sys.screenHeight,
  cpath: '',
  lang: sys.appLanguage || '',
  ua: sys.ua,
}


function resHandle (response, config) {
  const commonStore = useCommonStoreWithOut()
  const userStore = useUserStoreWithOut()
  const routes = getCurrentPages() // 获取当前打开过的页面路由数组
  const curRoute: any = routes[routes.length - 1]
  const responseCode = response.data?.code
  const responseError = response.data?.error
  // 401 token过期
  if (response.statusCode === 401) {
    // try {
    //   report.throw({
    //     request: config,
    //     response
    //   })
    // } catch (error) { }
    // 报错跳转前先存当前带参数url
    commonStore.setBackUrl(curRoute?.$page.fullPath)
    userStore.setClear()
    // 当flag为isIndex，由于首页不登录也可以查看，错误就不处理，否则跳转到登录页面
    if (config.data.flag === 'isIndex') {
      return Promise.reject(response)
    } else {
      // userStore.setClear()
      // uni.reLaunch({
      //   url: routerConfig.loginPage
      // })
      return Promise.reject(response)
    }
  }
  if (responseCode !== 0) {
    // try {
    //   report.throw({
    //     request: config,
    //     response
    //   })
    // } catch (error) { }
    // 报错跳转前先存当前带参数url
    commonStore.setBackUrl(curRoute?.$page.fullPath)
    /**
     * 500: token解析错误
     * 10000: token无效
     * 10002: token过期 (弹框)
     * 10004: 用户不存在（删除用户会出现） // 集市未查询到数据也是
     * 10006: 没绑定手机号(弹框) / 参数校验不合规
     */
    if (responseCode === 10004 && (response.data?.error === "您已经绑定过手机号，无需绑定" || response.data?.error === "该手机号已绑定其他账号")) {
      uni.removeStorageSync('token')
      userStore.setToken('')
    }
    if (responseCode === 10006) {
      pyToast(responseError)
      if (responseError === '绑定手机号后继续') {
        // 绑定手机号需要token
        // uni.navigateTo({
        //   url: routerConfig.loginBindPage
        // })
      }
    }
    if (responseCode === 500 || responseCode === 10000 || responseCode === 10002) {
      if (responseCode === 10002) {
        pyToast(responseError)
      }
      userStore.setClear()
      // uni.reLaunch({
      //   url: routerConfig.loginPage
      // })

      return Promise.reject(response)
    } else if (responseError) {
      // 当flag为isSimple，每日捧投用户超过三次，不展示服务端错误信息，单独在接口请求回调中处理。
      if (config.data.flag !== 'isSimple' && config.data.flag !== 'receive') {
        pyToast(responseError)
      }
      return Promise.reject(response)
    } else {
      pyToast('网络加载失败')
      return Promise.reject(response)
    }
  } else {
    return response.data
  }
}

const Request = new requestClass()
// 请求拦截器
Request.interceptors.request(async (request: any) => {
  // uni.setStorageSync('aibooktoken', `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2NzY1Mzg3NDQsImlzcyI6Im11c2VuZXR3b3JrLm9yZyIsInVpZCI6ODI2MDZ9.9Zkirl8_XxMikT0mOg9SNeK_ZftF8zu1qfNBusLGMIw`)
  let token = uni.getStorageSync('aibooktoken')
  request.header['content-type'] = 'application/json'
  request.header['Authorization'] = token ? `Bearer ${token}` : ''
  // request.commonHeader = p
  return request
})

// 响应拦截器
Request.interceptors.response((response: any) => {
  return resHandle(response.response, response.requestOptions)
})

// 设置默认配置
Request.setConfig((config: any) => {
  // 打包在@/utils/env中修改
  config.baseURL = getUniAppEnv().VITE_APP_BASE_API || 'https://wwzl-h5.bravedu.com'
  // config.baseURL = 'https://test-api.pengyin.vip'
  uni.setStorageSync('baseURL', config.baseURL)
  // config.commonHeader = p

  return config
})

export default Request
