<template>
  <!-- #ifdef H5 -->
  <view class="app-tips" @click="openApp">
    <view class="app-tips-down">
      <image src="@/static/img/common/app_download.png" alt="" />
      <view class="app-tips-title">来捧音，玩转Web3音乐！</view>
    </view>
    <view class="app-tips-btn">打开捧音</view>
  </view>
  <!-- #endif -->
</template>
<script lang="ts" setup>
const emit = defineEmits<{
  (e: 'openAppTop'): void
}>()

const openApp = () => {
  emit('openAppTop')
}
</script>
<style lang="scss" scoped>
.app-tips {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 84rpx;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 28rpx;
  font-weight: 600;
  color: #fff;
  z-index: 10;

  .app-tips-down {
    display: flex;
    align-items: center;

    image {
      width: 104rpx;
      height: 108rpx;
      margin-top: 24rpx;
    }

    .app-tips-title {
      height: 28rpx;
      font-size: 24rpx;
      font-weight: 400;
      color: #ffffff;
      line-height: 28rpx;
      margin-left: 16rpx;
    }
  }

  &-btn {
    margin-right: 12rpx;
    width: 216rpx;
    height: 60rpx;
    background: linear-gradient(90deg, #f7ca18 0%, #e69737 100%);
    border-radius: 32rpx;
    font-size: 28rpx;
    font-weight: 600;
    color: #1e1e1e;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
