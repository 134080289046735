<template>
  <view class="input_chunk">
    <!-- 带外边框的 -->
    <view class="chunkBox" v-if="inputType === 'chunk'">
      <view class="header">
        {{ title }}
      </view>
      <view class="content">
        <view class="inputbox">
          <view class="left" v-if="showLeft">
            <slot name="left"></slot>
          </view>
          <view class="right" v-if="showRight">
            <slot name="right"></slot>
          </view>
          <input
            :value="modelValue"
            :maxlength="maxlength"
            class="uni-input"
            :type="type"
            :placeholder="placeholder"
            :disabled="disabled"
            @input="handleInput"
            @blur="handleBlur"
          />
          <view v-if="hasGetCode" class="getcode">
            <text v-if="!showCountDown" @click="getCode">获取验证码</text>
            <view v-else class="count-down-box">
              <CountDown :time="time" :autoStart="autoStart" @finish="finish">
                <template v-slot="timeData">
                  {{ timeData.second }}
                </template>
              </CountDown>
              <text>s</text>
            </view>
          </view>
        </view>
      </view>
      <slot name="bottom"></slot>
    </view>
    <!-- 只有输入框的 -->
    <view class="content" :class="{ isInput: inputType === 'input' }" v-if="inputType === 'input'">
      <input
        :value="modelValue"
        :maxlength="maxlength"
        class="uni-input"
        :type="type"
        :placeholder="placeholder"
        :disabled="disabled"
        @input="handleInput"
        @blur="handleBlur"
      />
      <view v-if="hasGetCode" class="getcode">
        <text v-if="!showCountDown" @click="getCode">获取验证码</text>
        <view v-else class="count-down-box">
          <CountDown :time="time" :autoStart="autoStart" @finish="finish">
            <template v-slot="timeData">
              {{ timeData.second }}
            </template>
          </CountDown>
          <text>s</text>
        </view>
      </view>
    </view>
  </view>
</template>
<script setup lang="ts">
import { ref, watch, nextTick } from 'vue'
import { pyToast } from '@/utils/util'

const emit = defineEmits<{
  (e: 'update:modelValue', val: string): void
  (e: 'input', val: string): void
  (e: 'blur'): void
  (e: 'getcode'): void
}>()
const props = defineProps({
  showRight: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  maxlength: {
    type: Number
  },
  // 是否保留两位
  toFixedTwo: {
    type: Boolean,
    default: false
  },
  // 是否有获取验证码
  hasGetCode: {
    type: Boolean,
    default: false
  },
  // 倒计时时间
  time: {
    type: Number,
    default: 59000
  },
  // 是否自动开始
  autoStart: {
    type: Boolean,
    default: true
  },
  // 是输入框模块，还是普通输入框 chunk input
  inputType: {
    type: String,
    default: 'chunk'
  },
  // 绑定值
  modelValue: {
    type: [Number, String],
    required: true
  },
  // 输入块的标题
  title: {
    type: String,
    default: '标题'
  },
  // 输入块的placeholder
  placeholder: {
    type: String,
    required: true
  },
  // 输入块的type
  type: {
    type: String
  },
  // 左边是否使用插槽
  showLeft: {
    type: Boolean,
    default: false
  },
  passwordNumber: {
    type: Boolean,
    default: false
  }
})
// 监听值变化，如果 保留两位或者需要处理数字密码 就会执行
watch(
  () => props.modelValue,
  (val) => {
    if (val) {
      let right_val = val.toString()
      // 如果是保留两位小数修正
      if (props.toFixedTwo) {
        if (right_val.split('.')[1] && right_val.split('.')[1].length > 2) {
          pyToast('最多设置保留两位小数')
          const pay_amtArr = right_val.split('.')
          right_val = `${pay_amtArr[0]}.${pay_amtArr[1].substr(0, 2)}`
        }
        nextTick(() => {
          emit('update:modelValue', right_val)
        })
      }
      // 如果是数字密码，修正为数字
      if (props.passwordNumber) {
        let right_val = val.toString()
        if (/[^\d]/g.test(right_val)) {
          pyToast('请输入数字')
          right_val = right_val.replace(/[^\d]/g, '')
        }
        nextTick(() => {
          emit('update:modelValue', right_val)
        })
      }
      if (props.type === 'number' || props.type === 'digit') {
        let right_val = val.toString()
        if (right_val[0] === '0' && right_val[1] !== '.') {
          right_val = '0'
          nextTick(() => {
            emit('update:modelValue', right_val)
          })
        }
      }
    }
  }
)
// 输入时回调
const handleInput = (e) => {
  emit('input', e.detail.value)
  emit('update:modelValue', e.detail.value)
}
const showCountDown = ref(false)
// 获取验证码
const getCode = () => {
  showCountDown.value = true
  emit('getcode')
}
// 倒计时结束回调
const finish = () => {
  showCountDown.value = false
}
// 失焦抛出
const handleBlur = () => {
  emit('blur')
}
</script>
<style lang="scss" scoped>
.input_chunk {
  width: 100%;
  height: 100%;
  background: #302e33;
  border-radius: 20rpx;
  .chunkBox {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .header {
    height: 88rpx;
    border-bottom: 2rpx solid #49474b;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 0 32rpx;
    font-size: 28rpx;
    font-weight: 100;
    color: #ffffff;
  }
  .content {
    width: 100%;
    flex: 1;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 24rpx 32rpx;
    .right {
    }
    .inputbox {
      display: flex;
      background: #000;
      border-radius: 12rpx;
      width: 100%;
      position: relative;
      .left {
        display: flex;
        align-items: center;
        font-size: 28rpx;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        padding-left: 24rpx;
      }
      .right {
        height: 100%;
        position: absolute;
        font-size: 28rpx;
        font-weight: 600;
        color: #ffffff;
        right: 36rpx;
        top: 0;
        display: flex;
        align-items: center;
      }
    }
    :deep(.uni-input) {
      flex: 1;
    }
    :deep(.uni-input-placeholder) {
      padding-left: 0rpx!important;
      font-size: 28rpx;
      font-weight: 400;
      color: $color-primary;
      line-height: 32rpx;
    }
    :deep(.uni-input-input) {
      padding-left: 0rpx!important;
      font-size: 28rpx;
      font-weight: 400;
      color: #ffffff;
      line-height: 32rpx;
    }
    input {
      background: #000;
      border-radius: 12rpx;
      height: 84rpx;
    }
  }
  .isInput {
    background: #000;
    border-radius: 12rpx;
    padding: 0;
  }
  .getcode {
    border-left: 1rpx solid $color-primary;
    color: #f7ca18;
    padding: 0 40rpx;
    font-size: 28rpx;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    display: flex;
    align-items: center;
    margin: 28rpx 0;
    .count-down-box {
      display: flex;
      justify-content: center;
      min-width: 60rpx;
      font-size: 28rpx;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
</style>
