import { inject, ref } from 'vue'
import { pyToast } from '@/utils/util'
import { SongsApi } from './../../api/modules/songs/index'
import { useUserStoreWithOut, useCommonStoreWithOut } from '@/store/modules'
import { APIResponse } from './../../interface/index'
import { defineStore } from 'pinia'
import { store } from '@/store'
import { CommonApi } from '@/api/common'
import { get_page_vm } from '@/utils/stat/pageInfo'
import { MusicTabListTypeEnum, GlobalMusicPlayModeEnum } from '@/enum'

export const useGlobalPlayerStore = defineStore('app-player', {
  state: () => ({
    player: null as any,
    songId: -1,
    offsetX: 999,
    offsetY: 200,
    isPlay: false,
    loading: false,
    imgurl: '../../static/img/common/player/cover-default.png',
    musicTaskTime: 0, //唱机10分钟任务
    taskPalyTime: import.meta.env.VITE_APP_PLAY_TIME, //任务时间
    lyricInfo: {
      timeArr: [] as number[],
      wordArr: [] as string[],
      maxTime: 0,
      nowtimes: 0,
      nowlirics: 0, //当前歌词索引
      offsetTop: 0,
      quick_start: 0, //高潮开始
      quick_end: 0 //高潮结束
    },
    taskSetInterval: null as any,
    playList: [] as any,
    playMode: '', // 播放模式  ''为单曲循环; order为顺序播放
    pengtouSongId: -1,
    marketSongId: -1,
    musicianNewsMusicId: -1, //音乐人主页->动态->播放歌曲id
    footPlayer: {
      currentIndex: MusicTabListTypeEnum.ALLMUSICLIST,
      loopValue: GlobalMusicPlayModeEnum.ORDER,
      // 如果是我喜欢的音乐页面，则设置1, 用来区分全局播放器全部音乐和我的喜欢不同时播放同一首歌
      isUserLike: 0 // 是否是从我的 -> 我喜欢的音乐点击
    },
    musicInfo: {
      // cover: '../../static/img/common/player/cover-default.png'
      cover: ''
    } as any,
    favorMusic: {} as any,
    appLaunch: false,
    playpagecount: 1,
    playerClose: false,
    playerListVisible: false,
    isEnd: false,
    songRandom: 0
  }),
  getters: {
    getTaskPalyTime (state) {
      return state.taskPalyTime
    },
    getListenTime (state) {
      return state.musicTaskTime || +uni.getStorageSync('listen_time')
    },
    getLoopValue (state) {
      return +uni.getStorageSync('footPlayer').loopValue
    },
    getMusicInfo () {
      return uni.getStorageSync('lastMusicInfo')
    },
    getSongRandom () {
      return uni.getStorageSync('songRandom')
    },
    /**
     * 列表循环  获取下一首播放音乐
     * 如果未传，则从接口获取
     * @param state
     * @returns
     */
    async getNextSource (state) {
      const list = state.playList
      let curItem: any = null
      // 获取当前播放item
      let prevItem = list.filter((i) => {
        return i.ID === this.songId
      })[0]
      // 获取下一项item的下标
      let prevIndex = list.indexOf(prevItem)
      // 判断最后一项，回到第一首歌
      if (list.length === prevIndex + 1) {
        // 将要播放的item
        curItem = list[0]
      } else {
        // 将要播放的item
        curItem = list[prevIndex + 1]
      }
      return curItem
    }
  },
  actions: {
    initPlayer () {
      let listen_time = +uni.getStorageSync('listen_time') || 0
      let listen_date = +uni.getStorageSync('listen_date') || 0
      if (new Date().getTime() > listen_date) {
        uni.removeStorageSync('listen_date')
        uni.removeStorageSync('listen_time')
      } else {
        this.musicTaskTime = listen_time
      }
      let audioContext
      // #ifdef H5 || MP-WEIXIN
      audioContext = uni.createInnerAudioContext()
      audioContext.autoplay = false
      // audioContext.loop = true
      // #endif
      // #ifdef APP-PLUS
      audioContext = uni.getBackgroundAudioManager()
      // #endif
      audioContext.onPlay(() => {
        console.log('开始播放')
        this.isPlay = true
        this.clearTimeout()
        this.openTimeOut()
        setTimeout(() => {
          this.setIsEnd(false)
        }, 0)
      })
      audioContext.onCanplay(() => {
        console.log('canplay')
        this.loading = false
      })
      audioContext.onPause(() => {
        console.log('暂停')
        this.isPlay = false
        this.clearTimeout()
      })
      audioContext.onWaiting(() => {
        console.log('加载中')
        this.loading = true
      })
      audioContext.onError((res) => {
        console.log(res)
        pyToast('播放失败')
      })
      // 时间更新时
      audioContext.onTimeUpdate(() => {
        let nowtimes = Number(audioContext.currentTime)
        this.lyricInfo.nowtimes = nowtimes
        if (nowtimes > this.lyricInfo.timeArr[this.lyricInfo.timeArr.length - 1]) {
          this.lyricInfo.nowlirics = this.lyricInfo.timeArr.length - 1
          return
        }
        for (let i = 1; i < this.lyricInfo.timeArr.length; i++) {
          if (nowtimes < this.lyricInfo.timeArr[i]) {
            let current = i - 1
            this.lyricInfo.nowlirics = current
            break
          }
        }
      })
      // 音频/背景音频自然播放结束事件
      audioContext.onEnded(async () => {
        const localLoopValue = uni.getStorageSync('footPlayer').loopValue
        const commonStore = useCommonStoreWithOut()
        const currentTab = +uni.getStorageSync('footPlayer').currentIndex
        let musicList = commonStore.getMusicDailyLists
        let nextId = 0
        // 每日推荐的歌曲 并且是 随机播放: +currentTab === 1 && +localLoopValue === 3
        if (this.playMode === 'order' || +localLoopValue === GlobalMusicPlayModeEnum.ORDER || +localLoopValue === GlobalMusicPlayModeEnum.RANDOM) {
          // 列表循环
          try {
            // next_id previous_id
            // next_music_id  previous_music_id
            // 获取下一首歌曲id
            let res = null as any
            if (currentTab === MusicTabListTypeEnum.ALLMUSICLIST) {
              res = await SongsApi.getAllBehindMusic({
                music_id: +this.songId
              })
              nextId = +res.data.next_id
            } else if (currentTab === MusicTabListTypeEnum.MUSICDAILYLIST || currentTab === MusicTabListTypeEnum.MYCOLLECTMUSICLIST) {
              // let dailyIndex = 0
              if (currentTab === MusicTabListTypeEnum.MUSICDAILYLIST) {
                if (+localLoopValue === GlobalMusicPlayModeEnum.RANDOM) {
                  musicList = commonStore.getRondomMusicDailyLists // 随机播放列表
                }
                // dailyIndex = musicList.findIndex((dailyItem) => dailyItem.music_id === this.songId)
              }
              if (currentTab === MusicTabListTypeEnum.MYCOLLECTMUSICLIST) { //我的收藏
                musicList = uni.getStorageSync('allMyCollectLists')
              }
              let dailyIndex = musicList.findIndex((dailyItem) => dailyItem.music_id === this.songId)
              if (dailyIndex === musicList.length - 1 || dailyIndex < 0) {
                // 最后一个 或者 找不到
                nextId = musicList[0].music_id
              } else {
                nextId = musicList[dailyIndex + 1].music_id
              }
            } else if (currentTab === MusicTabListTypeEnum.MYLIKESLIST) {
              res = await SongsApi.getFavorBehindMusic({
                id: +this.favorMusic.id
              })
              nextId = +res.data.next_music_id
            }
            // 在集市页底部播放器播放下一首，集合列表歌曲也需切歌
            this.marketSongId = -1
            this.setIsEnd(true)
            this.setPlayerSrc('', +nextId)
            // 播放结束切换歌曲记录当前播放模式
            this.setFootPlayer({
              ...this.footPlayer,
              currentIndex: currentTab
            })
            if (currentTab === MusicTabListTypeEnum.MYLIKESLIST) {
              this.setFavorMusic({
                id: +res.data.next_id
              })
            }
          } catch (error) { }
        }
        this.setPlay()
      })
      this.player = audioContext
    },
    setIsEnd (isEnd) {
      this.isEnd = isEnd
    },
    // 设置播放类型
    setCurrentIndex () {
      // 勿删，在每日推荐播放打开全局底部播放器需展示每日推荐tab，在我的喜欢点击播放需要打开我的喜欢tab
      const currentTab = +uni.getStorageSync('footPlayer').currentIndex || MusicTabListTypeEnum.ALLMUSICLIST
      const localLoopValue = uni.getStorageSync('footPlayer').loopValue || GlobalMusicPlayModeEnum.ORDER
      const curPage = get_page_vm()
      console.log('curPage', curPage)
      if (curPage?.route === 'pages/songs/play') return
      if (curPage?.route === 'pages/user/likes/index') {
        this.setFootPlayer({
          ...this.footPlayer,
          currentIndex: MusicTabListTypeEnum.MYLIKESLIST
        })
      } else if (curPage?.route === 'pages/songs/songRecommend' || +localLoopValue.value === GlobalMusicPlayModeEnum.RANDOM) {
        this.setFootPlayer({
          ...this.footPlayer,
          currentIndex: MusicTabListTypeEnum.MUSICDAILYLIST
        })
      } else if (curPage?.route === 'pages/collect/detail' || curPage?.route === 'pages/collect/collection-details') { // 在藏品详情点击播放则设置为我的藏品歌单
        this.setFootPlayer({
          ...this.footPlayer,
          currentIndex: MusicTabListTypeEnum.MYCOLLECTMUSICLIST
        })
      } else {
        if (currentTab === MusicTabListTypeEnum.MUSICDAILYLIST && +localLoopValue === GlobalMusicPlayModeEnum.RANDOM) return // 随机播放不设置
        this.setFootPlayer({
          ...this.footPlayer,
          currentIndex: MusicTabListTypeEnum.ALLMUSICLIST
        })
      }
    },
    // 播放
    setPlay () {
      this.isPlay = true
      if (this.imgurl) {
        this.player.play()
      } else {
        this.loading = true
      }
    },
    // 暂停
    setPause () {
      this.isPlay = false
      this.player.pause()
    },
    // 停止播放
    setStop () {
      this.player.stop()
    },
    // 播放/暂停
    setPlayToggle () {
      this.isPlay = !this.isPlay
      this.setCurrentIndex()
      // 如果是我喜欢的音乐页面，则设置1, 用来区分全局播放器全部音乐和我的喜欢不同时播放同一首歌
      if (this.isPlay) {
        this.setPlay()
      } else {
        this.setPause()
      }
    },
    setOffsetTop (offsetTop) {
      this.lyricInfo.offsetTop = offsetTop
    },
    // setSongRandom (random) {
    //   this.songRandom = random
    //   uni.setStorageSync('songRandom', random)
    // },
    setAppLaunch (data) {
      this.appLaunch = data
      uni.setStorageSync('appLaunch', data)
    },
    // 设置我喜欢的列表id，因播放下一首需要
    setFavorMusic (data: any) {
      this.setFootPlayer({
        ...this.footPlayer,
        isUserLike: 1,
        currentIndex: MusicTabListTypeEnum.MYLIKESLIST
      })
      this.favorMusic = data
    },
    // 底部全局播放器（全部音乐、我的喜欢）是否展示
    setPlayerListVisible (visible) {
      this.playerListVisible = visible
    },
    async addMusicContribution () {
      if (!uni.getStorageSync('aibooktoken')) return
      try {
        let res = await SongsApi.addMusicContribution({ music_id: this.songId, task_type: 1 })
      } catch (error) {
      }
    },
    // 换歌 //app才有title coverimg
    async setPlayerSrc (src?: any, songId?: any, imgurl?: any, title?: '') {
      const globalApp: any = getApp()
      // const $pyStat: any = globalApp.globalData.$pyStat
      this.setCurrentIndex()
      this.songId = songId
      this.seekMusic(0)
      try {
        const userStore = useUserStoreWithOut()
        const commonStore = useCommonStoreWithOut()
        let res = null as any
        try {
          if (userStore.getToken) {
            res = (await CommonApi.getMusicDetail({
              id: this.songId,
              flag: 'isSimple' // 会出现删除歌曲的情况，不提示错误信息
            })) as APIResponse
          } else {
            let currentObj = (await CommonApi.getMusicDetailNoLogin({
              id: this.songId,
              flag: 'isSimple' // 会出现删除歌曲的情况，不提示错误信息
            })) as APIResponse
            res = { data: currentObj.data.music_info }
          }
        } catch (error) {
          console.log('error--------', error)
        }

        this.musicInfo = res.data
        this.imgurl = this.musicInfo.cover
        // #ifdef APP-PLUS
        this.player.coverImgUrl = this.imgurl
        this.player.title = this.musicInfo.title
        // #endif
        this.player.src = this.musicInfo.source_url
        this.lyricInfo.maxTime = this.musicInfo.duration
        uni.setStorageSync('lastSongId', this.songId)
        try {
          // $pyStat.sendEvent(`音乐播放-查询-${this.musicInfo.title}`, {
          //   music_name: this.musicInfo.title,
          //   music_id: this.songId,
          //   user_id: userStore.getUserInfo?.id || 0
          // })
          this.addMusicContribution()
        } catch (error) { }
        if (this.appLaunch) {
          this.setPause()
          this.setAppLaunch(false)
        } else {
          if (this.isPlay) {
            this.setPlay()
          }
        }
      } catch (error) { }
      // if (this.isPlay) {
      //   this.setPlay()
      // }
    },
    async setPlayerSrcEasy (res, songId) {
      const globalApp: any = getApp()
      // const $pyStat: any = globalApp.globalData.$pyStat
      this.songId = songId
      this.seekMusic(0)
      try {
        const userStore = useUserStoreWithOut()
        this.musicInfo = res
        this.imgurl = this.musicInfo.cover
        // #ifdef APP-PLUS
        this.player.coverImgUrl = this.imgurl
        this.player.title = this.musicInfo.title
        // #endif
        this.player.src = this.musicInfo.source_url
        this.lyricInfo.maxTime = this.musicInfo.duration
        uni.setStorageSync('lastSongId', this.songId)

        try {
          // $pyStat.sendEvent(`音乐播放-查询-${this.musicInfo.title}`, {
          //   music_name: this.musicInfo.title,
          //   music_id: this.songId,
          //   user_id: userStore.getUserInfo?.id || 0
          // })
          this.addMusicContribution()
        } catch (error) { }
        // #ifdef APP-PLUS
        this.setPlay()
        // #endif
        // #ifdef H5
        this.setPause()
        // #endif
      } catch (error) { }
      // if (this.isPlay) {
      //   this.setPlay()
      // }
    },

    // 跳转到歌的某个位置
    seekMusic (s) {
      this.lyricInfo.nowtimes = s
      for (let i = 1; i < this.lyricInfo.timeArr.length; i++) {
        if (this.lyricInfo.nowtimes < this.lyricInfo.timeArr[i]) {
          let current = i - 1
          this.lyricInfo.nowlirics = current
          break
        }
      }
      this.player.seek(s)
    },
    // 设置位置
    setPositon (x, y) {
      this.offsetX = x
      this.offsetY = y
    },
    // 设置播放列表
    setPlayList (list) {
      this.playList = list
    },
    /**
     * 顺序播放:order ｜ 单曲循环:''
     * 涉及页面：发现页、我的喜欢
     */
    setPlayMode (mode: string) {
      this.playMode = mode
    },
    resetLyricInfo () {
      this.lyricInfo = {
        timeArr: [] as number[],
        wordArr: [] as string[],
        maxTime: 0,
        nowtimes: 0,
        nowlirics: 0, //当前歌词索引
        offsetTop: 0,
        quick_start: 0, //高潮开始
        quick_end: 0 //高潮结束
      }
    },
    // 开启计时器
    async openTimeOut () {
      this.taskSetInterval = setInterval(async () => {
        this.musicTaskTime++
        // 明天0点时间戳
        let d = new Date().setHours(0, 0, 0, 0) + 86400000
        uni.setStorageSync('listen_date', d)
        uni.setStorageSync('listen_time', this.musicTaskTime + '')
        if (this.musicTaskTime >= Number(this.taskPalyTime)) {
          this.clearTimeout()
          await this.counterComplete()
        }
      }, 1000)
    },
    // 清除定时器
    clearTimeout () {
      clearInterval(this.taskSetInterval)
      this.taskSetInterval = null
    },
    // 600s 任务
    async counterComplete () {
      // await CommonApi.apiCounterComplete()
    },
    setPengtouSongId (id) {
      this.pengtouSongId = id
      // 其他id还原
      this.marketSongId = -1
      this.musicianNewsMusicId = -1
    },
    // musicianNewsMusicId
    setMarketSongId (id) {
      this.marketSongId = id
      // 其他id还原
      this.pengtouSongId = -1
      this.musicianNewsMusicId = -1
    },
    setMusicianNewsMusicId (id) {
      this.musicianNewsMusicId = id
      // 其他id还原
      this.pengtouSongId = -1
      this.marketSongId = -1
    },
    /**
     * 底部播放器相关
     */
    setFootPlayer (info: any) {
      this.footPlayer.currentIndex = info.currentIndex
      this.footPlayer.loopValue = info.loopValue
      this.footPlayer.isUserLike = info.isUserLike
      uni.setStorageSync('footPlayer', this.footPlayer)
    }
  }
})

// Need to be used outside the setup
export function useGlobalPlayerStoreWithOut () {
  return useGlobalPlayerStore(store)
}
