import Http from '@/api/request'

/**
 * 公共接口
 */
export const CommonApi = {
  // 获取token
  getToken (data) {
    return Http.get(`/v1/get_jwt?user_id=${data.user_id}`)
  },
  // 上报日志 \ 收集数据
  sendStat (data) {
    return Http.post(`/v1/tracking/collection`, {
      ...data,
      flag: 'isSimple'
    })
  },
  ping (data) {
    return Http.get(`/v1/ping`, data)
  },
  generateCaptcha (data) {
    return Http.get(`/v1/generate_captcha`, data)
  },
  send_phone_msg_code (data) {
    return Http.post(`/v1/send_phone_msg_code`, data)
  },
  books_chapter_list (data) {
    return Http.get(`/v2/books_chapter_list`, data)
  },
  getCode (data) {
    return Http.post(`/v1/smscode`, data)
  },
  // 提交H5登录数据
  sendLoginData (data) {
    // return Http.post(`/v1/login/${data.code}`, data)
    return Http.post(`/v1/login_wechat_h5`, data)
  },
  // 获取用户信息
  getUserInfo () {
    return Http.get('/v2/user_info')
  },

  // 提交安卓微信登录数据
  sendAndroidLoginData (data) {
    return Http.post(`/v1/applogin/${data.code}`, data)
  },
  loginByUionid (data: { openId: string; unionid: string; accessToken: string }) {
    return Http.post(`/v1/apploginbyunionid`, data)
  },
  phoneLogin (data) {
    return Http.post(`/v1/phone_login`, data)
  },
  // 绑定手机号
  apiBindMobile (data) {
    return Http.post(`/v2/bindphone`, data)
  },

}
