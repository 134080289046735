<template>
  <swiper class="swiper-box-list" :current="current" :duration="300" @change="change">
    <swiper-item class="swiper-list" v-for="(item, index) in imgList" :key="index">
      <movable-area v-if="preview" scale-area class="movable-area">
        <!-- @dblclick="dblclick" -->
        <movable-view class="movable-view" direction="all" scale="true" scale-min="1" scale-max="4" :scale-value="scale"
          @click="makeDoubleClick">
          <view class="imgbox">
            <slot></slot>
            <image :src="item" mode="widthFix" style="width:100%;"></image>
          </view>
        </movable-view>
      </movable-area>
    </swiper-item>
  </swiper>
</template>
<script setup lang="ts">
import { computed, nextTick, onMounted, reactive, watch, ref } from 'vue'
import { useRouter } from '@/hooks/routerLink'

const router = useRouter()
const props = defineProps({
  imgList: {
    type: Object,
    default: () => { }
  },
  current: {
    type: Number,
    default: 0
  },
})
const emit = defineEmits<{
  (e: 'onceClick', data?: any): void
  (e: 'doubleClick', data?: any): void
}>()

const scale = ref(1)
const preview = ref(true)

const clicks = ref(0)
let timeout: any = null
const makeDoubleClick = (e: any) => {
  clicks.value++
  if (clicks.value == 1) {
    timeout = setTimeout(function () {
      emit('onceClick', e)
      clicks.value = 0
    }, 250);
  } else {
    clearTimeout(timeout)
    if (scale.value == 4) {
      scale.value = 1;
    } else {
      scale.value = 4;
    }
    emit('doubleClick', e)
    clicks.value = 0
  }
}
const change = (e) => {
  console.log("swiper----", e)
  scale.value = 1
}


onMounted(() => { })
</script>
<style lang="scss" scoped>
.swiper-box-list {
  width: 100%;
  height: 100vh;

  .swiper-list {
    // width: 100%;
  }
}

.movable-area {
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
}

.movable-view {
  width: 100vw;
  height: 100vh;
  justify-content: center;
  /*子元素水平居中*/
  align-items: center;
  /*子元素垂直居中*/
  display: -webkit-flex;
  .imgbox{
    width: 100vw;
    position: relative;
  }
}

.swiperImg {
  height: 80%;
}
</style>
