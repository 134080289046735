<template>
  <view class="body-view">
    <!-- 使用scroll-view实现tabs滑动切换 -->
    <view class="top-wrap" v-if="props.needBg">
      <scroll-view
        class="top-menu-view"
        :style="{ height: props.tabHeight }"
        scroll-x="true"
        :scroll-into-view="tabCurrent"
        :show-scrollbar="false"
      >
        <view
          class="menu-topic-view"
          v-for="item in tabs"
          :id="'tabNum' + item.id"
          :key="item.id"
          @click="switchTab({ detail: { current: item.id } })"
        >
          <view :style="{ height: props.tabHeight }" :class="currentTab == item.id ? 'menu-topic-act' : 'menu-topic'">
            <text class="menu-topic-text">{{ item.name }}</text>
            <view class="menu-topic-bottom" v-if="props.showBottom">
              <view class="menu-topic-bottom-color"></view>
            </view>
          </view>
        </view>
      </scroll-view>
    </view>
    <scroll-view
      v-else
      class="top-menu-view"
      :style="{ height: props.tabHeight }"
      scroll-x="true"
      :scroll-into-view="tabCurrent"
      :show-scrollbar="false"
    >
      <view
        class="menu-topic-view"
        v-for="item in tabs"
        :id="'tabNum' + item.id"
        :key="item.id"
        @click="switchTab({ detail: { current: item.id } })"
      >
        <view :style="{ height: props.tabHeight }" :class="currentTab == item.id ? 'menu-topic-act' : 'menu-topic'">
          <text class="menu-topic-text">{{ item.name }}</text>
          <view class="menu-topic-bottom" v-if="props.showBottom">
            <view class="menu-topic-bottom-color"></view>
          </view>
        </view>
      </view>
    </scroll-view>
    <!-- 内容 -->
    <swiper class="swiper-box-list" :current="currentTab" :duration="300" @change="switchTab">
      <swiper-item class="swiper-topic-list" v-for="item in tabs" :key="item.id">
        <view v-if="currentTab === item.id" class="swiper-item">
          <slot></slot>
        </view>
      </swiper-item>
    </swiper>
  </view>
</template>

<script setup lang="ts">
import { ref, nextTick, watch } from 'vue'
import * as _ from 'lodash-es'

export interface Props {
  tabs?: Array<any>
  showBottom?: boolean
  tabHeight: string
  needBg?: boolean
  currentTab?: number
}
const props = withDefaults(defineProps<Props>(), {
  tabs: [] as any,
  showBottom: true,
  tabHeight: '86rpx',
  needBg: false,
  currentTab: 0
})
const emit = defineEmits<{
  (e: 'switch', item: any): void
}>()
let currentTab = ref(0)
watch(
  () => props.currentTab,
  (val) => {
    nextTick(() => {
      currentTab.value = val
      console.log('currentTab.value',currentTab.value)
    })
  },
  { immediate: true }
)
let tabCurrent = ref('tabNum1')
const swichMenu = (id) => {
  currentTab.value = id
  tabCurrent.value = 'tabNum' + id
}
const switchTab = _.debounce((e) => {
  let index = e.detail.current
  swichMenu(index)
  let item = props.tabs.filter((item) => item.id === index)
  emit('switch', item[0])
}, 200)

defineExpose({
  currentTab,
  switchTab
})
</script>

<style scoped lang="scss">
.body-view {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.top-wrap {
  width: 100vw;
  background: #1e1e1e;
  padding: 32rpx 0;
}
.top-menu-view {
  display: flex;
  white-space: nowrap;
  width: 100%;
  align-items: center;
  height: 86rpx;
  line-height: 86rpx;
  :deep(.uni-scroll-view-content) {
    display: flex;
  }

  .menu-topic-view {
    flex: 1;
    display: inline-block;
    white-space: nowrap;
    height: 100%;
    position: relative;

    .menu-topic-text {
      font-size: 24rpx;
      // font-weight: 600;
      color: #ffffff;
      line-height: 28rpx;
      padding: 10rpx 0rpx;
    }

    .menu-topic-bottom {
      position: absolute;
      bottom: 0;
      width: 100%;

      .menu-topic-bottom-color {
        width: 40rpx;
        height: 4rpx;
      }
    }

    .menu-topic-act .menu-topic-bottom {
      display: flex;
      justify-content: center;
    }

    .menu-topic-act .menu-topic-bottom-color {
      background: #ffffff;
      width: 84rpx;
      height: 8rpx;
      border-radius: 16rpx;
    }
    .menu-topic-act {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
    .menu-topic {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }
}

.swiper-box-list {
  width: 100%;
  height: 100%;
  .swiper-topic-list {
    width: 100%;
  }
}
</style>
