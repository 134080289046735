import { useCommonStoreWithOut } from '@/store/modules'
import { type Numeric } from './basic'
import packageConfig from '../../package.json'
import { reactive } from 'vue'
//#ifdef H5
import useClipboard from 'vue-clipboard3'
import { getUniAppEnv } from './env'
//#endif

const sys: any = uni.getSystemInfoSync()

// 前大于后返回 1  后大于前 返回-1 相等返回0
export function compareVersion (version1, version2) {
  const v1 = version1.split('.')
  const v2 = version2.split('.')
  for (let i = 0; i < v1.length || i < v2.length; i++) {
    let x = 0,
      y = 0
    if (i < v1.length) {
      x = parseInt(v1[i])
    }
    if (i < v2.length) {
      y = parseInt(v2[i])
    }
    if (x > y) return 1
    if (x < y) return -1
  }
  return 0
}

export const getPlatform = () => {
  return sys
}
export const pyToast = (str: string, duration = 2000) => {
  return new Promise<void>((resolve, reject) => {
    // #ifndef APP-PLUS
    uni.showToast({
      title: str,
      icon: 'none',
      duration,
      success: () => {
        setTimeout(() => {
          resolve()
        }, duration)
      }
    })
    // #endif
    // #ifdef APP-PLUS
    plus.nativeUI.toast(`<font color="#ffffff">${str}</font>`, {
      verticalAlign: 'center',
      background: 'rgba(17, 17, 17, 0.7)',
      type: 'richtext',
      duration: 'short',
      richTextStyle: {
        align: 'center'
      }
    })
    // #endif
  })
}
export const showLoading = (title: string, mask = false) => {
  return new Promise<void>((resolve, reject) => {
    // #ifndef APP-PLUS
    uni.showLoading({
      title, //提示的文字内容，显示在loading的下方
      mask, //Boolean 	是否显示透明蒙层，防止触摸穿透，默认：false
      success: function (res) {
        resolve()
      } //接口调用成功的回调函数
    })
    // #endif
    // #ifdef APP-PLUS
    plus.nativeUI.showWaiting(title)
    // #endif
  })
}
export function padZero (num: Numeric, targetLength = 2): string {
  let str = num + ''

  while (str.length < targetLength) {
    str = '0' + str
  }

  return str
}
/**
 * 解析url中指定的参数名称与值
 */
export function getUrlQuery (url: string, query: string) {
  // str为？之后的参数部分字符串
  const str = url.substr(url.indexOf('?') + 1)
  // arr每个元素都是完整的参数键值
  const arr = str.split('&')
  // result为存储参数键值的集合
  const result = {}
  for (let i = 0; i < arr.length; i++) {
    // item的两个元素分别为参数名和参数值
    const item = arr[i].split('=')
    result[item[0]] = item[1]
  }
  return result
}


// 获取地址栏参数
export function getQueryString (str?: any) {
  //#ifdef APP-PLUS
  const routes = getCurrentPages() // 获取当前打开过的页面路由数组
  const curRoute = (routes[routes.length - 1] as any)?.$page.options
  return str ? curRoute[str] : ''
  //#endif
  //#ifdef H5 || MP-WEIXIN
  const reg = new RegExp('(^|&)' + str + '=([^&]*)(&|$)', 'i')
  const r: any = window.location.search.substr(1).match(reg)
  if (r !== null) {
    return unescape(r[2])
  }
  return null
  //#endif
}

/**
 * 保留两位小树截取
 */
export function round (num: number | string) {
  let numStr = num.toString()
  let index = numStr.indexOf('.')
  if (index === -1) {
    return num
  } else {
    return numStr.slice(0, index + 3)
  }
}

export const copy = async (str: string, toast = '复制成功') => {
  //#ifdef APP-PLUS
  uni.setClipboardData({
    data: str,
    showToast: false,
    success: () => {
      if (toast) {
        pyToast(toast)
      }
    },
    fail: () => {
      pyToast('复制失败，当前环境不兼容此复制方法')
    }
  })
  //#endif

  //#ifndef APP-PLUS
  const textValue = document.createElement('textarea') // 创建DOM元素
  document.body.appendChild(textValue) // 将创建的DOM插入到Body上
  textValue.value = str // 将数据赋值给创建的DOM元素的Value上
  textValue.select() // 通过表单元素的select()方法选中内容
  document.execCommand('copy') // 执行浏览器复制命令
  if (toast) {
    pyToast(toast)
  }
  document.body.removeChild(textValue) // 移除DOM元素
  //#endif
}
/**
 * 获取当前应用版本号
 */
export function getVersion () {
  return new Promise((resolve, reject) => {
    let version = ''
    //#ifdef H5
    version = packageConfig.version
    // console.log('H5--version', version)
    // resolve(Number(version.split('.').join('')))
    resolve(version)
    //#endif
    //#ifdef APP-PLUS
    plus.runtime.getProperty(plus.runtime.appid, (info: any) => {
      version = info.version
      resolve(version)
      // resolve(Number(version.split('.').join('')))
    })
    //#endif
  })
}

/**
 * 是否大于当前版本号 即审核人看到的版本
 * 例如：
 * 当前版本号为4.0.1，则服务端设置===4.0.1 则为审核人看到的版本（不展示我的钱包，展示我的MP）
 * 否则为正常用户看到的版本（展示我的钱包)
 */
export const isAuditStatus = async () => {
  let isIosApp = uni.getSystemInfoSync().platform === 'ios'
  let serverVersion = ''
  const commonStore = useCommonStoreWithOut()
  const pyConfig = commonStore.getConfig
  if (isIosApp) {
    serverVersion = pyConfig ? JSON.parse(pyConfig).uniapp_force_update.ios_audit_version : ''
  } else {
    //  #ifdef APP-PLUS
    console.log(plus.runtime.channel)
    let channel = plus.runtime.channel
    if (channel === 'huawei') {
      serverVersion = pyConfig ? JSON.parse(pyConfig).uniapp_force_update.huawei_audit_iteration_version : ''
    } else if (channel === 'xiaomi') {
      serverVersion = pyConfig ? JSON.parse(pyConfig).uniapp_force_update.xiaomi_audit_iteration_version : ''
    } else if (channel === 'vivo') {
      serverVersion = pyConfig ? JSON.parse(pyConfig).uniapp_force_update.vivo_audit_iteration_version : ''
    } else if (channel === 'oppo') {
      serverVersion = pyConfig ? JSON.parse(pyConfig).uniapp_force_update.oppo_audit_iteration_version : ''
    } else if (channel === 'yyb') {
      serverVersion = pyConfig ? JSON.parse(pyConfig).uniapp_force_update.yyb_audit_iteration_version : ''
    } else {
      serverVersion = pyConfig ? JSON.parse(pyConfig).uniapp_force_update.android_audit_iteration_version : ''
    }
    //  #endif
    //  #ifdef H5
    serverVersion = pyConfig ? JSON.parse(pyConfig).uniapp_force_update.android_audit_iteration_version : ''
    //  #endif
  }
  const version: any = await getVersion()
  console.log('version', version, serverVersion, compareVersion(serverVersion, version) === 0)
  //  #ifdef APP-PLUS
  // 前大于后返回 1  后大于前 返回-1 相等返回0
  return compareVersion(serverVersion, version) === 0 ? true : false
  //  #endif
  //  #ifdef H5
  return false
  //  #endif
}

// 强制更新
export const forceUpdate = () => {
  uni.showModal({
    title: '发现新版本',
    content: '立即去更新',
    success: (res) => {
      if (res.confirm == true) {
        if (plus.os.name == 'Android') {
          uni.showLoading({
            title: '更新中，请勿退出APP'
          })
          uni.downloadFile({
            //执行下载
            url: 'https://pengyinh5.oss-cn-beijing.aliyuncs.com/py_apk/pengyin.v2.0.45.apk', //下载地址
            success: (downloadResult) => {
              //下载成功
              uni.hideLoading()
              if (downloadResult.statusCode == 200) {
                plus.runtime.install(
                  //安装
                  downloadResult.tempFilePath,
                  {
                    force: false
                  },
                  function (res) {
                    plus.runtime.restart()
                  },
                  function (err) {
                    console.log(err)
                  }
                )
              }
            }
          })
        } else {
          //ios
          plus.runtime.openURL('https://apps.apple.com/cn/app/%E6%8D%A7%E9%9F%B3/id1601206402') //url:app对应的apple store地址
        }
      } else {
        if (plus.os.name == 'Android') {
          plus.runtime.quit()
        } else {
          plus.ios.importClass('UIApplication').sharedApplication().performSelector('exit')
        }
      }
    }
  })
}

// 唤端失败跳转下载app页面
export const callApp = (path) => {
  //#ifdef H5
  location.href = `pengyin://${path}`
  // n秒后执行跳转下载
  const timer = setTimeout(() => {
    // this.__download(options);
    // router.navigateTo(`/pages/common/download`)
  }, 3000)

  // 页面隐藏，那么代表已经调起了app，就清除下载的定时器
  const visibilitychange = function () {
    const dom = document as any
    const tag = dom.hidden || dom.webkitHidden
    tag && clearTimeout(timer)
  }

  document.addEventListener('visibilitychange', visibilitychange, false)
  document.addEventListener('webkitvisibilitychange', visibilitychange, false)
  window.addEventListener(
    'pagehide',
    function () {
      clearTimeout(timer)
    },
    false
  )
  //#endif
}

// 查看、删除本地缓存
export const handleLocalStorge = (type?: string) => {
  if (type === 'see') {
    uni.getStorageInfo({
      success: function (res: any) {
        let arr: any = []
        for (let i = 0; i < res.keys.length; i++) {
          const item =
            res.keys[i] === `$$STAT__DBDATA:${sys.appId}`
              ? JSON.stringify(uni.getStorageSync(res.keys[i]))
              : uni.getStorageSync(res.keys[i])
          arr[i] = `${i}：${res.keys[i]}-----${item}\n\n`
        }
        uni.showModal({
          title: `查看-cur：${res.currentSize + 'kb'}-limit:${res.limitSize}`,
          // editable: true,
          // content: '点击确认即可复制',
          content: arr,
          success: function (res) {
            if (res.confirm) {
              copy(arr.toString())
            }
          }
        })
      }
    })
  } else if (type === 'del') {
    uni.showModal({
      title: '提示',
      content: '确认删除所有缓存吗？',
      success: function (res) {
        if (res.confirm) {
          uni.clearStorage()
        } else if (res.cancel) {
        }
      }
    })
  } else if (type === 'cid') {
    uni.removeStorageSync('push_clientid')
  }
}

const touch = reactive({
  touchStartX: 0, // 触屏起始点x
  touchStartY: 0 // 触屏起始点y
})
export const touchStart = (e) => {
  touch.touchStartX = e.touches[0].clientX
  touch.touchStartY = e.touches[0].clientY
}
export const touchEnd = (e) => {
  console.log('触摸结束')
  return new Promise((resolve, reject) => {
    let deltaX = e.changedTouches[0].clientX - touch.touchStartX
    let deltaY = e.changedTouches[0].clientY - touch.touchStartY
    // X轴的滑动距离大于 delta，且此次事件是以X轴移动为主（左滑或者右滑）；
    if (Math.abs(deltaX) > 50 && Math.abs(deltaX) > Math.abs(deltaY)) {
      if (deltaX >= 0) {
        console.log('右滑')
        resolve('rightSlide')
      } else {
        console.log('左滑')
        resolve('leftSlide')
      }
      // Y轴的滑动距离大于 delta，且此次事件是以Y轴移动为主（上滑或者下滑）；
    } else if (Math.abs(deltaY) > 50 && Math.abs(deltaX) < Math.abs(deltaY)) {
      if (deltaY < 0) {
        console.log('上滑')
        resolve('upSlide')
      } else {
        console.log('下滑')
        resolve('pullSlide')
      }
      // 两轴位移都特别小，可以判断是点击
    } else if (Math.abs(deltaY) < 25 && Math.abs(deltaX) < 25) {
      console.log('点击')
    } else {
      console.log('可能是误触')
    }
  })
}

export const imageCompress = (file) => {
  return new Promise((resolve, reject) => {
    let { size, path } = file
    //大于1M进行压缩，
    if (size < 1024 * 1024) {
      resolve(file)
      return false
    }
    // #ifdef H5
    resolve(file)
    return false
    // #endif
    // #ifdef APP-PLUS
    uni.compressImage({
      src: path,
      width: '80%',
      height: '80%',
      success: (res) => {
        let newPath = res.tempFilePath
        let newName = res.tempFilePath.split('/')[res.tempFilePath.split('/').length - 1]
        uni.getFileInfo({
          filePath: res.tempFilePath,
          success: async (info) => {
            let newFile = { ...file, size: info.size, path: newPath, name: newName, tempFilePath: res.tempFilePath }
            resolve(await imageCompress(newFile))
          }
        })
      }
    })
    // #endif
  })
}
export const uploadImgs = ({ count = 1, maxSize = 5 }) => {
  return new Promise((resolve, reject) => {
    const VITE_APP_BASE_API = getUniAppEnv().VITE_APP_BASE_API
    uni.chooseImage({
      count, //默认9
      sizeType: ['original', 'compressed'],
      sourceType: ['album'],
      success: (chooseImageRes) => {
        const tempFilePaths = chooseImageRes.tempFilePaths
        let tempFiles: any = chooseImageRes.tempFiles
        let imgSrcs: any = tempFilePaths
        let allRight = true
        let realFiles: any[] = []
        tempFiles.forEach(async (element, index) => {
          if (!allRight) return
          const fileSize = element.size / 1024 / 1024
          const isLt5M = fileSize <= maxSize
          if (!isLt5M) {
            allRight = false
            return uni.showToast({
              icon: 'none',
              title: `图片大小不可超过${maxSize}M`
            })
          } else {
            let res: any = await imageCompress(tempFiles[index])
            uni.uploadFile({
              url: `${VITE_APP_BASE_API}/v2/upload/image`,
              filePath: res.path,
              name: 'file',
              header: {
                Authorization: `Bearer ${uni.getStorageSync('aibooktoken')}`
              },
              success: (uploadFileRes: any) => {
                resolve(uploadFileRes)
              }
            })
          }
        })
      },
      fail: (err) => {
        reject(err)
      }
    })
  })
}
/**
 * 处理分享图片(w_80)
 *  // 运营：https://oss.pengyin.vip/1f395910003a37d9047c2a0f09c192290adf3ce9b94668dcaffdee38caad6852.jpg?x-oss-process=image/auto-orient,1/resize,m_lfit,w_200/quality,q_90
 *  // 开放平台：https://oss.pengyin.vip/1f395910003a37d9047c2a0f09c192290adf3ce9b94668dcaffdee38caad6852.jpg?x-oss-process=image/auto-orient,1/quality,q_90
 *  // app: https://oss.pengyin.vip/1f395910003a37d9047c2a0f09c192290adf3ce9b94668dcaffdee38caad6852.jpg
 * @param imgurl 
 */
export const handleShareImage = (imgurl: string) => {
  let url = ''
  if (imgurl.indexOf('x-oss-process=image') > 0) {
    url = imgurl.replace('auto-orient', 'resize,w_80/auto-orient')
  } else {
    url = `${imgurl}?x-oss-process=image/resize,w_80/auto-orient,1/quality,q_90`
  }
  return url
}