<template>
  <view>
    <view v-if="loading">
      <block v-for="(item, index) in elements" :key="index">
        <!-- 横向并列布局 -->
        <view class="py_skeleton-group" v-if="item.type == 'flex'">
          <view :class="[inner.clas, index2 > 0 ? 'py_ml' : '']" v-for="(inner, index2) in item.children" :key="index2">
            <view
              :class="[el.clas, el.clas == 'py_circle' ? '' : style, animateClass]"
              v-for="(el, index3) in inner.eles"
              :key="index3"
              ref="skeleton"
              :width="100"
            ></view>
          </view>
        </view>
        <!-- 扩展模板：新闻资讯模板 -->
        <!-- ps 自定义扩展说明： -->
        <!-- 如果你需要自定义模板，可以参照这个news的写法，增加一个skeleton配置类型，编写布局和样式就可以了 -->
        <!-- 注意事项：为了保证骨架效果和动态效果的一致，扩展时，在你希望实际展示在页面中的元素上加上 :class="[style, animateClass]" 和 ref="skeleton" -->
        <view class="py_skeleton-group" v-else-if="item.type == 'news'">
          <view class="py_news_img" :class="[style, animateClass]" ref="skeleton"></view>
          <view class="py_news">
            <view class="py_line" :class="[style, animateClass]" ref="skeleton"></view>
            <view class="py_news_user" :class="[style, animateClass]" ref="skeleton"></view>
            <view class="py_news_time" :class="[style, animateClass]" ref="skeleton"></view>
          </view>
        </view>
        <!-- 垂直高度站位 -->
        <view :style="{ height: item.height + 'rpx' }" v-else-if="item.type == 'space'"></view>
        <!-- 其他基本单位  line  card circle 等 -->
        <view :class="[item.clas, item.clas == 'py_circle' ? '' : style, animateClass]" v-else ref="skeleton"></view>
      </block>
    </view>
    <view v-else>
      <slot></slot>
    </view>
  </view>
</template>

<script>
// skeleton: [
// 					'line-sm*3',
// 					40,
// 					'card',
// 					'circle+line-sm*2',
// 					'line',
// 					'card-lg',
// 					40,
// 					'news',
// 					'square+square+square+square+square+square',
// 					'line-sm*3+square',
// 				],
function getElCountsAndLayout(clas) {
  let elements = {
    // flex左右布局中，给本身没有宽度属性的元素父级添加flex:1
    clas: clas.indexOf('circle') > -1 || clas.indexOf('square') > -1 ? '' : 'py_flex-sub',
    eles: []
  }
  if (clas.indexOf('*') > -1) {
    let group = clas.split('*')
    for (let i = 0, len = Number.parseInt(group[1]); i < len; i++) {
      elements.eles.push({
        clas: 'py_' + group[0],
        type: 'skeleton'
        // flex左右布局中，为了保持元素垂直居中，每一列的最后一行去掉margin-bottom
        // styl: i == len - 1 ? {marginBottom: '0'} : ''
      })
    }
  } else {
    elements.eles.push({
      clas: 'py_' + clas,
      type: 'skeleton'
    })
  }
  return elements
}
function getElCounts(clas) {
  let elements = []
  if (clas.indexOf('*') > -1) {
    let group = clas.split('*')
    for (let i = 0, len = Number.parseInt(group[1]); i < len; i++) {
      elements.push({
        clas: 'py_' + group[0],
        type: 'skeleton'
      })
    }
  } else if (
    clas == 'line' ||
    clas == 'line-sm' ||
    clas == 'line-lg' ||
    clas == 'card' ||
    clas == 'card-sm' ||
    clas == 'card-lg' ||
    clas == 'circle' ||
    clas == 'circle-sm' ||
    clas == 'circle-lg' ||
    clas == 'square' ||
    clas == 'square-sm' ||
    clas == 'square-lg'
  ) {
    elements.push({
      clas: 'py_' + clas,
      type: 'skeleton'
    })
  } else {
    elements.push({
      clas: '',
      type: clas
    })
  }
  return elements
}
// #ifdef APP-NVUE
const animationActuator = weex.requireModule('animation')
let interval
// #endif

/**
 * ls-skeleton 骨架屏
 * @description 可自定义内容的骨架屏
 * @property {Boolean} loading 是否显示骨架（默认：否）
 * @property {Boolean} round 是否圆角骨架风格（默认：否）
 * @property {Boolean} animate 是否开启动画效果（默认：是）
 * @property {Array} skeleton 骨架内容 （段落：line|line-sm|line-lg| 卡片：card|card-sm|card-lg| 圆：circle|circle-sm|circle-lg 正方形：square|square-sm|square-lg）个数标识符*  横向并列连接标识符+
 * @example
 * <ls-skeleton :skeleton="skeleton" :loading="loading">
 * 	<view>好的，页面加载完了</view>
 * </ls-skeleton>
 */
export default {
  name: 'ls-skeleton',
  props: {
    // 是否显示骨架
    loading: {
      type: Boolean,
      default: true
    },
    // 是否圆角骨架风格
    round: {
      type: Boolean,
      default: false
    },
    // 骨架内容 特殊符号说明 [*代表个数 例如：line*3 意思是3个行] [+代表横向并列排列连接  例如：circ+line 意思是左侧一个圆右侧一个行] [纯数字 代表垂直间隔 例如：40 代表40前后的两个元素之间有40rpx高度的间隔]
    skeleton: {
      type: Array,
      default: () => []
    },
    // 是否开启动画效果
    animate: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      elements: []
    }
  },
  computed: {
    animateClass() {
      return this.animate ? 'py_animation' : 'py_static'
    },
    style() {
      if (this.round) {
        return 'py_round'
      }
      return 'py_radius'
    }
  },
  watch: {
    // 开始loading时，加载动画
    loading(val) {
      // #ifdef APP-NVUE
      if (val && this.animate) {
        this.createAnimation()
      }
      // #endif
    }
  },
  created() {
    this.init()
    // #ifdef APP-NVUE
    if (this.loading && this.animate) {
      setTimeout(() => {
        this.createAnimation()
      }, 40)
    }
    // #endif
  },
  methods: {
    init() {
      let elements = []
      let elClass
      this.skeleton.forEach((el) => {
        if (typeof el === 'string') {
          if (el.indexOf('+') > -1) {
            let group = el.split('+')
            let children = []
            group.forEach((els) => {
              children.push(getElCountsAndLayout(els))
            })
            elements.push({
              type: 'flex',
              children: children
            })
          } else {
            elClass = getElCounts(el)
            elements = elements.concat(elClass)
          }
        } else if (typeof el === 'number') {
          elements.push({
            type: 'space',
            height: el
          })
        } else {
          console.warn('[ls-skeleton]: 参数格式包含了不符合规范的内容')
        }
      })
      this.elements = [...elements]
    },
    createAnimation() {
      // let background = '#e6e6e6'
      let background = '#49474B'
      clearInterval(interval)
      interval = setInterval(() => {
        // background = background === '#e6e6e6' ? '#d3d3d3' : '#e6e6e6'
        background = background === '#49474B' ? '#302e33' : '#49474B'
        this.executeAnimation(background)
      }, 1000)
    },
    executeAnimation(background) {
      // loading结束之后，移除计时器，动画不再执行
      if (!this.loading) {
        clearInterval(interval)
        return
      }
      this.$refs.skeleton.forEach((item) => {
        animationActuator.transition(item, {
          styles: {
            backgroundColor: background
          },
          duration: 800, //ms
          timingFunction: 'linear'
        })
      })
    }
  }
}
</script>

<style scoped>
.border {
  border-width: 1px;
  border-style: solid;
  border-color: #dd524d;
}

/* 骨架种类 start*/
.py_line-sm {
  height: 24rpx;
  margin-bottom: 20rpx;
}

.py_line {
  height: 32rpx;
  margin-bottom: 30rpx;
}

.py_line-lg {
  height: 48rpx;
  margin-bottom: 30rpx;
}

.py_card-sm {
  height: 100rpx;
  margin-bottom: 20rpx;
}

.py_card {
  height: 200rpx;
  margin-bottom: 30rpx;
}

.py_card-lg {
  height: 300rpx;
  margin-bottom: 30rpx;
}

.py_circle-sm {
  width: 50rpx;
  height: 50rpx;
  border-radius: 50rpx;
  margin-bottom: 20rpx;
}

.py_circle {
  width: 108rpx;
  height: 108rpx;
  border-radius: 108rpx;
  margin-bottom: 30rpx;
}

.py_circle-lg {
  width: 200rpx;
  height: 200rpx;
  border-radius: 200rpx !important;
  margin-bottom: 30rpx;
}

.py_square-sm {
  width: 50rpx;
  height: 50rpx;
  margin-bottom: 20rpx;
}

.py_square {
  width: 100rpx;
  height: 100rpx;
  margin-bottom: 30rpx;
}

.py_square-lg {
  width: 200rpx;
  height: 200rpx;
  margin-bottom: 30rpx;
}

/* 骨架种类 end*/

/* 辅助样式 under */
.py_skeleton-group {
  /* #ifndef APP-NVUE */
  display: flex;
  /* #endif */
  flex-direction: row;
  align-items: center;
  /* margin-bottom: 30rpx; */
}

.py_flex-sub {
  flex: 1;
}

.py_round {
  border-radius: 48rpx;
}

.py_radius {
  border-radius: 8rpx;
}

.py_ml {
  margin-left: 20rpx;
}

.py_animation {
  background-color: #49474b;
  /* #ifndef APP-NVUE */
  animation-name: twinkle;
  animation-timing-function: inherit;
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  /* #endif */
}

.py_static {
  background-color: #49474b;
}

/* #ifndef APP-NVUE */
@keyframes twinkle {
  0% {
    background-color: #49474b;
  }

  50% {
    background-color: #302e33;
  }

  100% {
    background-color: #49474b;
  }
}

/* #endif */

/* 扩展模板news样式 */
.py_news {
  flex: 1;
  margin-left: 20rpx;
}

.py_news_img {
  width: 240rpx;
  height: 200rpx;
  margin-bottom: 30rpx;
}

.py_news_user {
  height: 32rpx;
  margin-bottom: 30rpx;
  width: 260rpx;
}

.py_news_time {
  height: 32rpx;
  margin-bottom: 30rpx;
  width: 130rpx;
}
</style>
