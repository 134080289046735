<template>
  <PyLoading :loading="loading" :loadingText="loadingText"></PyLoading>
  <view v-if="modelValue" class="py-dialog" @click.stop="maskClick" :class="{ customClass: props.customClass }">
    <view class="py-dialog-container" :style="{ height: height, width: width }" @click.stop>
      <image
        v-if="props.showClose"
        class="dialog-close"
        @click="close"
        src="@/static/img/common/dialog_close.svg"
        alt=""
      />
      <view>
        <slot name="content"></slot>
      </view>
      <view class="footer-group" v-if="cancelText || confirmText" :style="{ 'justify-content': footerStyle }">
        <view v-if="cancelText" class="cancel" :style="{ color: cancelColor }" @click="cancel">{{ cancelText }}</view>
        <view v-if="confirmText" class="confirm" :style="{ color: confirmColor }" @click="confirm">{{
          confirmText
        }}</view>
      </view>
    </view>
  </view>
</template>
<script setup lang="ts">
import { computed, watch, ref } from 'vue'
import PyLoading from '@/components/loading/loading.vue'

export interface Props {
  height?: string
  width?: string
  cancelText?: string
  cancelColor?: string
  confirmText?: string
  confirmColor?: string
  // loading遮罩
  loading?: boolean
  loadingText?: string
  maskClick?: boolean // 是否禁用背景点击
  modelValue: boolean
  showClose?: boolean
  customClass?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  height: '452rpx',
  width: '606rpx',
  cancelText: '',
  cancelColor: 'rgb(172, 174, 191)',
  confirmText: '',
  // confirmColor: 'rgb(247, 202, 24)',
  confirmColor: '#ec6c39',
  loading: false,
  loadingText: '加载中...',
  maskClick: false,
  modelValue: false,
  showClose: true,
  customClass: false
})
const emit = defineEmits<{
  (e: 'update:modelValue', val: boolean): void
  (e: 'close'): void
  (e: 'cancel'): void
  (e: 'confirm'): void
}>()

// 计算传入的按钮数量
const footerStyle = computed(() => {
  let str = 'space-around'
  if (props.confirmText || props.cancelText) {
    if (props.confirmText && props.cancelText) {
      str = 'space-around'
    } else {
      str = 'center'
    }
  }
  return str
})
const maskClick = () => {
  if (props.maskClick) {
    return
  } else {
    emit('update:modelValue', false)
  }
}
const close = () => {
  emit('close')
}
const cancel = () => {
  emit('cancel')
}
const confirm = () => {
  emit('confirm')
}
</script>
<style setup lang="scss">
.py-dialog {
  position: fixed;
  /** z-index 参考 common.scss 规范 */
  z-index: 4001;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  background: rgba($color: #000000, $alpha: 0.7);
  overflow: hidden;

  .py-dialog-container {
    position: absolute;
    z-index: 3001;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    // background: #302e33;
    background: #fff;
    border: 2rpx solid #49474b;
    border-radius: 32rpx;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    overflow: hidden;

    .footer-group {
      border-top: 2rpx solid #eee;
      background: #fff;
      position: absolute;
      bottom: 0;
      display: flex;
      align-items: center;
      height: 96rpx;
      line-height: 96rpx;
      width: 100%;

      .cancel,
      .confirm {
        flex: 1;
        text-align: center;
      }
    }
  }
}

.dialog-close {
  width: 76rpx;
  height: 76rpx;
  position: absolute;
  top: 12rpx;
  right: 12rpx;
  z-index: 10;
}
</style>
