<template>
  <!-- 阿里云滑块验证码 -->
  <view class="nocaptcha">
    <view
      ref="nc"
      :id="newNcId"
      :propNcId="newNcId"
      :change:propNcId="AWSCRenderjs.setId"
      :prop="type"
      :change:prop="AWSCRenderjs.changeProp"
      :AWSCReset="slideSuccess"
      :change:AWSCReset="AWSCRenderjs.reset"
    ></view>
  </view>
</template>
<script lang="ts">
/**
 * 因阿里云滑块验证码需要用到renderjs，官方现不支持setup语法糖，待支持后优化
 * @since 20220828
 */

import { pyToast } from '@/utils/util'
import { defineComponent } from 'vue'

export default defineComponent({
  props: ['ncId', 'AWSCType'],
  data() {
    return {
      newNcId: this.ncId,
      type: this.AWSCType || 'message',
      slideSuccess: false,
      slideData: {}
    }
  },
  methods: {
    // 滑块回调
    AWSCRenderjsCall(options) {
      if (options.resType === 'success') {
        this.slideSuccess = true
        const { sessionId, sig, token, appkey, scene } = options.resData
        this.slideData = {
          session_id: sessionId,
          sig,
          token,
          app_key: appkey,
          scene
        }
        this.$emit('success', this.slideData)
      } else {
        pyToast(options.resData)
        this.slideSuccess = false
      }
      this.$emit('slideStatus', this.slideSuccess)
    },
    resetSlider() {
      this.slideSuccess = false
      this.$emit('slideStatus', this.slideSuccess)
    }
  },
  mounted() {}
})
</script>
<script module="AWSCRenderjs" lang="renderjs" src="../../utils/renderjs/AWSCRender.js"></script>
<style lang="scss" scoped></style>
