import { isWeiXin, isAndroid, isIphone } from '@/utils/validator'
import { isDevMode, getEnv, isProdMode } from '@/utils/env';
import type { App } from 'vue';
/**
 * 检测登录状态
 * @param this Vue实例
 * @param needMobile 是否需要手机号，默认需要
 * @since 2022.08.20
 */
// function checkUserLogin (this: app, needMobile: true) {
//   return new Promise((resolve) => {
//     if (this.$store.state.userInfo) {
//       if (!needMobile || (needMobile && this.$store.state.userInfo.mobile)) {
//         resolve()
//       } else {
//         weixinLogin.getUserInfo(this.$route.fullPath, needMobile)
//       }
//     } else {
//       weixinLogin.getUserInfo(this.$route.fullPath, needMobile)
//     }
//   })
// }

/**
 * 将常用的判断挂载vue原型上，直接使用
 * 需要在`@types`目录下的vue-ext.d.ts中添加上声明，方便推导
 * @since 2022.08.20
 */
export function setupGlobalProperties (app: App<Element>) {
  app.provide('$isWeixin', isWeiXin())
  app.provide('$isAndroid', isAndroid())
  app.provide('$isIphone', isIphone())
  app.provide('$isPyApp', isAndroid() || isIphone())
  app.provide('$isDevMode', isDevMode())
  app.provide('$isProdMode', isProdMode())
  app.provide('$getEnv', getEnv())
}