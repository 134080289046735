<template>
  <movable-view @change="handleViewChange" :animation="false" :x="offsetX" :y="offsetY" class="player" direction="all">
    <view class="wrapper" :class="{ playing: isPlay && !isLoading, paused: !isPlay }">
      <image class="cover" :src="imgurl"></image>
    </view>
    <!-- <image class="right" src="@/static/img/player/newplay_1.png"></image> -->
    <image class="loading" v-if="isLoading" src="@/static/img/common/player/icon-loading.svg"></image>
    <image
      class="play"
      v-else-if="!isPlay && !isLoading"
      @click="handlePlay"
      src="@/static/img/common/player/icon_play_player.svg"
    ></image>
    <image class="play" v-else @click="handlePlay" src="@/static/img/common/player/icon_pause_player.svg"></image>
  </movable-view>
</template>
<script setup lang="ts">
import { onMounted, computed, reactive } from 'vue'
import { useGlobalPlayerStore } from '@/store/modules'
import * as _ from 'lodash-es'
const globalPlayerStore = useGlobalPlayerStore()
const position = reactive({
  x: 0,
  y: 0
})
let imgurl = computed(() => {
  return globalPlayerStore.imgurl
})
const isPlay = computed(() => {
  return globalPlayerStore.isPlay
})
const isLoading = computed(() => {
  return globalPlayerStore.loading
})
const offsetX = computed(() => {
  return globalPlayerStore.offsetX || 999
})
const offsetY = computed(() => {
  return globalPlayerStore.offsetY || 200
})
const handlePlay = () => {
  if (!globalPlayerStore.isPlay) {
    globalPlayerStore.setPlay()
  } else {
    globalPlayerStore.setPause()
  }
}
const handleViewChange = _.debounce((e) => {
  const { x, y } = e.detail
  position.x = x
  position.y = y
  globalPlayerStore.setPositon(position.x, position.y)
}, 200)
onMounted(() => {
  if (!globalPlayerStore.player) {
    globalPlayerStore.initPlayer()
  }
})
</script>
<style lang="scss" scoped>
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.player {
  z-index: 999999;
  width: 156rpx;
  height: 156rpx;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  .wrapper {
    width: 136rpx;
    height: 136rpx;
    // background: url('@/static/img/player/newplay_2.png');
    background-size: 100% 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .loading {
    width: 58rpx;
    height: 58rpx;
    animation: rotate 3s linear infinite;
    animation-play-state: running;
  }
  .playing {
    animation: rotate 5s linear infinite;
    animation-play-state: running;
  }
  .paused {
    animation: rotate 5s linear infinite;
    animation-play-state: paused;
  }
  .cover {
    width: 68rpx;
    height: 68rpx;
    position: absolute;
    border-radius: 50%;
    z-index: 1;
  }
  .play {
    width: 68rpx;
    height: 68rpx;
    z-index: 99;
  }
  .right {
    position: absolute;
    width: 24rpx;
    height: 88rpx;
    position: absolute;
    right: 10rpx;
    top: 0;
  }
}
</style>
