<template>
  <view>
    <uni-collapse ref="collapse" v-model="modelValue" @change="change" :border="border">
      <uni-collapse-item
        v-for="(item, idx) in collapseList"
        :title="item.title"
        :key="idx"
        :title-border="item.titleBorder"
        :disabled="item.disabled"
        :border="false"
      >
        <slot :name="`collapse-${idx}`"></slot>
      </uni-collapse-item>
    </uni-collapse>
  </view>
</template>
<script setup lang="ts">
import { ref } from 'vue'
export interface Props {
  // [title?: string, titleBorder?: string, disabled?: boolean]
  // titleBorder: none
  border?: boolean
  collapseList: Array<any>
  modelValue: Array<any>
}

const props = withDefaults(defineProps<Props>(), {
  border: true,
  collapseList: () => [],
  modelValue: () => ['0']
})

const emit = defineEmits<{
  (e: 'update:modelValue', collapseValue: number): void
}>()

// const value = ref(['0'])
const change = () => {}
</script>
<style lang="scss" scoped>
:deep(.uni-collapse) {
  background: #302e33;
  border-radius: 32rpx;
  padding: 0 48rpx;
  .uni-collapse-item__title {
    height: 112rpx;
    line-height: 112rpx;
  }
  .uni-collapse-item__wrap,
  .uni-collapse-item__title-box,
  .is--transition {
    background: #302e33;
    .uni-collapse-item__title-text {
      color: #fff;
    }
    .text {
      font-size: 28rpx;
      font-weight: 400;
      color: $color-primary;
      line-height: 40rpx;
      padding-bottom: 48rpx;
    }
  }
  .uni-collapse-item__title-box {
    padding: 0;
  }
  .uni-collapse-item__title-arrow {
    margin-right: 0;
  }
  .uni-collapse-item__title.uni-collapse-item-border {
    border-bottom-color: rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .is-open.uni-collapse-item-border {
    border-bottom: transparent;
  }
  .open.uni-collapse-item__wrap-content.uni-collapse-item--border {
    border-bottom-color: rgba(255, 255, 255, 0.1);
  }
}
</style>
