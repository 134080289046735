<template>
  <!-- 阿里云无痕验证码 -->
  <view class="nocaptcha">
    <view
      ref="nc"
      :id="newNcId"
      :change:propNcId="NAWSCRender.setId"
      :prop="type"
      :change:prop="NAWSCRender.changeProp"
      :AWSCReset="slideSuccess"
      :change:AWSCReset="NAWSCRender.reset"
      @click="NAWSCRender.getNVCValAsync"
    >
      <slot></slot>
    </view>
  </view>
</template>
<script lang="ts">
/**
 * 因阿里云滑块验证码需要用到renderjs，官方现不支持setup语法糖，待支持后优化
 * @since 20220828
 */

import { defineComponent } from 'vue'

export default defineComponent({
  /**
   * isNext: 是否允许获取验证码参数
   */
  props: ['ncId', 'AWSCType', 'isNext'],
  data() {
    return {
      newNcId: this.ncId,
      type: this.AWSCType || 'message',
      slideSuccess: false,
      isNext: this.isNext,
      slideData: {}
    }
  },
  methods: {
    // 滑块回调
    AWSCRenderjsCall(options) {
      console.log('common-slider-nvc', options)
      if (options.resType === 'getNVCValAsync') {
        this.slideSuccess = true
        this.$emit('faceSuccess', options.resData.ncVal)
      } else {
        // pyToast(options.resData)
        this.slideSuccess = false
      }
      this.$emit('slideStatus', this.slideSuccess)
    },
    resetSlider() {
      this.slideSuccess = false
      this.$emit('slideStatus', this.slideSuccess)
    }
  },
  mounted() {}
})
</script>
<script module="NAWSCRender" lang="renderjs" src="../../utils/renderjs/NAWSCRender.js"></script>
<style lang="scss" scoped></style>
