<script setup lang="ts">
import { useCommonStore, useGlobalPlayerStoreWithOut } from '@/store/modules'
import { pyToast, forceUpdate, compareVersion, isAuditStatus } from '@/utils/util'
import { onLaunch, onShow, onHide, onError } from '@dcloudio/uni-app'
import { handleConfig } from '@/utils/global-config'
import routingIntercept from '@/router/index'
import { useRouter } from '@/hooks/routerLink'
import { ref, inject, reactive, nextTick, watch } from 'vue'
import { setTabbar } from '@/utils'
import { pushLister } from '@/utils/push'
import { CommonApi } from './api'
import { useUserStoreWithOut } from '@/store/modules'
import { getUniAppEnv } from '@/utils/env'

// const $pyStat: any = inject('$pyStat')
const commonStore = useCommonStore()
const globalPlayerStore = useGlobalPlayerStoreWithOut()
const router = useRouter()
const userStore = useUserStoreWithOut()

onLaunch(() => {
  const globalApp: any = getApp()
  // nextTick(() => {
  //   globalApp.globalData.$pyStat = $pyStat
  // })
  if (!globalPlayerStore.player) {
    globalPlayerStore.initPlayer()
  }
  globalPlayerStore.setAppLaunch(true)
  // 路由监听
  routingIntercept()
  // #ifdef APP-PLUS
  plus.nativeUI.setUIStyle('dark') //  设置暗黑模式
  // 推送监听
  let isIosApp = uni.getSystemInfoSync().platform === 'ios'
  uni.onPushMessage((msg: any) => {
    console.log(msg)
    // ios
    if (isIosApp) {
      //【APP离线】收到消息，但没有提醒（发生在一次收到多个离线消息时，只有一个有提醒，但其他的没有提醒）
      //【APP在线】收到消息，不会触发系统消息,需要创建本地消息，但不能重复创建。必须加msg.type验证去除死循环
      //创建本地消息,发送的本地消息也会被receive方法接收到，但没有type属性，且aps是null
      // ios在线 用透传
      if (msg.type === 'receive') {
        uni.createPushMessage({
          title: msg.data.title,
          content: msg.data.content,
          payload: msg.data.payload
        })
        // ios 点击
      } else if (msg.type === 'click') {
        if (msg.data.payload) {
          // 在线
          if (typeof msg.data.payload === 'string') {
            commonStore.closeOpenPage()
            router.navigateTo(msg.data.payload)
            // 离线
          } else if (msg.data.payload && msg.data.payload.url) {
            commonStore.closeOpenPage()
            router.navigateTo(msg.data.payload.url)
          }
        }
      }
      // ios离线
      // Android
    } else {
      //如果是Android，当APP在线时，收到透传消息不会进入系统消息，需要发送本地提醒。
      // 安卓收到通知
      if (msg.type == 'receive') {
        uni.createPushMessage({
          title: msg.data.title,
          content: msg.data.content,
          payload: msg.data.payload
        })
        // 安卓点击
      } else if (msg.type === 'click') {
        // 安卓在线
        commonStore.closeOpenPage()
        router.navigateTo(msg.data.payload.url)
      }
    }
  })
  // pushLister()
  // 设置底色
  plus.navigator.setStatusBarStyle('dark') // 黑色
  uni.getNetworkType({
    success: function (res) {
      if (res.networkType === 'none') {
        commonStore.setNetwork(false)
      } else {
        commonStore.setNetwork(true)
      }
    }
  })
  uni.onNetworkStatusChange(async function (res) {
    if (res.isConnected === false) {
      commonStore.setNetwork(false)
      pyToast('网络无连接')
    } else {
      commonStore.setNetwork(true)
      const config: any = await commonStore.setConfig(true)
      handleConfig(config)
      setTabbar()
    }
  })
  // #endif
})
let auditStatus = ref(false)
let networkType = ref('')
const getAllSongs = async () => {
  // 没有数据去查询
  if (!commonStore.getAllMusicLists || commonStore.getAllMusicLists.length <= 0) {
    try {
      let res: any = await CommonApi.getAllMusicLists({})
      if (res && !res.code) {
        // 空就是没数据
        if (res.data.list.length > 0) {
          commonStore.setAllMusicLists(res.data.list)
          uni.setStorageSync('last_up_time', Date.parse(new Date()) / 1000 - 600)
        }
      }
    } catch (error) {}
  } else {
    // 有数据去查是否更新
    try {
      let res: any = await CommonApi.getAllMusicLists({
        last_up_time: uni.getStorageSync('last_up_time')
      })
      if (res && !res.code) {
        // 空就是没更新
        if (res.data.list.length > 0) {
          commonStore.setAllMusicLists(res.data.list)
          uni.setStorageSync('last_up_time', Date.parse(new Date()) / 1000 - 600)
        }
      }
    } catch (error) {}
  }
}
function shuffle(array) {
  return array.sort(function () {
    return Math.random() - 0.5
  })
}
const getMusicDailyLists = async () => {
  try {
    let res: any = await CommonApi.getMusicDailyLists({})
    if (res && !res.code) {
      let resList = res.data.list
      commonStore.setMusicDailyLists(resList)
      commonStore.setRondomMusicDailyLists(shuffle(resList))
    }
  } catch (error) {}
}
onShow(async () => {
  const config: any = await commonStore.setConfig(true)
  handleConfig(config)
  getAllSongs()
  getMusicDailyLists()
  // #ifdef APP-PLUS
  setTimeout(() => {
    var args = plus.runtime.arguments
    if (args) {
      //捧投详情分享： pengyin:///pages/home/pengTouDetail?pengTouId=606&userId=2992&from=pengTouShare
      commonStore.closeOpenPage()
      if (args.indexOf('pengyin://') > -1) {
        uni.setStorageSync('globalUrlScheme', args.replace('pengyin://', getUniAppEnv().VITE_APP_BASE_HOST))
        commonStore.setUrlScheme(true)
      } else {
        commonStore.setUrlScheme(false)
      }
      // 处理args参数，如直达到某新页面等
      const goUrl = args.replace('pengyin://', '')
      if (
        goUrl.indexOf('pengTouShare') > -1 ||
        goUrl.indexOf('songshare') > -1 ||
        goUrl.indexOf('marketshare') > -1 ||
        goUrl.indexOf('fromshare') > -1 ||
        goUrl.indexOf('fromselfsaleshare') > -1
      ) {
        router.navigateTo(goUrl)
      }
      // 用完参数之后清空，否则每次唤醒app都会调用上面的方法
      plus.runtime.arguments = ''
    }
  }, 0)
  // 强制更新
  // 前大于后返回 1  后大于前 返回-1 相等返回0
  let isIosApp = uni.getSystemInfoSync().platform === 'ios'
  let is_force_update = config.uniapp_force_update.is_force_update
  let ios_force_version = config.uniapp_force_update.ios_force_version
  let android_force_version = config.uniapp_force_update.android_force_version
  let currentVersion
  plus.runtime.getProperty(plus.runtime.appid, (info: any) => {
    currentVersion = info.version
    if (isIosApp) {
      if (compareVersion(ios_force_version, currentVersion) > 0 && is_force_update) {
        forceUpdate()
      }
    } else {
      if (compareVersion(android_force_version, currentVersion) > 0 && is_force_update) {
        forceUpdate()
      }
    }
  })
  // #endif
  setTabbar()
})
onHide(() => {
  console.log('App onHide')
})

// 捕获 app error
onError((err) => {
  // 这里只能捕获方法内的异常，不能捕获生命周期中的逻辑异常
  try {
    // $pyStat.throw(err)
  } catch (error) {}
})
</script>
<style lang="scss">
// #ifndef APP-PLUS-NVUE
#app,
.user,
page {
  background-color: #fff;
}

// #endif
</style>
