<template>
  <view id="common-header">
    <view class="header-ul">
      <view
        class="header-li"
        v-for="(item, idx) in state.navData"
        :key="item.name"
        @click="handleClickNav(item.route, +idx)"
      >
        <image v-if="state.active === +idx" class="active" :src="item.activeIcon" alt="" />
        <image v-else :src="item.icon" alt="" />
        <view :class="{ nav_active: state.active === +idx }">{{ item.name }}</view>
      </view>
    </view>
  </view>
</template>
<script lang="ts" setup>
import { reactive } from 'vue'
import { useRouter } from '@/hooks/routerLink'

const router = useRouter()
const state = reactive({
  active: 0,
  navData: [
    {
      name: '首发',
      route: '/pages/home/index',
      icon: '../../static/img/tabbar/nav_home.png',
      activeIcon: '../../static/img/tabbar/nav_home_active.png'
    },
    // {
    //   name: "集市",
    //   route: "/pages/market/index",
    //   icon: '../../static/img/tabbar/nav_market.png',
    //   activeIcon: '../../static/img/tabbar/nav_market_active.png',
    // },
    {
      name: '发现',
      route: '/pages/songs/index',
      icon: '../../static/img/tabbar/nav_songs.png',
      activeIcon: '../../static/img/tabbar/nav_songs_active.png'
    },
    {
      name: '藏品',
      route: '/pages/collect/index',
      icon: '../../static/img/tabbar/nav_collection.png',
      activeIcon: '../../static/img/tabbar/nav_collection_active.png'
    },
    {
      name: '我的',
      route: '/pages/user/index',
      icon: '../../static/img/tabbar/nav_user.png',
      activeIcon: '../../static/img/tabbar/nav_user_active.png'
    }
  ]
})

const handleClickNav = (name: string, index: number) => {
  state.active = index
  router.switchTab(name)
  let pages = getCurrentPages()
  // let page = pages[pages.length - 1].$page.fullPath;  //完整路由地址
  let page = pages[pages.length - 1] //完整路由地址

  // this.type = page.split('type=')[1]  //携带的type参数
  // const routeNotAuto = ["home", "songs"];
  // const routeName = this.$route.name;
  // if (routeName === name) {
  //   return;
  // }
  // if (!getToken() && !routeNotAuto.includes(name)) {
  //   this.$router.push({
  //     path: "/login",
  //     query: {
  //       backUrl: `${document.location.protocol}//${window.location.host}/${name}`,
  //     },
  //   });
  // } else {
  //   this.$router.push({ name });
  // }
}
</script>

<style lang="scss" scoped>
.showPadding {
  padding-bottom: 40rpx !important;
}
#common-header {
  width: 100%;
  height: 100rpx;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  z-index: 12;
  background: #000;
  padding-bottom: env(safe-area-inset-bottom);
  padding-bottom: constant(safe-area-inset-bottom);
  .header-ul {
    flex: 1;
    display: flex;
    padding: 0 12rpx;
    .header-li {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: $color-primary;
      font-size: 20rpx;
      font-weight: 400;
      image {
        width: 60rpx;
        height: 60rpx;
      }
      .nav_active {
        background: url('@/static/img/tabbar/nav_bg.svg') no-repeat center;
        background-size: 96rpx 96rpx;
        color: #f7ca18;
      }
    }
  }
}
</style>
