<template>
  <uni-popup class="goodsdetailpopup" ref="popupRef" type="bottom" mask-background-color="rgba(0,0,0,0.7)">
    <view class="share-popup">
      <view class="share-text">{{ shareText }}</view>
      <view class="share-container">
        <view @click="share('微信好友')">
          <image src="@/static/img/common/wx.png"></image>
          <view class="share-title">微信好友</view>
        </view>
        <view @click="share('微信朋友圈')">
          <image src="@/static/img/common/pyq.png"></image>
          <view class="share-title">微信朋友圈</view>
        </view>
        <view v-if="showSystem" @click="share('系统分享')">
          <image src="@/static/img/common/system.png"></image>
          <view class="share-title">系统分享</view>
        </view>
      </view>
      <view class="btn f-col-c-c" @click.stop="close">
        <view class="btn-text">取消</view>
      </view>
    </view>
  </uni-popup>
</template>
<script setup lang="ts">
import { ref } from 'vue'
import { getUniAppEnv } from '@/utils/env'
import { pyToast } from '@/utils/util'
import { string } from 'mathjs'

const emits = defineEmits<{
  (e: 'share', val: string): void
}>()
const props = defineProps({
  shareText: {
    type: String,
    default: '分享'
  },
  showSystem: {
    type: Boolean,
    default: false
  },
  shareInfo: {
    type: Object,
    required: true
  }
})
let popupRef = ref<any>(null)
const open = () => {
  popupRef.value.open()
}
const close = () => {
  popupRef.value.close()
}
const share = (type: string) => {
  let content = {
    title: props.shareInfo.title,
    summary: props.shareInfo.summary,
    href: props.shareInfo.href,
    imageUrl: props.shareInfo.imageUrl
  }
  if (type === '微信好友' || type === '微信朋友圈') {
    if (plus.runtime.isApplicationExist({ pname: 'com.tencent.mm', action: 'weixin://' })) {
      //已安装微信
      uni.share({
        provider: 'weixin',
        scene: type === '微信好友' ? 'WXSceneSession' : 'WXSceneTimeline',
        type: 0,
        href: content.href,
        title: content.title,
        summary: content.summary,
        imageUrl: content.imageUrl,
        success: function (res) {
          console.log('success:' + JSON.stringify(res))
        },
        fail: function (err) {
          console.log('fail:' + JSON.stringify(err))
        }
      })
    } else {
      //未安装微信
      pyToast('请先安装微信')
    }
  } else if (type === '系统分享') {
    plus.share.sendWithSystem(
      { type: 'image', content: content.title, href: content.href, pictures: content.imageUrl },
      function () {
        console.log('分享成功')
      },
      function (e) {
        console.log('分享失败：' + JSON.stringify(e))
      }
    )
  }
}
defineExpose({
  open
})
</script>
<style lang="scss" scoped>
.goodsdetailpopup {
  z-index: 2000;

  .share-popup {
    background: #302e33;
    height: 478rpx;
    border-radius: 32rpx 32rpx 0 0;
    position: relative;
    .share-text {
      font-size: 28rpx;
      font-weight: 400;
      color: #ffffff;
      line-height: 32rpx;
      text-align: center;
      padding-top: 48rpx;
    }

    // 分享
    .share-container {
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-size: 28rpx;
      margin-top: 88rpx;

      image {
        height: 110rpx;
        width: 110rpx;
      }

      .share-title {
        font-size: 28rpx;
        font-weight: 400;
        color: #ffffff;
        line-height: 32rpx;
        margin-top: 16rpx;
      }

      view {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .btn {
      text-align: center;
      background: #000;
      width: 750rpx;
      height: 64rpx;
      line-height: 64rpx;
      margin-top: 88rpx;
      position: absolute;
      bottom: 0;
      .btn-text {
        height: 64rpx;
        font-size: 24rpx;
        font-weight: 400;
        color: $color-primary;
      }
    }
  }
}
</style>
