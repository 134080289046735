<!-- 打开app组件 -->
<template>
  <!-- #ifdef H5 -->
  <view class="goods_share" v-if="isOverlay" @click="isOverlay = false">
    <view class="mt40 mr80 text-right">
      <image class="goods_share_handle" src="@/static/img/home/share_handle.png" alt="" />
      <view class="icon">点击<image src="@/static/img/home/ICONshare@2x.png" alt="" />{{ props.shareDesc }}</view>
    </view>
  </view>
  <!-- #endif -->
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { isWeiXin } from '@/utils/validator'
import { getUniAppEnv } from '@/utils/env'
import { padZero, copy } from '@/utils/util'
import { useRouter } from '@/hooks/routerLink'

const router = useRouter()

let isOverlay = ref(false)
const props = defineProps({
  // 点击描述文案
  shareDesc: {
    type: String,
    default: '在浏览器打开'
  }
})
/**
 * H5打开捧音app
 * 调用方法：
 * 1、template中直接写：<OpenPyApp ref="openPyAppRef"></OpenPyApp>
 * 2、定义ref变量： let openPyAppRef = ref<any>(null)
 * 3、methods: openPyAppRef.value.openPyApp({ copyText, copyToast: '分享链接复制成功', url: shareUrl.value })
 * 
 * @param param
 * copyText 需要复制的文案
 * copyToast 复制成功的文案提示
 * url 要跳转app的url(不是全路径，不要域名)
 * goDownLoadApp 是否要跳转H5下载app页面，默认fasle
 */
const openPyApp = async ({ copyText = '', copyToast = '打开路径复制成功', url = '', goDownLoadApp = true }) => {
  return new Promise((resolve, reject) => {
    if (isWeiXin()) {
      if (copyText) {
        copy(copyText, '')
      }
      isOverlay.value = true
      return
    }
    const VITE_APP_SCHEMA_PATH = getUniAppEnv().VITE_APP_SCHEMA_PATH
    // #ifdef H5
    if (copyText) {
      copy(copyText, copyToast)
    }
    if (url) {
      window.location.href = `${VITE_APP_SCHEMA_PATH}${url}`
    } else {
      window.location.href = `${VITE_APP_SCHEMA_PATH}`
    }
    // n秒后执行跳转下载
    const timer = setTimeout(async () => {
      if (goDownLoadApp) {
        // 跳转下载app页面
        router.navigateTo(`/pages/common/download`)
        return
      } else {
        resolve({
          copyText,
          copyToast,
          url,
          goDownLoadApp
        })
      }
    }, 3000)
    // 页面隐藏，那么代表已经调起了app，就清除下载的定时器
    const visibilitychange = function () {
      const dom = document as any
      const tag = dom.hidden || dom.webkitHidden
      tag && clearTimeout(timer)
    }

    document.addEventListener('visibilitychange', visibilitychange, false)
    document.addEventListener('webkitvisibilitychange', visibilitychange, false)
    window.addEventListener(
      'pagehide',
      function () {
        clearTimeout(timer)
      },
      false
    )
    // #endif
  })
}
defineExpose({
  openPyApp
})
</script>
<style lang="scss" scoped>
.goods_share {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: fixed;
  z-index: 9999;
  right: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  &_handle {
    width: 200rpx;
    height: 200rpx;
  }
  color: #fff;
  .icon {
    font-size: 42rpx;
    font-weight: 600;
    height: 64rpx;
    display: flex;
    align-items: center;
    image {
      width: 64rpx;
      height: 64rpx;
      margin: 0 16rpx;
    }
  }
}
</style>
