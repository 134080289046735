import { useUserStoreWithOut } from '@/store/modules';
import routerConfig from '@/router/routerConfig'

type routerLink = 'navigateTo' | 'redirectTo' | 'reLaunch' | 'switchTab'

export const useRouter = () => {
  const userStore = useUserStoreWithOut()

  const navigateTo = (
    url: string,
    animationType?: 'auto' | 'none' | 'slide-in-right' | 'slide-in-left' | 'slide-in-top' | 'slide-in-bottom' | 'fade-in' | 'zoom-out' | 'zoom-fade-out' | 'pop-in',
    events?: any): any => {
    return new Promise((resolve, reject) => {
      const isTabbar = routerConfig.tabbarList.includes(url)
      console.log("url-Promise", url)
      if (!url) {
        return reject(new Error('路由为空'))
      } else if (/^wx/.test(url)) {
        return navigateToMiniProgram({
          appId: url,
        })
      }
      // webview 跳转外面淡出处理
      // else if (/^http/.test(url)) {
      //   url = `/pages/common/webview?url=${encodeURIComponent(url)}`
      // }
      if (isTabbar) {
        switchTab(url)
      } else {
        uni.navigateTo({
          url,
          animationType,
          events,
          success: (res) => {
            resolve(res.eventChannel)
          },
          fail: () => {
            /*尝试跳转到tab页面*/
            return switchTab(url)
          },
        })
      }

    })
  }

  /*跳转tab*/
  const switchTab = (url: string): any => {
    return new Promise((resolve, reject) => {
      uni.switchTab({
        url,
        success: (res) => resolve(res),
        fail: (err) => reject(err),
      })
    })
  }

  /*关闭当前跳转其他*/
  const redirectTo = (url: string): any => {
    return new Promise((resolve, reject) => {
      uni.redirectTo({
        url,
        success: (res) => resolve(res),
        fail: (err) => reject(err),
      })
    })
  }

  /*关闭所有跳转其他*/
  const reLaunch = (url: string): any => {
    return new Promise((resolve, reject) => {
      uni.reLaunch({
        url,
        success: (res) => resolve(res),
        fail: (err) => reject(err),
      })
    })
  }

  /*跳转到其他小程序*/
  const navigateToMiniProgram = (options: UniApp.NavigateToMiniProgramOptions): any => {
    return new Promise((resolve, reject) => {
      uni.navigateToMiniProgram({
        ...options,
        success: (res) => resolve(res),
        fail: (err) => reject(err),
      })
    })
  }

  /*检测登录 并跳转页面*/
  const linkTo = (url: string, type: routerLink = 'navigateTo') => {
    if (!!userStore.getToken) {
      switch (type) {
        case 'navigateTo':
          navigateTo(url)
          break
        case 'redirectTo':
          redirectTo(url)
          break
        case 'reLaunch':
          reLaunch(url)
          break
        case 'switchTab':
          switchTab(url)
          break
      }
    } else {
      userStore.setClear()
      // reLaunch(`/pages/login/index`)
    }
  }
  /*检测登录 登录成功后回调*/
  const checkLogin = (callback: any) => {
    if (!!userStore.getToken) {
      callback()
    } else {
      userStore.setClear()
      // reLaunch(`/pages/login/index`)
    }
  }

  return {
    navigateTo,
    switchTab,
    redirectTo,
    navigateToMiniProgram,
    linkTo,
    checkLogin,
  }
}

