import { defineStore } from 'pinia'
import { store } from '@/store'

export const useUserStore = defineStore('app-user', {
  state: () => ({
    userInfo: null,
    // 因注册接口跟绑定手机号接口返回数据接口不一致
    token: '',
    selectedKeys: []
  }),
  getters: {
    getUserInfo(state) {
      const userInfo = uni.getStorageSync('userInfo') || state.userInfo
      return userInfo ? JSON.parse(userInfo) : {}
    },
    getToken(state) {
      const token = uni.getStorageSync('aibooktoken') || state.token
      return token
    }
  },
  actions: {
    setClear() {
      // 不能全部清除，因为有backUrl
      uni.removeStorageSync('aibooktoken')
      this.token = ''
      uni.removeStorageSync('aibookuserInfo')
      uni.removeStorageSync('pyConfig')
      this.userInfo = null
    },
    setToken(token) {
      uni.setStorageSync('aibooktoken', token)
      this.token = token
    },
    setUserInfo(userInfo) {
      uni.setStorageSync('aibookuserInfo', JSON.stringify(userInfo))
      this.userInfo = userInfo
    }
  }
})

// Need to be used outside the setup
export function useUserStoreWithOut() {
  return useUserStore(store)
}
