<template>
	<view style="overflow: hidden;position: absolute;width: 100%;height: 100%;pointer-events: none; top: 0;">
		<view class="danmu-li" v-for="(item, index) in listData" :class="item.type" :style="item.style" :key="index">
			<view class="danmu-inner" v-if="item.item.nick_name">
				<view class="user-box">
					<view class="user-img">
						<view class="img-box">
							<image :src="item.item.avatar_url">
							</image>
						</view>
					</view>
					<view class="user-text cl1 f24 ">
						{{item.item.nick_name}}
					</view>
					<view class="user-status cl1 f24">
						{{ item.item.content?item.item.content:'购买了这首音乐作品' }}
					</view>
				</view>
			</view>
		</view>
	</view>
</template>
<script lang="ts" setup>
import { onMounted, ref } from 'vue'
const props: any = defineProps({
	//rightToLeft leftToRight leftBottom
	type: {
		type: String,
		default: 'rightToLeft'
	},
	list: {
		type: Array,
		default() {
			return []
		}
	},
	minTime: {
		type: Number,
		default: 9
	},
	maxTime: {
		type: Number,
		default: 9
	},
	minTop: {
		type: Number,
		default: 40
	},
	maxTop: {
		type: Number,
		default: 160
	},
	hrackH: { //轨道高度
		type: Number,
		default: 40
	}
})
const listData = ref<any>([])
const add = (obj) => {
	let data = {
		item: obj.item,
		id: Date.parse(new Date() + ''),
		time: Math.ceil(Math.floor(Math.random() * (props.maxTime - props.minTime + 1) + props.minTime)),
		type: props.type
	}
	if (props.type === 'leftBottom') {
		let objData = {
			item: data.item,
			type: 'leftBottomEnter',
			style: {
				transition: `all 0.5s`,
				animationDuration: `0.5s`,
				transform: `translateX(0%)`,
				bottom: `${props.minTop}px`
			}
		}
		let listLen = listData.value.length;
		let hrackNum = props.hrackNum;
		for (let i in listData.value) {
			if (listData.value[i].status === 'reuse') { //重用
				// this.$set(this.listData, i, objData);
				listData.value[i] = objData
			} else if (listData[i].value.status === 'reset') { //重置
				listData.value[i].style.transition = 'none';
				listData.value[i].style.bottom = 0;
				listData.value[i].status = 'reuse';
			} else if (listData.value[i].status === 'recycle') { //回收
				listData.value[i].type = 'leftBottomExit';
				listData.value[i].status = 'reset';
			} else {
				listData.value[i].style.bottom = parseInt(listData.value[i].style.bottom) + props.hrackH + 'px';
			}
			if (parseInt(listData.value[i].style.bottom) >= (props.maxTop - props.hrackH) && listData.value[i].status !== 'reset') { //需要回收
				listData.value[i].status = 'recycle';
			}
		}
		if (listLen < hrackNum + 2) {
			listData.value.push(objData);
		}
	} else if (props.type === 'rightToLeft') {
		let objData = {
			item: data.item,
			type: 'rightToLeft',
			style: {
				animationDuration: `${data.time}s`,
				top: `${Math.ceil(Math.random() * (props.maxTop - props.minTop + 1) + props.minTop)}px`
			},
			delTime: Date.parse(new Date() + '') + data.time * 1200
		}
		for (let i in listData.value) {
			if (listData.value[i].delTime <= Date.parse(new Date() + '')) {
				repaint(i, objData.type);
				objData.type = '';
				// this.$set(listData.value, i, objData);
				listData.value[i] = objData
				return
			}
		}
		listData.value.push(objData);
	}
}

const repaint = (index, type) => {
	setTimeout(() => {
		listData.value[index].type = type;
	}, 100)
}
onMounted(() => {
	//leftBottom 使用参数
	if (props.type === 'leftBottom') {
		props.hrackNum = Math.floor(props.maxTop / props.hrackH);
	}
})
defineExpose({
	repaint,
	add,
	listData
})
</script>
<style></style>
<style lang="scss">
@keyframes leftBottomEnter {
	0% {
		transform: translateY(100%);
		opacity: 0;
	}

	100% {
		transform: translateY(0%);
		opacity: 1;
	}
}

@keyframes leftBottomExit {
	0% {
		transform: translateY(0%);
		opacity: 1;
	}

	100% {
		transform: translateY(-200%);
		opacity: 0;
	}
}

@keyframes leftToRight {
	0% {
		transform: translateX(-100%);
	}

	100% {
		transform: translateX(100%);
	}
}

@keyframes rightToLeft {
	0% {
		transform: translateX(0%);
	}

	100% {
		transform: translateX(-200%);
	}
}

.danmu-li {
	position: absolute;
	left: 100%;
	width: 100%;
	transform: translateX(100%);
	animation-timing-function: linear;

	&.leftBottomEnter {
		animation-name: leftBottomEnter;
	}

	&.leftBottomExit {
		animation-name: leftBottomExit;
		animation-fill-mode: forwards;
	}

	&.rightToLeft {
		animation-name: rightToLeft;
	}

	&.leftToRight {
		animation-name: rightToLeft;
	}

	.danmu-inner {
		display: inline-block;

		.user-box {
			display: flex;
			padding: 8rpx 32rpx 8rpx 8rpx;
			background: rgba(0, 0, 0, 0.3);
			border-radius: 32rpx;
			align-items: center;

			.user-img {
				.img-box {
					display: flex;

					image {
						width: 48rpx;
						height: 48rpx;
						background: rgba(55, 55, 55, 1);
						border-radius: 50%;
					}
				}
			}

			.user-status {
				max-width: 960rpx;
				text-overflow: ellipsis;
				overflow: hidden;
				margin-left: 8rpx;
				white-space: nowrap;
				color: rgba(255, 255, 255, 1);
			}

			.user-text {
				margin-left: 16rpx;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
				max-width: 240rpx;
				color: rgba(255, 255, 255, 1);
			}
		}
	}
}
</style>
