<template>
  <view class="custom-tab-bar" :style="{ 'background-color': bgColor }">
    <view class="tabbar-left">
      <view class="icon-block" @click="backHandle">
        <image v-if="isComponent" src="@/static/img/common/player/player-down.png"></image>
        <image v-else src="@/static/img/common/icon_back.svg" alt="" />
      </view>
      <view class="title-block musicname-text" @click="backHandle" v-if="leftTitle">{{ leftTitle }}</view>
    </view>
    <view class="content">
      <slot name="content"></slot>
    </view>
    <view class="right">
      <slot name="right"></slot>
    </view>
  </view>
</template>
<script lang="ts" setup>
import { computed } from 'vue'
import { useRouter } from '@/hooks/routerLink'

export interface Props {
  leftTitle?: string
  backPath?: string
  custom?: boolean
  bgColor?: string // 小程序不允许deep样式穿透
  isComponent?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  leftTitle: '',
  backPath: '',
  custom: false,
  bgColor: '', // 不可传默认值，有些页面不需要背景色
  isComponent: false // true 则展示向下⬇️的箭头
})

const emit = defineEmits<{
  (e: 'customBack'): void
}>()

const router = useRouter()

const backHandle = () => {
  if (props.custom) {
    emit('customBack')
  } else {
    const routes: any = getCurrentPages() // 获取当前打开过的页面路由数组
    if (routes.length <= 1) {
      router.navigateTo(`/pages/home/index`)
    } else {
      uni.navigateBack({
        delta: 1,
        animationType: 'pop-out',
        animationDuration: 200
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.custom-tab-bar {
  width: 100vw;
  height: 108rpx;
  position: fixed;
  // 部分页面需要设置通顶
  // background-color: $bg-primary;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  z-index: 1999;

  .tabbar-left {
    // width: 30vw;
    flex: 1;
    height: 108rpx;
    display: flex;
    align-items: center;
  }

  .icon-block {
    padding-left: 48rpx;
    padding-right: 20rpx;
    height: 108rpx;
    display: flex;
    align-items: center;

    image {
      width: 68rpx;
      height: 68rpx;
      display: block;
    }
  }

  .title-block {
    font-size: 34rpx;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
  }

  .content {
    flex: 1;
    height: 100%;
    color: #fff;
    // margin-left: -30rpx;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28rpx;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
  }

  .right {
    height: 100%;
    flex: 1;
    display: flex;
    align-items: center;
  }
}
</style>
