<template>
  <scroll-view
    class="list-scroll-view"
    :style="{ height: height }"
    scroll-y="true"
    :scroll-top="scrollTopHeight"
    :scroll-anchoring="true"
    lower-threshold="100rpx"
    :refresher-enabled="refresherEnabled && disabledScroll"
    :refresher-triggered="triggered"
    :refresher-threshold="100"
    :refresher-background="refresherBackground"
    :refresher-default-style="refresherDefaultStyle"
    :scroll-with-animation="true"
    :show-scrollbar="false"
    @refresherpulling="onPulling"
    @refresherrefresh="onRefresh"
    @refresherrestore="onRestore"
    @refresherabort="onAbort"
    @scroll="onScroll"
    @scrolltolower="scrolltolower"
    @touchstart="touchstart"
    @touchmove="touchmove"
    @touchend="touchend"
  >
    <!-- <template #refresher> -->
    <view v-if="pulltop > 0" class="refresher" :style="{ top: pulltop + 'px' }">
      <view class="refresher-text">
        <image
          class="arrow"
          src="@/static/img/common/pull/arrow-down.png"
          v-if="refreshTxt === '下拉即可刷新...'"
        ></image>
        <image
          class="arrow"
          src="@/static/img/common/pull/arrow-up.png"
          v-if="refreshTxt === '释放立即刷新...'"
        ></image>
        <image src="@/static/img/common/pull/loading.png" v-if="refreshTxt === '加载中...'"></image>
        {{ refreshTxt }}
      </view>
    </view>
    <!-- </template> -->
    <slot name="empty" v-if="state.data.length === 0 && isSend && !emptyShow"></slot>
    <slot name="content" v-else-if="(state.data.length !== 0 && isSend) || emptyShow" :content="state.data"> </slot>
    <slot name="other"></slot>
    <!-- <view v-if="!refresherEnabled">别拉了，没有更多了~</view> -->
    <view v-if="state.data.length !== 0" class="bottom-text">
      <view>
        {{ finished ? loadingMoreNoMoreText : '加载中...' }}
      </view>
    </view>
  </scroll-view>
</template>

<script lang="ts" setup>
import { onShow, onLoad, onHide } from '@dcloudio/uni-app'
import { onMounted, reactive, ref, watch, nextTick } from 'vue'
import * as _ from 'lodash-es'

const emit = defineEmits<{
  (e: 'listChange', data: any): void
  (e: 'onRefresh'): void
  (e: 'error'): void
}>()

const props: any = defineProps({
  emptyShow: {
    type: Boolean,
    default: false
  },
  // apiAction: {
  //   type: Promise,
  //   default: (params?: any) => Promise as any
  // },
  apiAction: {
    type: Function,
    default: () => {}
  },
  apiParams: {
    type: Object,
    default: {} as any
  },
  // 页码
  offset: {
    type: Number,
    default: 0
  },
  // 每页多少条
  page_size: {
    type: Number,
    default: 10
  },
  resDataKey: {
    type: String,
    default: 'data'
  },
  height: {
    type: String,
    default: '100vh'
  },
  // 首次是否load
  immediateCheck: {
    type: Boolean,
    default: true
  },
  // 是否开启自定义下拉刷新
  refresherEnabled: {
    type: Boolean,
    default: true
  },
  // 是否需要加载更多
  needLoadMore: {
    type: Boolean,
    default: true
  },
  showLoading: {
    type: Boolean,
    default: false
  },
  loadingMoreNoMoreText: {
    type: String,
    default: '到底了~'
  },
  refresherBackground: {
    type: String,
    default: 'black'
  },
  refresherDefaultStyle: {
    type: String,
    default: 'none'
  },
  isMountedLoad: {
    type: Boolean,
    default: false
  },
  isApiAction: {
    type: Boolean,
    default: true
  }
})

watch(
  () => props.apiAction,
  (val) => {
    if (val && props.isApiAction) {
      getData(true)
    }
  }
)
watch(
  () => props.apiParams,
  (val) => {
    if (val) {
      getData(true)
    }
  },
  { deep: true }
)

// 设置当前下拉刷新状态，true 表示下拉刷新已经被触发，false 表示下拉刷新未被触发
// const triggered = ref(false)
const pulltop = ref(-40)
const triggered: any = ref(null)
const offset = ref(1)
const scrollTop = ref(0)
const refreshTxt = ref('下拉即可刷新')
const state = reactive({
  data: [] as any
})
const isSend = ref(false)
// 自定义下拉刷新被触发
const _freshing = ref(false)
// 加载状态
const disabledScroll = ref(true)
const finished = ref(false)
let touchPositon = reactive({
  X: 0,
  Y: 0
})
const touchstart = (e) => {
  touchPositon.X = e.touches[0].clientX
  touchPositon.Y = e.touches[0].clientY
}
const touchmove = (e) => {
  if (disabledScroll.value === false) {
    return
  }
  let moveX = Math.abs(touchPositon.X - e.touches[0].clientX)
  let moveY = Math.abs(touchPositon.Y - e.touches[0].clientY)
  if (moveX / moveY <= 1) {
    // 下滑
    disabledScroll.value = true
  } else {
    // 左滑
    disabledScroll.value = false
  }
}
const touchend = (e) => {
  if (refreshTxt.value === '下拉即可刷新...') {
    pulltop.value = -40
  }
  touchPositon.X = 0
  touchPositon.Y = 0
  disabledScroll.value = true
}
/**
 * 获取数据
 * @param init 是否初始化，默认false
 * @param ps 传入页大小 刷新时调用
 */
const getData = (init = false, page_size?: number) => {
  if (props.showLoading) {
    uni.showLoading({
      title: '加载中',
      mask: true
    })
  }
  if (init) {
    offset.value = 1
    finished.value = false
    isSend.value = false
    state.data = []
  }
  if (finished.value) {
    uni.hideLoading()
    return
  }
  if (props.apiAction) {
    try {
      return props
        .apiAction({
          ...props.apiParams,
          offset: offset.value,
          page_size: page_size || props.page_size
        })
        .then((res: any) => {
          uni.hideLoading()
          isSend.value = true
          const resData = res.data[props.resDataKey] || []
          state.data = init ? resData : state.data.concat([...resData])
          offset.value++
          // 如果有传入ps参数，根据ps参数计算下一次的pn
          if (page_size) {
            offset.value = Math.ceil(page_size / props.page_size) + 1
          }
          const ps = props.page_size || page_size
          // 改为判断返回的list长度是否为0，为0则没有数据了
          console.log('resData', resData, state.data)
          if (
            resData.length === 0 ||
            res.data?.total === 0 ||
            resData.length < ps ||
            !props.needLoadMore ||
            state.data?.length === res.data?.total
          ) {
            finished.value = true
          }
          emit('listChange', {
            ...res,
            offset: offset.value,
            resList: state.data,
            isInit: init
          })
        })
        .catch(() => {
          uni.hideLoading()
          // this.error = true
          state.data = []
          finished.value = true
          isSend.value = true
          pullRstore()
          emit('error')
        })
        .finally(() => {
          uni.hideLoading()
          // this.loading = false
          // if (this.refreshing) {
          //   this.refreshing = false
          // }
        })
    } catch (error) {
      emit('error')
      uni.hideLoading()
      state.data = []
      finished.value = true
      isSend.value = true
      pullRstore()
    }
  }
}

const setData = (data) => {
  state.data = data
}

onLoad(() => {
  // _freshing.value = false
  // setTimeout(() => {
  //   triggered.value = true
  // }, 1000)
})

// 自定义下拉刷新控件被下拉
const pullHeight = ref(0)
const onPulling =
  //  _.throttle(
  (e) => {
    pulltop.value = e.detail.deltaY / 2 - 25
    if (refreshTxt.value === '加载中...') return
    if (e.detail.deltaY > 110) {
      refreshTxt.value = '释放立即刷新...'
    } else {
      refreshTxt.value = '下拉即可刷新...'
    }
    // if (e.detail.deltaY < 0) return // 防止上滑页面也触发下拉
    // triggered.value = true
  }
// , 200)

const pullRstore = () => {
  triggered.value = 'restore' // 需要重置
  setTimeout(async () => {
    triggered.value = false
    _freshing.value = false
  }, 500)
}

const isPull = ref(false)
const onRefresh = () => {
  pulltop.value = 30
  refreshTxt.value = '加载中...'
  triggered.value = 'restore' // 需要重置
  setTimeout(async () => {
    await getData(true)
    triggered.value = false
    _freshing.value = false
    pulltop.value = -40
    refreshTxt.value = '释放立即刷新...'
  }, 500)
  emit('onRefresh')
}
// 自定义下拉刷新被复位
const onRestore = () => {
  triggered.value = 'restore' // 需要重置
}
// 自定义下拉刷新被中止
const onAbort = () => {}
const onScroll = (e) => {
  scrollTop.value = e.detail.scrollTop
}
const scrolltolower = () => {
  getData()
}
const clearData = () => {
  isSend.value = false
  state.data = []
}
const setScrollTopInit = () => {
  scrollTop.value = 0
}
const scrollTopHeight = ref(0)
onShow(async () => {
  if (props.immediateCheck) {
    await getData(true)
  }
  uni.hideLoading()
  scrollTopHeight.value = scrollTop.value
})
onHide(() => {
  // uni.setStorageSync('scrollTop', scrollTop)
})
onMounted(() => {
  if (props.isMountedLoad) {
    getData()
  }
})
defineExpose({
  getData,
  clearData,
  setData,
  onRefresh,
  setScrollTopInit,
  scrollTop
})
</script>
<style lang="scss" scoped>
.bottom-text {
  font-size: 20rpx;
  color: #666;
  height: 80rpx;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
:deep(.uni-scroll-view-refresher) {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff !important;
}
.refresher {
  color: $color-primary;
  width: 100vw;
  height: 100rpx;
  font-size: 20rpx;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -40px;
  left: 0;
  .refresher-text {
    display: flex;
    align-items: center;
    image {
      width: 44rpx;
      height: 44rpx;
      margin-right: 10rpx;
    }
    .arrow {
      width: 14rpx;
      height: 20rpx;
    }
  }
}
</style>
