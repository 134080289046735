export default {
  //不需要登录的页面,白名单
  notNeedLoginList: [
    // '/pages/login/index',
    '/pages/login/authBack',
    '/pages/home/index',
    '/pages/common/webview',
    '/pages/common/maintain',
    '/pages/common/protocol',
    '/pages/common/privacy',
    // '/pages/login/bind',
    '/pages/common/download',
    '/pages/home/customMade',
    '/pages/home/booksRead'
  ],
  tabbarList: [
    '/pages/home/index',
    '/pages/user/index'
  ],
  //登录页
  // loginPage: '/pages/login/index',
  // 绑定手机号页面
  // loginBindPage: '/pages/login/bind',
}
