import { defineStore } from 'pinia'
import { store } from '@/store'
import Http from '@/api/request'
import routerConfig from '@/router/routerConfig'

const COFNIG_KEY = 'pyConfig'

function getConfigFn (that, resolve, reject) {
  // Http.post('/v1/platform/emergency_close', { flag: 'isIndex' })
  //   // Http.post('/v2/platform/emergency_close11', { flag: 'isIndex' })
  //   .then((res: any) => {
  //     if (res && !res.code) {
  //       const resData = res.data
  //       that.config = resData
  //       uni.setStorageSync(COFNIG_KEY, JSON.stringify(resData))
  //       resolve(resData)
  //     }
  //   })
  //   .catch((err) => {
  //     reject(err)
  //   })
}
export const useCommonStore = defineStore('app-common', {
  state: () => ({
    config: null as any,
    // 登录后要返回的url
    backUrl: '',
    showPengtoupopup: false,
    // 登录页面环境切换
    loginEnv: 'test',
    network: true,
    marketPayingId: 0, // 当前用户集市支付中id
    marketPaySuccessId: 0, // 当前用户集市支付成功id
    showOpenPage: true,
    push_clientid: '',
    hasUrlScheme: false,
    musicDailyLists: [] as any, // 每日推荐列表
    rondomMusicDailyLists: [] as any, // 每日推荐随机播放列表
  }),
  getters: {
    getConfig (state) {
      return uni.getStorageSync(COFNIG_KEY) || state.config
    },
    getBackUrl (state) {
      return uni.getStorageSync('backUrl') || state.backUrl
    },
    getLoginEnv (state) {
      return uni.getStorageSync('setLoginEnv') || state.loginEnv
    },
    getMusicDailyLists () {
      return uni.getStorageSync('musicDailyLists')
    },
    getRondomMusicDailyLists () {
      return uni.getStorageSync('rondomMusicDailyLists')
    },
    getAllMusicLists () {
      return uni.getStorageSync('allMusicLists')
    },
  },
  actions: {
    setPushClientid (id) {
      this.push_clientid = id
    },
    closeOpenPage () {
      // #ifdef APP-PLUS
      plus.navigator.setFullscreen(false) //显示手机顶部状态栏
      plus.navigator.showSystemNavigation() //显示手机底部导航按键
      // #endif
      this.showOpenPage = false
      uni.showTabBar()
    },
    setNetwork (isonline) {
      this.network = isonline
    },
    setPengtouPopup (isShow) {
      this.showPengtoupopup = isShow
    },
    setUrlScheme (urlScheme) {
      this.hasUrlScheme = urlScheme
    },
    setConfig (isGetStorage = false) {
      const config = uni.getStorageSync(COFNIG_KEY)
      return new Promise((resolve, reject) => {
        if (isGetStorage) {
          getConfigFn(this, resolve, reject)
        } else {
          if (config) {
            resolve(JSON.parse(config))
          } else {
            getConfigFn(this, resolve, reject)
          }
        }
      })
    },
    setBackUrl (backUrl) {
      this.backUrl = backUrl
      uni.setStorageSync('backUrl', backUrl)
      // // 如果要要跳转的是绑定手机号页面，则不设置backurl
      // if (this.backUrl.includes(routerConfig.loginBindPage) || this.backUrl.includes(routerConfig.loginPage)) {
      //   console.log("this.backUrl-------111-------", this.backUrl)
      // } else {
      //   console.log("this.backUrl-----222--------", this.backUrl)
      //   uni.setStorageSync('backUrl', backUrl)
      // }
    },
    setLoginCode ({ code }) {
      uni.setStorageSync('loginCode', code)
    },
    setLoginEnv (env) {
      this.loginEnv = env
      uni.setStorageSync('setLoginEnv', env)
    },
    setMarketPayingId (id: number) {
      this.marketPayingId = id
    },
    setMarketPaySuccessId (id: number) {
      this.marketPaySuccessId = id
    },
    setMusicDailyLists (list) {
      uni.setStorageSync('musicDailyLists', list)
    },
    setRondomMusicDailyLists (list) {
      uni.setStorageSync('rondomMusicDailyLists', list)
    },
    setAllMusicLists (list) {
      uni.setStorageSync('allMusicLists', list)
    },
    setAllLikeMusicLists (list) {
      uni.setStorageSync('allLikeMusicLists', list)
    },
    setAllCollectLists (list) {
      uni.setStorageSync('allMyCollectLists', list)
    },
  }
})

// Need to be used outside the setup
export function useCommonStoreWithOut () {
  return useCommonStore(store)
}
