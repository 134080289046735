/**
 * fromshare = 某个页面名称
 * v4.0.10
 * 捧投分享：https://test-h5.pengyin.vip/h5-uniapp/pages/home/pengTouDetail?pengTouId=280&userId=2933&from=pengTouShare
 * 集市藏品分享： https://test-h5.pengyin.vip/h5-uniapp/pages/market/detail?id=51&music_id=1164&isShare=1
 * 自助售卖的藏品分享：
 *    单个：https://test-h5.pengyin.vip/h5-uniapp/pages/selfSales/detail?musicId=1110&fromselfsaleshare=1
 *    多个：https://test-h5.pengyin.vip/h5-uniapp/pages/selfSales/list?music_ids=1111,1109&musician_id=1023&fromshare=1
 * 首发分享：https://test-h5.pengyin.vip/h5-uniapp/pages/home/goodsDetails?id=69&isShare=1&music_id
 * 音乐分享：https://test-h5.pengyin.vip/h5-uniapp/pages/songs/play?id=1114&from=songshare
 * 元宇宙音乐分享：https://test-h5.pengyin.vip/h5-uniapp/pages/home/musicMetaverse?&fromshare=1
 */

export const enum PassphraseEnum { // 首页口令获取剪贴板打开
  PASSPHRASE =  '复制打开捧音',
  PENGTOUDETAIL = '/pages/home/pengTouDetail', // 捧投详情分享
  MARKETDETAIL = '/pages/market/detail', // 集市藏品分享
  SELFSALESDETAIL = '/pages/selfSales/detail', // 自助售卖的藏品分享单个
  SELFSALESLIST = '/pages/selfSales/list', // 自助售卖的藏品分享多个
  HOMEFIRSTROUND = '/pages/home/goodsDetails', // 首发分享
  SONGSPLAY = '/pages/songs/play', // 音乐分享
  MUSICMETAVERSE = '/pages/home/musicMetaverse' // 音乐元宇宙分享
}

export const enum MusicTabListTypeEnum { // 全局底部播放器音乐列表
  ALLMUSICLIST =  0,  // 全部音乐列表
  MUSICDAILYLIST =  1, // 每日推荐
  MYCOLLECTMUSICLIST =  2, // 我的藏品
  MYLIKESLIST =  3,  //  我的喜欢 
}

export const enum GlobalMusicPlayModeEnum { // 全局播放模式
  ORDER =  1,  // 列表循环
  SINGLE =  2, // 单曲播放 
  RANDOM=  3, // 随机播放
}
